const verticali = [
  {
    id: 6,
    it: 'STEM',
    en: 'STEM',
    es: 'STEM',
    de: 'STEM',
    fr: 'STEM',
    pt: 'PONTO',
    color: '#Ff5291',
    onAir: false,
    video: '/assets/verticali-video/STEM.mp4',
    redirect: 'https://media.cving.com/stem'

  },
  {
    id: 8,
    it: 'Turismo & Cultura',
    en: 'Turism & Culture',
    es: 'Turismo y Cultura',
    de: 'Fremdenverkehr & Kultur',
    fr: 'Tourisme et Culture',
    pt: 'Turismo e Cultura',
    color: '#2284d6',
    onAir: false,
    video: '/assets/verticali-video/Turismo.mp4',
    redirect: 'https://media.cving.com/turismo-cultura'

  },
  {
    id: 10,
    it: 'Sales & Marketing',
    en: 'Sales & Marketing',
    es: 'Ventas y Marketing',
    de: 'Vertrieb und Marketing',
    fr: 'Ventes et Marketing',
    pt: 'Vendas e Marketing',
    color: '#ff9600',
    onAir: false,
    video: '/assets/verticali-video/Sales.mp4',
    redirect: 'https://media.cving.com/sales-marketing'

  },
  {
    id: 9,
    it: 'Industry & Manufacturing',
    en: 'Industry & Manufacturing',
    es: 'Logística y Fabricación',
    de: 'Logistik und Fertigung',
    fr: 'Logistique et Fabrication',
    pt: 'Logistica & Manufatura',
    color: '#ff3e00',
    onAir: false,
    video: '/assets/verticali-video/Logistica.mp4',
    redirect: 'https://media.cving.com/industry-manifacturing'

  },
  {
    id: 4,
    it: 'Retail &<br>Customer care',
    en: 'Retail & Customer care',
    es: 'Venta al detalle y atención al cliente',
    de: 'Einzelhandel & Kundenbetreuung',
    fr: 'Vente au détail et services à la clientèle',
    pt: 'Retalho e apoio ao cliente',
    color: '#30d35d',
    onAir: false,
    video: '/assets/verticali-video/Retail.mp4',
    redirect: 'https://media.cving.com/retail-customer-care'

  },
  {
    id: 2,
    it: 'Tech & Digital',
    en: 'Tech & Digital',
    es: 'Tecnología y digital',
    de: 'Technik & Digital',
    fr: 'Tech et Digital',
    pt: 'Técnica e Digital',
    color: '#6311BE',
    onAir: false,
    video: '/assets/verticali-video/Tech.mp4',
    redirect: 'https://media.cving.com/tech-digital'

  },
  {
    id: 5,
    it: 'Risorse umane & Formazione',
    en: 'HR & Education',
    es: 'Recursos humanos y formación',
    de: 'HR & Bildung',
    fr: 'RH et éducation',
    pt: 'RH & Educação',
    color: '#f00012',
    onAir: false,
    video: '/assets/verticali-video/Risorse-umane.mp4',
    redirect: 'https://media.cving.com/risorse-umane-formazione'

  },
  {
    id: 7,
    it: 'Healthcare & Pharma',
    en: 'Healthcare & Pharma',
    es: 'Sanidad y Farmacia',
    de: 'Gesundheitswesen & Pharma',
    fr: 'Saúde e Farmácia',
    pt: 'Saúde e Farmácia',
    color: '#12eddb',
    onAir: false,
    video: '/assets/verticali-video/Healthcare.mp4',
    redirect: 'https://media.cving.com/healthcare-pharma'

  },
  {
    id: 1,
    it: 'Accounting &<br> Finance',
    en: 'Accounting & Finance',
    es: 'Contabilidad y Finanzas',
    de: 'Buchhaltung und Finanzen',
    fr: 'Comptabilité et Finances',
    pt: 'Contabilidade e Finanças',
    color: '#5a72ff',
    onAir: false,
    video: '/assets/verticali-video/Accounting.mp4',
    redirect: 'https://media.cving.com/accounting_finance'

  }
]
function formatStr(str) {
  // rimuovo i tag html che potrebbero essere presenti
  const regex = /(<([^>]+)>)/ig
  return str.replace(regex, ' ')
}
function shuffle(a) {
  // mischio le carte
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]]
  }
  // se tag onAir va in prima posizione
  const onAir = a.find(el => el.onAir)
  if (onAir) {
    const index = a.indexOf(onAir)
    a.splice(index, 1)
    a.unshift(onAir)
  }
  return a
}
export function getVerticals(lang) {
  const filtred = []
  verticali.forEach((verticale) => {
    filtred.push({
      [lang]: verticale[lang],
      label: formatStr(verticale[lang]),
      color: verticale.color,
      id: verticale.id,
      video: verticale.video,
      redirect: verticale?.redirect,
      onAir: verticale?.onAir
    })
  })
  return shuffle(filtred)
}
