const benefits = [
  {
    id: 1,
    language: 'it',
    correlation_id: 1,
    description: 'formazione professionale',
    active: true
  },
  {
    id: 2,
    language: 'it',
    correlation_id: 2,
    description: 'partecipazione azionaria',
    active: true
  },
  {
    id: 3,
    language: 'it',
    correlation_id: 3,
    description: 'assicurazione sanitaria integrativa',
    active: true
  },
  {
    id: 4,
    language: 'it',
    correlation_id: 4,
    description: 'assicurazione sulla vita',
    active: true
  },
  {
    id: 5,
    language: 'it',
    correlation_id: 5,
    description: 'auto aziendale',
    active: true
  },
  {
    id: 6,
    language: 'it',
    correlation_id: 6,
    description: 'cellulare aziendale',
    active: true
  },
  {
    id: 7,
    language: 'it',
    correlation_id: 7,
    description: 'computer aziendale',
    active: true
  },
  {
    id: 8,
    language: 'it',
    correlation_id: 8,
    description: 'mensa aziendale',
    active: true
  },
  {
    id: 9,
    language: 'it',
    correlation_id: 9,
    description: 'parcheggio aziendale',
    active: true
  },
  {
    id: 10,
    language: 'it',
    correlation_id: 10,
    description: 'rimborso spese cellulare',
    active: true
  },
  {
    id: 11,
    language: 'it',
    correlation_id: 11,
    description: 'rimborso internet',
    active: true
  },
  {
    id: 12,
    language: 'it',
    correlation_id: 12,
    description: 'spese di alloggio',
    active: true
  },
  {
    id: 13,
    language: 'it',
    correlation_id: 13,
    description: 'abbonamento mezzi pubblici',
    active: true
  },
  {
    id: 14,
    language: 'it',
    correlation_id: 14,
    description: 'buoni pasto',
    active: true
  },
  {
    id: 15,
    language: 'it',
    correlation_id: 15,
    description: 'snack gratuiti',
    active: true
  },
  {
    id: 16,
    language: 'it',
    correlation_id: 16,
    description: 'distributori automatici di snack e bevande',
    active: true
  },
  {
    id: 17,
    language: 'it',
    correlation_id: 17,
    description: 'fondo pensione',
    active: true
  },
  {
    id: 18,
    language: 'it',
    correlation_id: 18,
    description: 'orario flessibile',
    active: true
  },
  {
    id: 19,
    language: 'it',
    correlation_id: 19,
    description: 'sconti su prodotti o servizi dell’azienda',
    active: true
  },
  {
    id: 20,
    language: 'it',
    correlation_id: 20,
    description: 'viaggio premio',
    active: true
  },
  {
    id: 21,
    language: 'en',
    correlation_id: 1,
    description: 'professional training',
    active: true
  },
  {
    id: 22,
    language: 'en',
    correlation_id: 2,
    description: 'shareholding',
    active: true
  },
  {
    id: 23,
    language: 'en',
    correlation_id: 3,
    description: 'supplementary health insurance',
    active: true
  },
  {
    id: 24,
    language: 'en',
    correlation_id: 4,
    description: 'life insurance',
    active: true
  },
  {
    id: 25,
    language: 'en',
    correlation_id: 5,
    description: 'company car',
    active: true
  },
  {
    id: 26,
    language: 'en',
    correlation_id: 6,
    description: 'company cell phone',
    active: true
  },
  {
    id: 27,
    language: 'en',
    correlation_id: 7,
    description: 'company computer',
    active: true
  },
  {
    id: 28,
    language: 'en',
    correlation_id: 8,
    description: 'company canteen',
    active: true
  },
  {
    id: 29,
    language: 'en',
    correlation_id: 9,
    description: 'company parking lot',
    active: true
  },
  {
    id: 30,
    language: 'en',
    correlation_id: 10,
    description: 'cell phone reimbursement',
    active: true
  },
  {
    id: 31,
    language: 'en',
    correlation_id: 11,
    description: 'internet reimbursement',
    active: true
  },
  {
    id: 32,
    language: 'en',
    correlation_id: 12,
    description: 'accommodation expenses',
    active: true
  },
  {
    id: 33,
    language: 'en',
    correlation_id: 13,
    description: 'public transportation subscription',
    active: true
  },
  {
    id: 34,
    language: 'en',
    correlation_id: 14,
    description: 'meal vouchers',
    active: true
  },
  {
    id: 35,
    language: 'en',
    correlation_id: 15,
    description: 'free snacks',
    active: true
  },
  {
    id: 36,
    language: 'en',
    correlation_id: 16,
    description: 'vending machines for snacks and drinks',
    active: true
  },
  {
    id: 37,
    language: 'en',
    correlation_id: 17,
    description: 'retirement fund',
    active: true
  },
  {
    id: 38,
    language: 'en',
    correlation_id: 18,
    description: 'flexible hours',
    active: true
  },
  {
    id: 39,
    language: 'en',
    correlation_id: 19,
    description: 'discounts on company products or services',
    active: true
  },
  {
    id: 40,
    language: 'en',
    correlation_id: 20,
    description: 'award trip',
    active: true
  },
  {
    id: 41,
    language: 'es',
    correlation_id: 1,
    description: 'formación profesional',
    active: true
  },
  {
    id: 42,
    language: 'es',
    correlation_id: 2,
    description: 'participación',
    active: true
  },
  {
    id: 43,
    language: 'es',
    correlation_id: 3,
    description: 'seguro de salud complementario',
    active: true
  },
  {
    id: 44,
    language: 'es',
    correlation_id: 4,
    description: 'seguro de vida',
    active: true
  },
  {
    id: 45,
    language: 'es',
    correlation_id: 5,
    description: 'coche de empresa',
    active: true
  },
  {
    id: 46,
    language: 'es',
    correlation_id: 6,
    description: 'teléfono móvil de la empresa',
    active: true
  },
  {
    id: 47,
    language: 'es',
    correlation_id: 7,
    description: 'ordenador de la empresa',
    active: true
  },
  {
    id: 48,
    language: 'es',
    correlation_id: 8,
    description: 'comedor de empresa',
    active: true
  },
  {
    id: 49,
    language: 'es',
    correlation_id: 9,
    description: 'aparcamiento de la empresa',
    active: true
  },
  {
    id: 50,
    language: 'es',
    correlation_id: 10,
    description: 'reembolso del teléfono móvil',
    active: true
  },
  {
    id: 51,
    language: 'es',
    correlation_id: 11,
    description: 'reembolso por internet',
    active: true
  },
  {
    id: 52,
    language: 'es',
    correlation_id: 12,
    description: 'gastos de alojamiento',
    active: true
  },
  {
    id: 53,
    language: 'es',
    correlation_id: 13,
    description: 'abono de transporte público',
    active: true
  },
  {
    id: 54,
    language: 'es',
    correlation_id: 14,
    description: 'vales de comida',
    active: true
  },
  {
    id: 55,
    language: 'es',
    correlation_id: 15,
    description: 'bocadillos gratis',
    active: true
  },
  {
    id: 56,
    language: 'es',
    correlation_id: 16,
    description: 'máquinas expendedoras de aperitivos y bebidas',
    active: true
  },
  {
    id: 57,
    language: 'es',
    correlation_id: 17,
    description: 'fondo de pensiones',
    active: true
  },
  {
    id: 58,
    language: 'es',
    correlation_id: 18,
    description: 'horario de trabajo flexible',
    active: true
  },
  {
    id: 59,
    language: 'es',
    correlation_id: 19,
    description: 'descuentos en productos o servicios de la empresa',
    active: true
  },
  {
    id: 60,
    language: 'es',
    correlation_id: 20,
    description: 'viaje de premio',
    active: true
  },
  {
    id: 61,
    language: 'fr',
    correlation_id: 1,
    description: 'formation professionnelle',
    active: true
  },
  {
    id: 62,
    language: 'fr',
    correlation_id: 2,
    description: 'participation',
    active: true
  },
  {
    id: 63,
    language: 'fr',
    correlation_id: 3,
    description: 'l\'assurance maladie complémentaire',
    active: true
  },
  {
    id: 64,
    language: 'fr',
    correlation_id: 4,
    description: 'l\'assurance-vie',
    active: true
  },
  {
    id: 65,
    language: 'fr',
    correlation_id: 5,
    description: 'voiture de société',
    active: true
  },
  {
    id: 66,
    language: 'fr',
    correlation_id: 6,
    description: 'téléphone mobile d\'entreprise',
    active: true
  },
  {
    id: 67,
    language: 'fr',
    correlation_id: 7,
    description: 'ordinateur de l\'entreprise',
    active: true
  },
  {
    id: 68,
    language: 'fr',
    correlation_id: 8,
    description: 'cantine d\'entreprise',
    active: true
  },
  {
    id: 69,
    language: 'fr',
    correlation_id: 9,
    description: 'parking de l\'entreprise',
    active: true
  },
  {
    id: 70,
    language: 'fr',
    correlation_id: 10,
    description: 'remboursement du téléphone portable',
    active: true
  },
  {
    id: 71,
    language: 'fr',
    correlation_id: 11,
    description: 'remboursement par internet',
    active: true
  },
  {
    id: 72,
    language: 'fr',
    correlation_id: 12,
    description: 'les frais de logement',
    active: true
  },
  {
    id: 73,
    language: 'fr',
    correlation_id: 13,
    description: 'abonnement aux transports publics',
    active: true
  },
  {
    id: 74,
    language: 'fr',
    correlation_id: 14,
    description: 'les bons de repas',
    active: true
  },
  {
    id: 75,
    language: 'fr',
    correlation_id: 15,
    description: 'des collations gratuites',
    active: true
  },
  {
    id: 76,
    language: 'fr',
    correlation_id: 16,
    description: 'des distributeurs automatiques de snacks et de boissons',
    active: true
  },
  {
    id: 77,
    language: 'fr',
    correlation_id: 17,
    description: 'fonds de pension',
    active: true
  },
  {
    id: 78,
    language: 'fr',
    correlation_id: 18,
    description: 'horaires de travail flexibles',
    active: true
  },
  {
    id: 79,
    language: 'fr',
    correlation_id: 19,
    description: 'des remises sur les produits ou services de l\'entreprise',
    active: true
  },
  {
    id: 80,
    language: 'fr',
    correlation_id: 20,
    description: 'voyage de prix',
    active: true
  },
  {
    id: 81,
    language: 'de',
    correlation_id: 1,
    description: 'Berufsausbildung',
    active: true
  },
  {
    id: 82,
    language: 'de',
    correlation_id: 2,
    description: 'Aktienbesitz',
    active: true
  },
  {
    id: 83,
    language: 'de',
    correlation_id: 3,
    description: 'Zusatzkrankenversicherung',
    active: true
  },
  {
    id: 84,
    language: 'de',
    correlation_id: 4,
    description: 'Lebensversicherung',
    active: true
  },
  {
    id: 85,
    language: 'de',
    correlation_id: 5,
    description: 'Firmenwagen',
    active: true
  },
  {
    id: 86,
    language: 'de',
    correlation_id: 6,
    description: 'Firmenhandy',
    active: true
  },
  {
    id: 87,
    language: 'de',
    correlation_id: 7,
    description: 'Firmencomputer',
    active: true
  },
  {
    id: 88,
    language: 'de',
    correlation_id: 8,
    description: 'Betriebskantine',
    active: true
  },
  {
    id: 89,
    language: 'de',
    correlation_id: 9,
    description: 'Firmenparkplatz',
    active: true
  },
  {
    id: 90,
    language: 'de',
    correlation_id: 10,
    description: 'Erstattung des Mobiltelefons',
    active: true
  },
  {
    id: 91,
    language: 'de',
    correlation_id: 11,
    description: 'Internet-Rückerstattung',
    active: true
  },
  {
    id: 92,
    language: 'de',
    correlation_id: 12,
    description: 'Unterbringungskosten',
    active: true
  },
  {
    id: 93,
    language: 'de',
    correlation_id: 13,
    description: 'Abonnement für öffentliche Verkehrsmittel',
    active: true
  },
  {
    id: 94,
    language: 'de',
    correlation_id: 14,
    description: 'Essensgutscheine',
    active: true
  },
  {
    id: 95,
    language: 'de',
    correlation_id: 15,
    description: 'kostenlose Snacks',
    active: true
  },
  {
    id: 96,
    language: 'de',
    correlation_id: 16,
    description: 'Verkaufsautomaten für Snacks und Getränke',
    active: true
  },
  {
    id: 97,
    language: 'de',
    correlation_id: 17,
    description: 'Rentenfonds',
    active: true
  },
  {
    id: 98,
    language: 'de',
    correlation_id: 18,
    description: 'flexible Arbeitszeiten',
    active: true
  },
  {
    id: 99,
    language: 'de',
    correlation_id: 19,
    description: 'Rabatte auf Produkte oder Dienstleistungen des Unternehmens',
    active: true
  },
  {
    id: 100,
    language: 'de',
    correlation_id: 20,
    description: 'Preisreise',
    active: true
  },
  {
    id: 101,
    language: 'pt',
    correlation_id: 1,
    description: 'formação profissional',
    active: true
  },
  {
    id: 102,
    language: 'pt',
    correlation_id: 2,
    description: 'participação',
    active: true
  },
  {
    id: 103,
    language: 'pt',
    correlation_id: 3,
    description: 'seguro complementar de saúde',
    active: true
  },
  {
    id: 104,
    language: 'pt',
    correlation_id: 4,
    description: 'seguro de vida',
    active: true
  },
  {
    id: 105,
    language: 'pt',
    correlation_id: 5,
    description: 'carro da companhia',
    active: true
  },
  {
    id: 106,
    language: 'pt',
    correlation_id: 6,
    description: 'telefone celular da companhia',
    active: true
  },
  {
    id: 107,
    language: 'pt',
    correlation_id: 7,
    description: 'computador da empresa',
    active: true
  },
  {
    id: 108,
    language: 'pt',
    correlation_id: 8,
    description: 'cantina da companhia',
    active: true
  },
  {
    id: 109,
    language: 'pt',
    correlation_id: 9,
    description: 'parque de estacionamento da empresa',
    active: true
  },
  {
    id: 110,
    language: 'pt',
    correlation_id: 10,
    description: 'reembolso por telefone celular',
    active: true
  },
  {
    id: 111,
    language: 'pt',
    correlation_id: 11,
    description: 'reembolso pela internet',
    active: true
  },
  {
    id: 112,
    language: 'pt',
    correlation_id: 12,
    description: 'despesas de hospedagem',
    active: true
  },
  {
    id: 113,
    language: 'pt',
    correlation_id: 13,
    description: 'subscrição de transporte público',
    active: true
  },
  {
    id: 114,
    language: 'pt',
    correlation_id: 14,
    description: 'vales de refeição',
    active: true
  },
  {
    id: 115,
    language: 'pt',
    correlation_id: 15,
    description: 'petiscos gratuitos',
    active: true
  },
  {
    id: 116,
    language: 'pt',
    correlation_id: 16,
    description: 'máquinas de venda automática de lanches e bebidas',
    active: true
  },
  {
    id: 117,
    language: 'pt',
    correlation_id: 17,
    description: 'fundo de pensão',
    active: true
  },
  {
    id: 118,
    language: 'pt',
    correlation_id: 18,
    description: 'horário de trabalho flexível',
    active: true
  },
  {
    id: 119,
    language: 'pt',
    correlation_id: 19,
    description: 'descontos em produtos ou serviços da empresa',
    active: true
  },
  {
    id: 120,
    language: 'pt',
    correlation_id: 20,
    description: 'viagem prêmio',
    active: true
  }
]
export function getBenefits (lang) {
  return benefits.filter(e => e.active && e.language === lang)
}
