
export default {
  data () {
    return {
      $gtm: null
    }
  },
  computed: {
    isMobile () {
      // check for common mobile user agents
      if (window.innerWidth < 768 || navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) {
        // the user is using a mobile device, so redirect to the mobile version of the website
        return true
      }
      return false
    }
  },
  methods: {
    trackMenu(menu) {
      console.log('track', menu)
      this.$gtm.push({
        event: 'click_menu',
        event_data: {
          menu_location: this.isMobile ? 'footer' : 'header',
          menu_type: 'button',
          menu_text: menu,
          page_url: this.$router.currentRoute.fullPath,
          page_title: document ? document?.title : 'CVing'
        }
      })
      if (menu === this.$t('menu.suggest')) {
        const sectionFormatted = this.$t('dashboard.career') + '😎'
        this.$gtm.push({
          event: 'orp_dashboard',
          event_data: {
            section: sectionFormatted.replaceAll(/\s/g, ''),
            sub_section: 'home',
            page_url: this.$router.currentRoute.fullPath,
            page_title: document ? document?.title : 'CVing'
          }
        })
      }
    }
  }
}
