export default {
  setRefresh: ({ commit }, payload) => {
    console.log('payload', payload)
    commit('SET_REFRESH', payload)
  },
  setPosts: ({ commit }, payload) => {
    commit('SET_POSTS', payload)
  },

  setCurrentPage: ({ commit }, payload) => {
    commit('SET_CURRENT_PAGE', payload)
  },

  setSearchTitle: ({ commit }, payload) => {
    commit('SET_SEARCH_TITLE', payload)
  },

  setCity: ({ commit }, payload) => {
    commit('SET_CITY', payload)
  },
  setRadius: ({ commit }, payload) => {
    console.log(payload)
    commit('SET_RADIUS', payload)
  },
  setLat: ({ commit }, payload) => {
    commit('SET_LAT', payload)
  },
  setVerticaliRadar: ({ commit }, payload) => {
    commit('SET_VERTICALI_RADAR', payload)
  },
  setLon: ({ commit }, payload) => {
    commit('SET_LON', payload)
  },
  setOrpSuggestion: ({ commit }, payload) => {
    commit('SET_ORP_SUGGESTION', payload)
  },
  setOrpOutcome: ({ commit }, payload) => {
    commit('SET_ORP_OUTCOME', payload)
  }
}
