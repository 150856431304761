const seniorities = [
  {
    id: 1,
    language: 'it',
    correlation_id: 0,
    description: 'Non specificato',
    active: true
  },
  {
    id: 2,
    language: 'it',
    correlation_id: 1,
    description: 'Intern',
    active: true
  },
  {
    id: 3,
    language: 'it',
    correlation_id: 2,
    description: 'Junior',
    active: true
  },
  {
    id: 4,
    language: 'it',
    correlation_id: 3,
    description: 'Middle',
    active: true
  },
  {
    id: 5,
    language: 'it',
    correlation_id: 4,
    description: 'Senior',
    active: true
  },
  {
    id: 6,
    language: 'en',
    correlation_id: 0,
    description: 'Not specified',
    active: true
  },
  {
    id: 7,
    language: 'en',
    correlation_id: 1,
    description: 'Intern',
    active: true
  },
  {
    id: 8,
    language: 'en',
    correlation_id: 2,
    description: 'Junior',
    active: true
  },
  {
    id: 9,
    language: 'en',
    correlation_id: 3,
    description: 'Middle',
    active: true
  },
  {
    id: 10,
    language: 'en',
    correlation_id: 4,
    description: 'Senior',
    active: true
  },
  {
    id: 11,
    language: 'es',
    correlation_id: 0,
    description: 'No se especifica',
    active: true
  },
  {
    id: 12,
    language: 'es',
    correlation_id: 1,
    description: 'Pasante',
    active: true
  },
  {
    id: 13,
    language: 'es',
    correlation_id: 2,
    description: 'Junior',
    active: true
  },
  {
    id: 14,
    language: 'es',
    correlation_id: 3,
    description: 'Middle',
    active: true
  },
  {
    id: 15,
    language: 'es',
    correlation_id: 4,
    description: 'Senior',
    active: true
  },
  {
    id: 16,
    language: 'fr',
    correlation_id: 0,
    description: 'Nicht angegeben',
    active: true
  },
  {
    id: 17,
    language: 'fr',
    correlation_id: 1,
    description: 'Praktikant',
    active: true
  },
  {
    id: 18,
    language: 'fr',
    correlation_id: 2,
    description: 'Junior',
    active: true
  },
  {
    id: 19,
    language: 'fr',
    correlation_id: 3,
    description: 'Middle',
    active: true
  },
  {
    id: 20,
    language: 'fr',
    correlation_id: 4,
    description: 'Senior',
    active: true
  },
  {
    id: 21,
    language: 'de',
    correlation_id: 0,
    description: 'Non spécifié',
    active: true
  },
  {
    id: 22,
    language: 'de',
    correlation_id: 1,
    description: 'Stagiaire',
    active: true
  },
  {
    id: 23,
    language: 'de',
    correlation_id: 2,
    description: 'Junior',
    active: true
  },
  {
    id: 24,
    language: 'de',
    correlation_id: 3,
    description: 'Middle',
    active: true
  },
  {
    id: 25,
    language: 'de',
    correlation_id: 4,
    description: 'Senior',
    active: true
  },
  {
    id: 26,
    language: 'pt',
    correlation_id: 0,
    description: 'Não especificado',
    active: true
  },
  {
    id: 27,
    language: 'pt',
    correlation_id: 1,
    description: 'Estagiário',
    active: true
  },
  {
    id: 28,
    language: 'pt',
    correlation_id: 2,
    description: 'Júnior',
    active: true
  },
  {
    id: 29,
    language: 'pt',
    correlation_id: 3,
    description: 'Médio',
    active: true
  },
  {
    id: 30,
    language: 'pt',
    correlation_id: 4,
    description: 'Sênior',
    active: true
  }
]
export function getSeniorities (lang) {
  return seniorities.filter(e => e.active && e.language === lang)
}
