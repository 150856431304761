const workingHours = [
  {
    id: 1,
    language: 'it',
    correlation_id: 0,
    description: 'Non specificato',
    active: true
  },
  {
    id: 2,
    language: 'it',
    correlation_id: 1,
    description: 'Da lunedì a venerdì',
    active: true
  },
  {
    id: 3,
    language: 'it',
    correlation_id: 2,
    description: 'Turni',
    active: true
  },
  {
    id: 4,
    language: 'it',
    correlation_id: 3,
    description: 'Full time',
    active: true
  },
  {
    id: 5,
    language: 'it',
    correlation_id: 4,
    description: 'Part time',
    active: true
  },
  {
    id: 6,
    language: 'it',
    correlation_id: 5,
    description: 'Turno diurno',
    active: true
  },
  {
    id: 7,
    language: 'it',
    correlation_id: 6,
    description: 'Turno notturno',
    active: true
  },
  {
    id: 8,
    language: 'it',
    correlation_id: 7,
    description: 'Festivo',
    active: true
  },
  {
    id: 9,
    language: 'it',
    correlation_id: 8,
    description: 'Solo weekend',
    active: true
  },
  {
    id: 10,
    language: 'it',
    correlation_id: 9,
    description: 'Straordinari',
    active: true
  },
  {
    id: 11,
    language: 'en',
    correlation_id: 0,
    description: 'Not specified',
    active: true
  },
  {
    id: 12,
    language: 'en',
    correlation_id: 1,
    description: 'Monday through Friday',
    active: true
  },
  {
    id: 13,
    language: 'en',
    correlation_id: 2,
    description: 'Shifts',
    active: true
  },
  {
    id: 14,
    language: 'en',
    correlation_id: 3,
    description: 'Full time',
    active: true
  },
  {
    id: 15,
    language: 'en',
    correlation_id: 4,
    description: 'Part time',
    active: true
  },
  {
    id: 16,
    language: 'en',
    correlation_id: 5,
    description: 'Day shift',
    active: true
  },
  {
    id: 17,
    language: 'en',
    correlation_id: 6,
    description: 'Night shift',
    active: true
  },
  {
    id: 18,
    language: 'en',
    correlation_id: 7,
    description: 'Holiday',
    active: true
  },
  {
    id: 19,
    language: 'en',
    correlation_id: 8,
    description: 'Weekends only',
    active: true
  },
  {
    id: 20,
    language: 'en',
    correlation_id: 9,
    description: 'Overtime',
    active: true
  },
  {
    id: 21,
    language: 'es',
    correlation_id: 0,
    description: 'No se especifica',
    active: true
  },
  {
    id: 22,
    language: 'es',
    correlation_id: 1,
    description: 'De lunes a viernes',
    active: true
  },
  {
    id: 23,
    language: 'es',
    correlation_id: 2,
    description: 'Turnos',
    active: true
  },
  {
    id: 24,
    language: 'es',
    correlation_id: 3,
    description: 'A tiempo completo',
    active: true
  },
  {
    id: 25,
    language: 'es',
    correlation_id: 4,
    description: 'A tiempo parcial',
    active: true
  },
  {
    id: 26,
    language: 'es',
    correlation_id: 5,
    description: 'Turno de día',
    active: true
  },
  {
    id: 27,
    language: 'es',
    correlation_id: 6,
    description: 'Turno de noche',
    active: true
  },
  {
    id: 28,
    language: 'es',
    correlation_id: 7,
    description: 'Vacaciones',
    active: true
  },
  {
    id: 29,
    language: 'es',
    correlation_id: 8,
    description: 'Sólo fin de semana',
    active: true
  },
  {
    id: 30,
    language: 'es',
    correlation_id: 9,
    description: 'Horas extras',
    active: true
  },
  {
    id: 31,
    language: 'fr',
    correlation_id: 0,
    description: 'Non spécifié',
    active: true
  },
  {
    id: 32,
    language: 'fr',
    correlation_id: 1,
    description: 'Du lundi au vendredi',
    active: true
  },
  {
    id: 33,
    language: 'fr',
    correlation_id: 2,
    description: 'Quarts de travail',
    active: true
  },
  {
    id: 34,
    language: 'fr',
    correlation_id: 3,
    description: 'Temps plein',
    active: true
  },
  {
    id: 35,
    language: 'fr',
    correlation_id: 4,
    description: 'Temps partiel',
    active: true
  },
  {
    id: 36,
    language: 'fr',
    correlation_id: 5,
    description: 'Poste de jour',
    active: true
  },
  {
    id: 37,
    language: 'fr',
    correlation_id: 6,
    description: 'Équipe de nuit',
    active: true
  },
  {
    id: 38,
    language: 'fr',
    correlation_id: 7,
    description: 'Vacances',
    active: true
  },
  {
    id: 39,
    language: 'fr',
    correlation_id: 8,
    description: 'Week-ends uniquement',
    active: true
  },
  {
    id: 40,
    language: 'fr',
    correlation_id: 9,
    description: 'Heures supplémentaires',
    active: true
  },
  {
    id: 41,
    language: 'de',
    correlation_id: 0,
    description: 'Nicht angegeben',
    active: true
  },
  {
    id: 42,
    language: 'de',
    correlation_id: 1,
    description: 'Montag bis Freitag',
    active: true
  },
  {
    id: 43,
    language: 'de',
    correlation_id: 2,
    description: 'Shifts',
    active: true
  },
  {
    id: 44,
    language: 'de',
    correlation_id: 3,
    description: 'Ganztags',
    active: true
  },
  {
    id: 45,
    language: 'de',
    correlation_id: 4,
    description: 'Halbtags',
    active: true
  },
  {
    id: 46,
    language: 'de',
    correlation_id: 5,
    description: 'Tagesschicht',
    active: true
  },
  {
    id: 47,
    language: 'de',
    correlation_id: 6,
    description: 'Nachtschicht',
    active: true
  },
  {
    id: 48,
    language: 'de',
    correlation_id: 7,
    description: 'Urlaub',
    active: true
  },
  {
    id: 49,
    language: 'de',
    correlation_id: 8,
    description: 'Nur am Wochenende',
    active: true
  },
  {
    id: 50,
    language: 'de',
    correlation_id: 9,
    description: 'Überstunden',
    active: true
  },
  {
    id: 51,
    language: 'pt',
    correlation_id: 0,
    description: 'Non spécifié',
    active: true
  },
  {
    id: 52,
    language: 'pt',
    correlation_id: 1,
    description: 'Du lundi au vendredi',
    active: true
  },
  {
    id: 53,
    language: 'pt',
    correlation_id: 2,
    description: 'Quarts de travail',
    active: true
  },
  {
    id: 54,
    language: 'pt',
    correlation_id: 3,
    description: 'Temps plein',
    active: true
  },
  {
    id: 55,
    language: 'pt',
    correlation_id: 4,
    description: 'Temps partiel',
    active: true
  },
  {
    id: 56,
    language: 'pt',
    correlation_id: 5,
    description: 'Poste de jour',
    active: true
  },
  {
    id: 57,
    language: 'pt',
    correlation_id: 6,
    description: 'Équipe de nuit',
    active: true
  },
  {
    id: 58,
    language: 'pt',
    correlation_id: 7,
    description: 'Vacances',
    active: true
  },
  {
    id: 59,
    language: 'pt',
    correlation_id: 8,
    description: 'Week-ends uniquement',
    active: true
  },
  {
    id: 60,
    language: 'pt',
    correlation_id: 9,
    description: 'Heures supplémentaires',
    active: true
  }
]
export function getWorkingHours (lang) {
  return workingHours.filter(e => e.active && e.language === lang)
}
