var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "footer",
    class: [{
      'footer--off': _vm.$route.name === null ? false : false && (_vm.$route.name.startsWith('login') || _vm.$route.name.startsWith('signup'))
    }, {
      'footer--languages': _vm.$auth.loggedIn === undefined ? false : !_vm.$auth.loggedIn
    }]
  }, [_vm.long ? _c('div', {
    staticClass: "container-footer"
  }, [_c('div', {
    staticClass: "links_container"
  }, [_c('button', {
    staticClass: "footer__cookies",
    on: {
      "click": function ($event) {
        return _vm.toggleCookies();
      }
    }
  }, [_vm._v("\n        Cookies\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "cving_explanation"
  }, [_c('nuxt-link', {
    staticClass: "footer__logo click_logo",
    attrs: {
      "to": _vm.localePath('index')
    }
  }, [_c('img', {
    attrs: {
      "src": require("assets/images/logo.svg"),
      "width": "80",
      "height": "28",
      "alt": "CVing"
    }
  })]), _vm._v(" "), _c('p', [_vm._v("\n          " + _vm._s(_vm.$t('footer.cvingDescription')) + "\n        ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "cving_links"
  }, [_c('div', {
    staticClass: "ul-title"
  }, [_vm._v("\n          " + _vm._s(_vm.$t('footer.companies')) + "\n        ")]), _vm._v(" "), _c('ul', _vm._l(_vm.agencyLinks, function (link, index) {
    return _c('li', {
      key: 'agency_link_' + index
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          return _vm.openLink(link.link);
        },
        "tap": function ($event) {
          return _vm.openLink(link.link);
        }
      }
    }, [_vm._v(" " + _vm._s(link.text))])]);
  }), 0)]), _vm._v(" "), _c('div', {
    staticClass: "cving_links candidates"
  }, [_c('div', {
    staticClass: "ul-title"
  }, [_vm._v("\n          Candidati\n        ")]), _vm._v(" "), _c('ul', _vm._l(_vm.candidateLinks, function (link, index) {
    return _c('li', {
      key: 'agency_link_' + index
    }, [link.text != 'Test di personalità' ? _c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          return _vm.openLink(link.link);
        },
        "tap": function ($event) {
          return _vm.openLink(link.link);
        }
      }
    }, [_vm._v(" " + _vm._s(link.text))]) : _c('nuxt-link', {
      attrs: {
        "to": _vm.localePath('user-test')
      }
    }, [_c('span', [_vm._v(_vm._s(link.text))])])], 1);
  }), 0)]), _vm._v(" "), false ? _c('div', {
    staticClass: "cving_links"
  }, [_c('div', {
    staticClass: "ul-title"
  }, [_vm._v("\n          Platform\n        ")]), _vm._v(" "), _c('ul', _vm._l(_vm.platformLinks, function (link, index) {
    return _c('li', {
      key: 'agency_link_' + index
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          return _vm.openLink(link.link);
        },
        "tap": function ($event) {
          return _vm.openLink(link.link);
        }
      }
    }, [_vm._v(" " + _vm._s(link.text))])]);
  }), 0)]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "cving_links corporate"
  }, [_c('div', {
    staticClass: "ul-title"
  }, [_vm._v("\n          Corporate\n        ")]), _vm._v(" "), _c('ul', _vm._l(_vm.corporateLinks, function (link, index) {
    return _c('li', {
      key: 'agency_link_' + index
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          return _vm.openLink(link.link);
        },
        "tap": function ($event) {
          return _vm.openLink(link.link);
        }
      }
    }, [_vm._v(" " + _vm._s(link.text))])]);
  }), 0)]), _vm._v(" "), _c('div', {
    staticClass: "cving_links"
  }, [_c('div', {
    staticClass: "ul-title"
  }, [_vm._v("\n          Newsletter\n        ")]), _vm._v(" "), _c('getresponse-form', {
    attrs: {
      "form-id": "7eb00f39-47c7-411b-8d57-012951f743c7",
      "e": "0"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "links_container last-container"
  }, [_c('div', {
    staticClass: "links-icon"
  }, _vm._l(_vm.otherLinks, function (l, index) {
    return _c('div', {
      key: 'icons_link_' + l.name + '_' + index,
      staticClass: "link"
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          return _vm.openLink(l.link);
        },
        "tap": function ($event) {
          return _vm.openLink(l.link);
        }
      }
    }, [_c('fa', {
      staticStyle: {
        "height": "20px",
        "width": "20px"
      },
      attrs: {
        "icon": ['fab', l.name]
      }
    })], 1)]);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "text-links"
  }, [_vm._l(_vm.textLinks, function (l, index) {
    return _c('div', {
      key: 'link_' + l.name + '_' + index,
      staticClass: "link"
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          return _vm.openLink(l.link);
        },
        "tap": function ($event) {
          return _vm.openLink(l.link);
        }
      }
    }, [_c('span', [_vm._v(" " + _vm._s(l.name))])])]);
  }), _vm._v(" "), _c('div', {
    staticClass: "link"
  }, [_c('a', {
    attrs: {
      "href": "javascript: Cookiebot.renew()"
    }
  }, [_vm._v(_vm._s(_vm.$t('footer.cookieSettings')))])])], 2)])]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "footer__details"
  }, [!_vm.long && false ? _c('nuxt-link', {
    staticClass: "footer__logo click_logo",
    attrs: {
      "to": _vm.localePath('index')
    }
  }, [_c('img', {
    attrs: {
      "src": require("assets/images/logo.svg"),
      "width": "80",
      "height": "28",
      "alt": "CVing"
    }
  })]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "short-footer"
  }, [_c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$auth.loggedIn === undefined ? false : !_vm.$auth.loggedIn,
      expression: "$auth.loggedIn === undefined ? false : !$auth.loggedIn"
    }],
    staticClass: "footer__languages"
  }, _vm._l(_vm.getLangs, function (locale) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.$i18n.locale !== locale.code,
        expression: "$i18n.locale !== locale.code "
      }],
      key: locale.code,
      class: {
        'hidden-flag': _vm.$i18n.locale === locale.code
      },
      attrs: {
        "to": _vm.switchLocalePath(locale.code)
      },
      on: {
        "click": function ($event) {
          return _vm.changeLanguage(locale.code);
        }
      }
    }, [_c('span', [_c('img', {
      attrs: {
        "src": '/assets/images/flags/' + locale.code + '.png',
        "width": "16",
        "alt": locale.code
      }
    }), _vm._v(" "), _c('span', {
      staticClass: "code"
    }, [_vm._v(" " + _vm._s(locale.code))])])]);
  }), 0), _vm._v(" "), _c('p', {
    staticClass: "copyright"
  }, [_vm._v("\n        Copyright " + _vm._s(_vm.$dayjs().year()) + " © CVing S.r.l. | P. IVA 04681350270 | REA MI - 2515112 | Aut. Def. ANPAL n. 0000142 del 28/11/2023\n      ")]), _vm._v(" "), _c('button', {
    staticClass: "footer__cookies",
    on: {
      "click": function ($event) {
        return _vm.toggleCookies();
      }
    }
  }, [_vm._v("\n        Cookies\n      ")])])], 1), _vm._v(" "), _c('Menu')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }