const microcategories = [
  {
    id: 1,
    id_parent: 1,
    it: 'Analista dei costi',
    en: 'Cost analyst',
    es: 'Analista de costes',
    de: 'Kostenanalytiker',
    fr: 'Analyste des coûts',
    pt: 'Analista de custos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 2,
    id_parent: 1,
    it: 'Contabile del libro paga',
    en: 'Payroll Accountant',
    es: 'Contable de nóminas',
    de: 'Lohnbuchhalter',
    fr: 'Gestionnaire de paie',
    pt: 'Contabilista de folha de pagamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 3,
    id_parent: 1,
    it: 'Preparatore fiscale',
    en: 'Tax Preparer',
    es: 'Preparador de impuestos',
    de: 'Steuerberater',
    fr: 'Préparateur d\'impôts',
    pt: 'Preparador de impostos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 4,
    id_parent: 1,
    it: 'Addetto ai mutui ipotecari',
    en: 'Mortgage Loan Officer',
    es: 'Agente de préstamos hipotecarios',
    de: 'Hypothekendarlehenssachbearbeiter',
    fr: 'Officier de prêt hypothécaire',
    pt: 'Diretor de Crédito Hipotecário',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 5,
    id_parent: 1,
    it: 'Contabile del personale',
    en: 'Staff Accountant',
    es: 'Contable de plantilla',
    de: 'Angestellter Buchhalter',
    fr: 'Comptable du personnel',
    pt: 'Funcionário da contabilidade',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 6,
    id_parent: 1,
    it: 'Revisore notturno',
    en: 'Night Auditor',
    es: 'Auditor nocturno',
    de: 'Nachtportier',
    fr: 'Auditeur de nuit',
    pt: 'Auditor Noturno',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 7,
    id_parent: 1,
    it: 'Amministratore di contabilità',
    en: 'Accounts Administrator',
    es: 'Administrador de cuentas',
    de: 'Kontenadministrator',
    fr: 'Administrateur de comptes',
    pt: 'Administrador de contas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 8,
    id_parent: 1,
    it: 'Responsabile del credito',
    en: 'Credit Manager',
    es: 'Gestor de créditos',
    de: 'Kreditmanager',
    fr: 'Gestionnaire de crédit',
    pt: 'Gestor de crédito',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 9,
    id_parent: 1,
    it: 'Addetto al credito',
    en: 'Credit Officer',
    es: 'Oficial de crédito',
    de: 'Kreditsachbearbeiter',
    fr: 'Agent de crédit',
    pt: 'Diretor de Crédito',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 10,
    id_parent: 1,
    it: 'Assistente alla contabilità',
    en: 'Accounting Assistant',
    es: 'Asistente de contabilidad',
    de: 'Buchhaltungsassistent',
    fr: 'Assistant de comptabilité',
    pt: 'Assistente de contabilidade',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 11,
    id_parent: 1,
    it: 'Responsabile del bilancio',
    en: 'Budget Manager',
    es: 'Gestor de presupuestos',
    de: 'Budget-Manager',
    fr: 'Gestionnaire budgétaire',
    pt: 'Gerente de orçamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 12,
    id_parent: 1,
    it: 'Contabile aziendale',
    en: 'Corporate Accountant',
    es: 'Contable de empresa',
    de: 'Unternehmensbuchhalter',
    fr: 'Comptable d\'entreprise',
    pt: 'Contador Corporativo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 13,
    id_parent: 1,
    it: 'Specialista della riscossione',
    en: 'Collection Specialist',
    es: 'Especialista en cobros',
    de: 'Sammlungsspezialist',
    fr: 'Spécialiste du recouvrement',
    pt: 'Especialista em cobrança',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 14,
    id_parent: 1,
    it: 'Analista di fatturazione',
    en: 'Billing Analyst',
    es: 'Analista de facturación',
    de: 'Abrechnungsanalyst',
    fr: 'Analyste de facturation',
    pt: 'Analista de cobrança',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 15,
    id_parent: 1,
    it: 'Contabile forense',
    en: 'Forensic Accountant',
    es: 'Contable forense',
    de: 'Forensischer Buchhalter',
    fr: 'Juricomptable',
    pt: 'Contador Forense',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 16,
    id_parent: 1,
    it: 'Economo',
    en: 'Bursar',
    es: 'Ecónomo',
    de: 'Schatzmeister',
    fr: 'Intendant',
    pt: 'Tesoureiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 17,
    id_parent: 1,
    it: 'Contabile fiscale',
    en: 'Tax Accountant',
    es: 'Contable de impuestos',
    de: 'Steuerberater',
    fr: 'Conseiller fiscal',
    pt: 'Contador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 18,
    id_parent: 1,
    it: 'Responsabile della contabilità',
    en: 'Accounts Receivable Manager',
    es: 'Gestor de cuentas por cobrar',
    de: 'Debitorenbuchhalter',
    fr: 'Gestionnaire des comptes clients',
    pt: 'Gerente de contas a receber',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 19,
    id_parent: 1,
    it: 'Contabile dei costi',
    en: 'Cost Accountant',
    es: 'Contable de costes',
    de: 'Kosten Buchhalter',
    fr: 'Comptable des coûts',
    pt: 'Contador de custos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 20,
    id_parent: 1,
    it: 'Contabile pubblico certificato (CPA)',
    en: 'Certified Public Accountant (CPA)',
    es: 'Contable público certificado (CPA)',
    de: 'Wirtschaftsprüfer (CPA)',
    fr: 'Expert-comptable agréé (CPA)',
    pt: 'Contador Público Certificado (CPA)',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 21,
    id_parent: 1,
    it: 'Supervisore della contabilità',
    en: 'Accounting Supervisor',
    es: 'Supervisor de contabilidad',
    de: 'Leiter der Buchhaltung',
    fr: 'Superviseur de la comptabilité',
    pt: 'Supervisor de contas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 22,
    id_parent: 1,
    it: 'Revisore senior',
    en: 'Senior Auditor',
    es: 'Auditor superior',
    de: 'Leitender Wirtschaftsprüfer',
    fr: 'Auditeur senior',
    pt: 'Auditor sênior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 23,
    id_parent: 1,
    it: 'Coordinatore della fatturazione',
    en: 'Billing Coordinator',
    es: 'Coordinador de facturación',
    de: 'Abrechnungskoordinator',
    fr: 'Coordonnateur de la facturation',
    pt: 'Coordenador de cobrança',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 24,
    id_parent: 1,
    it: 'Specialista di fatturazione',
    en: 'Billing Specialist',
    es: 'Especialista en facturación',
    de: 'Abrechnungsspezialist',
    fr: 'Spécialiste de la facturation',
    pt: 'Especialista em faturamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 25,
    id_parent: 1,
    it: 'Addetto alla fatturazione',
    en: 'Billing Clerk',
    es: 'Empleado de facturación',
    de: 'Rechnungsführer',
    fr: 'Commis à la facturation',
    pt: 'Responsável pela cobrança',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 26,
    id_parent: 1,
    it: 'Elaboratore di prestiti ipotecari',
    en: 'Mortgage Loan Processor',
    es: 'Procesador de Préstamos Hipotecarios',
    de: 'Hypothekendarlehensverarbeiter',
    fr: 'Processeur de prêts hypothécaires',
    pt: 'Processador de Empréstimo Hipotecário',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 27,
    id_parent: 1,
    it: 'Elaboratore di prestiti',
    en: 'Loan Processor',
    es: 'Procesador de préstamos',
    de: 'Kreditverarbeiter',
    fr: 'Processeur de prêt',
    pt: 'Processador de Empréstimo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 28,
    id_parent: 1,
    it: 'Responsabile fiscale',
    en: 'Tax Manager',
    es: 'Gestor de impuestos',
    de: 'Steuer Manager',
    fr: 'Gestionnaire fiscal',
    pt: 'Gerente Fiscal',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 29,
    id_parent: 1,
    it: 'Contabile senior',
    en: 'Senior Accountant',
    es: 'Contable senior',
    de: 'Senior Buchhalter',
    fr: 'Senior Accountant',
    pt: 'contador SÊNIOR',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 30,
    id_parent: 1,
    it: 'Impiegato contabile',
    en: 'Accounting Clerk',
    es: 'Empleado de contabilidad',
    de: 'Buchhalter',
    fr: 'Commis comptable',
    pt: 'Assistente de contabilidade',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 31,
    id_parent: 1,
    it: 'Analista di credito',
    en: 'Credit Analyst',
    es: 'Analista de crédito',
    de: 'Kreditanalyst',
    fr: 'Analyste de crédit',
    pt: 'Analista de crédito',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 32,
    id_parent: 1,
    it: 'Contabile',
    en: 'Bookkeeper',
    es: 'Contable',
    de: 'Buchhalter',
    fr: 'Comptable',
    pt: 'Guarda-livros',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 33,
    id_parent: 1,
    it: 'Impiegato contabile',
    en: 'Accounts Receivable Clerk',
    es: 'Empleado de cuentas por cobrar',
    de: 'Debitorenbuchhalter',
    fr: 'Commis aux comptes recevables',
    pt: 'Escriturário de contas a receber',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 34,
    id_parent: 1,
    it: 'Impiegato contabilità fornitori',
    en: 'Accounts Payable Clerk',
    es: 'Empleado de cuentas por pagar',
    de: 'Rechnungsprüfer',
    fr: 'Commis aux comptes à payer',
    pt: 'Escriturário de contas a pagar',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 35,
    id_parent: 1,
    it: 'Addetto ai prestiti',
    en: 'Loan Officer',
    es: 'Oficial de préstamos',
    de: 'Kreditsachbearbeiter',
    fr: 'Agent de crédit',
    pt: 'Diretor de Empréstimo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 36,
    id_parent: 1,
    it: 'Analista di bilancio',
    en: 'Budget Analyst',
    es: 'Analista de presupuestos',
    de: 'Budget Analyst',
    fr: 'Analyste budgétaire',
    pt: 'Analista de orçamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 37,
    id_parent: 1,
    it: 'Contabile di gestione',
    en: 'Management Accountant',
    es: 'Contable de gestión',
    de: 'Bilanzbuchhalter',
    fr: 'Comptable en management',
    pt: 'Contador de gestão',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 38,
    id_parent: 1,
    it: 'Responsabile della contabilità',
    en: 'Accounting Manager',
    es: 'Director de contabilidad',
    de: 'Buchhaltungsleiter',
    fr: 'Responsable comptable',
    pt: 'Gerente de contabilidade',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 39,
    id_parent: 1,
    it: 'Ragioniere junior',
    en: 'Junior Accountant',
    es: 'Contable junior',
    de: 'Junior Buchhalter',
    fr: 'Comptable junior',
    pt: 'Júnior Contador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 40,
    id_parent: 1,
    it: 'Contabile',
    en: 'Accountant',
    es: 'Contable',
    de: 'Buchhalter',
    fr: 'Comptable',
    pt: 'Contador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 41,
    id_parent: 2,
    it: 'Perito liquidatore',
    en: 'Claims Adjuster',
    es: 'Ajustador de siniestros',
    de: 'Schadenregulierer',
    fr: 'Expert en sinistre',
    pt: 'Ajustador de sinistros',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 42,
    id_parent: 2,
    it: 'Controllore',
    en: 'Controller',
    es: 'Interventor',
    de: 'Regler',
    fr: 'Manette',
    pt: 'Controlador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 43,
    id_parent: 2,
    it: 'Attuario',
    en: 'Actuary',
    es: 'Actuario',
    de: 'Aktuar',
    fr: 'Actuaire',
    pt: 'Atuário',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 44,
    id_parent: 2,
    it: 'CFO non profit',
    en: 'Nonprofit CFO',
    es: 'Director financiero sin ánimo de lucro',
    de: 'Gemeinnütziger CFO',
    fr: 'Directeur financier à but non lucratif',
    pt: 'CFO sem fins lucrativos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 45,
    id_parent: 2,
    it: 'Capo delle Finanze',
    en: 'Head of Finance',
    es: 'Jefe de Finanzas',
    de: 'Finanzleiter',
    fr: 'Directeur financier',
    pt: 'Chefe de finanças',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 46,
    id_parent: 2,
    it: 'Amministratore finanziario',
    en: 'Finance Administrator',
    es: 'Administrador financiero',
    de: 'Finanzverwalter',
    fr: 'Administrateur financier',
    pt: 'Administrador Financeiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 47,
    id_parent: 2,
    it: 'Consulente finanziario',
    en: 'Financial Consultant',
    es: 'Consultor financiero',
    de: 'Finanzberater',
    fr: 'Conseiller financier',
    pt: 'Consultor financeiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 48,
    id_parent: 2,
    it: 'Commercialista finanziario',
    en: 'Financial Accountant',
    es: 'Contable financiero',
    de: 'Finanzbuchhalter',
    fr: 'Comptable financier',
    pt: 'Contador financeiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 49,
    id_parent: 2,
    it: 'Assistente finanziario',
    en: 'Finance Assistant',
    es: 'Asistente financiero',
    de: 'Finanzassistent',
    fr: 'Assistant Financier',
    pt: 'assistente de finanças',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 50,
    id_parent: 2,
    it: 'Analista finanziario senior',
    en: 'Senior Financial Analyst',
    es: 'Analista financiero senior',
    de: 'Senior Financial Analyst',
    fr: 'Analyste financier principal',
    pt: 'Analista Financeiro Sênior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 51,
    id_parent: 2,
    it: 'Revisore esterno',
    en: 'External Auditor',
    es: 'Auditor externo',
    de: 'Externer Prüfer',
    fr: 'Auditeur externe',
    pt: 'Auditor externo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 52,
    id_parent: 2,
    it: 'Impiegato finanziario',
    en: 'Finance Clerk',
    es: 'Empleado de finanzas',
    de: 'Finanzkaufmann',
    fr: 'Commis aux finances',
    pt: 'Escriturário Financeiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 53,
    id_parent: 2,
    it: 'Banchiere al dettaglio',
    en: 'Retail Banker',
    es: 'Banquero minorista',
    de: 'Retail-Banker',
    fr: 'Banquier de détail',
    pt: 'Banqueiro de Varejo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 54,
    id_parent: 2,
    it: 'Banchiere d\'investimento',
    en: 'Investment Banker',
    es: 'Banquero de inversiones',
    de: 'Investmentbanker',
    fr: 'Banquier d\'investissement',
    pt: 'Banqueiro de investimento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 55,
    id_parent: 2,
    it: 'Specialista finanziario',
    en: 'Financial Specialist',
    es: 'Especialista financiero',
    de: 'Finanzspezialist',
    fr: 'Spécialiste financier',
    pt: 'Especialista Financeiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 56,
    id_parent: 2,
    it: 'Banchiere personale',
    en: 'Personal Banker',
    es: 'Banquero personal',
    de: 'Persönlicher Bankier',
    fr: 'Banquier personnel',
    pt: 'Banqueiro pessoal',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 57,
    id_parent: 2,
    it: 'Pianificatore finanziario',
    en: 'Financial Planner',
    es: 'Planificador financiero',
    de: 'Finanzberater',
    fr: 'Planificateur financier',
    pt: 'Consultor financeiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 58,
    id_parent: 2,
    it: 'Funzionario finanziario',
    en: 'Finance Officer',
    es: 'Oficial de finanzas',
    de: 'Finanzbeamter',
    fr: 'Responsable des finances',
    pt: 'Oficial de Finanças',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 59,
    id_parent: 2,
    it: 'Manager finanziario',
    en: 'Financial Manager',
    es: 'Director financiero',
    de: 'Finanzdirektor',
    fr: 'Manager financier',
    pt: 'Gerente financeiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 60,
    id_parent: 2,
    it: 'Broker assicurativo',
    en: 'Insurance Broker',
    es: 'Corredor de seguros',
    de: 'Versicherungsmakler',
    fr: 'Courtier d\'assurance',
    pt: 'Corretor de seguros',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 61,
    id_parent: 2,
    it: 'Analista finanziario',
    en: 'Financial Analyst',
    es: 'Analista financiero',
    de: 'Finanzanalyst',
    fr: 'Analyste financier',
    pt: 'Analista financeiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 62,
    id_parent: 2,
    it: 'Analista degli investimenti',
    en: 'Investment Analyst',
    es: 'Analista de inversiones',
    de: 'Investmentanalyst',
    fr: 'Analyste en investissements',
    pt: 'Analista de investimentos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 63,
    id_parent: 2,
    it: 'Tesoriere',
    en: 'Treasurer',
    es: 'Tesorero',
    de: 'Schatzmeister',
    fr: 'Trésorier',
    pt: 'Tesoureiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 64,
    id_parent: 2,
    it: 'CFO',
    en: 'CFO',
    es: 'CFO',
    de: 'Finanzvorstand',
    fr: 'CFO',
    pt: 'Diretor Financeiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 65,
    id_parent: 2,
    it: 'Direttore finanziario',
    en: 'Director of Finance',
    es: 'Director de Finanzas',
    de: 'Finanzdirektor',
    fr: 'Directeur des Finances',
    pt: 'Diretor de Finanças',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 66,
    id_parent: 2,
    it: 'Sottoscrittore assicurativo',
    en: 'Insurance Underwriter',
    es: 'Suscriptor de seguros',
    de: 'Versicherungsmakler',
    fr: 'Souscripteur d\'assurance',
    pt: 'Subscritor de Seguros',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 67,
    id_parent: 2,
    it: 'Consulente finanziario',
    en: 'Financial Adviser',
    es: 'Asesor financiero',
    de: 'Finanzberater',
    fr: 'Conseiller financier',
    pt: 'Conselheiro financeiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 68,
    id_parent: 2,
    it: 'Assistente del controllore',
    en: 'Assistant Controller',
    es: 'Asistente del interventor',
    de: 'Assistant Controller',
    fr: 'Contrôleur adjoint',
    pt: 'Controlador Assistente',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 69,
    id_parent: 2,
    it: 'Controllore finanziario',
    en: 'Financial Controller',
    es: 'Interventor financiero',
    de: 'Finanzkontrolleur',
    fr: 'Contrôleur financier',
    pt: 'Controlador financeiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 70,
    id_parent: 3,
    it: 'Supervisore delle operazioni',
    en: 'Operations Supervisor',
    es: 'Supervisor de Operaciones',
    de: 'Einsatzleiter',
    fr: 'superviseur des opérations',
    pt: 'Supervisor de operações',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 71,
    id_parent: 3,
    it: 'Vicepresidente senior',
    en: 'Senior Vice President',
    es: 'Vicepresidente Senior',
    de: 'Leitender Vizepräsident',
    fr: 'Vice-président principal',
    pt: 'Vice presidente Senior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 72,
    id_parent: 3,
    it: 'Tirocinante di gestione',
    en: 'Management Trainee',
    es: 'Aprendiz de gestión',
    de: 'Managementlehrling',
    fr: 'Stagiaire en gestion',
    pt: 'Estagiário de Gestão',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 73,
    id_parent: 3,
    it: 'Supervisore di turno',
    en: 'Shift supervisor',
    es: 'Supervisor de turno',
    de: 'Schichtleiter',
    fr: 'Chef de quart',
    pt: 'Supervisor de turno',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 74,
    id_parent: 3,
    it: 'Capo turno',
    en: 'Shift Leader',
    es: 'Jefe de turno',
    de: 'Schichtleiter',
    fr: 'Chef d\'équipe',
    pt: 'Líder de Turno',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 75,
    id_parent: 3,
    it: 'Capo delle operazioni',
    en: 'Head of Operations',
    es: 'Jefe de Operaciones',
    de: 'Betriebsleiter',
    fr: 'Chef des opérations',
    pt: 'Chefe de operações',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 76,
    id_parent: 3,
    it: 'Amministratore del programma',
    en: 'Program Administrator',
    es: 'Administrador de programas',
    de: 'Programmadministrator',
    fr: 'Administrateur du programme',
    pt: 'Administrador do programa',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 77,
    id_parent: 3,
    it: 'Direttore Generale',
    en: 'General Manager',
    es: 'Director general',
    de: 'Hauptgeschäftsführer',
    fr: 'Directeur général',
    pt: 'Director Geral',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 78,
    id_parent: 3,
    it: 'Traduttore',
    en: 'Translator',
    es: 'Traductor',
    de: 'Übersetzer',
    fr: 'Traducteur',
    pt: 'Tradutor',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 79,
    id_parent: 3,
    it: 'Amministratore di progetto',
    en: 'Project Administrator',
    es: 'Administrador de proyectos',
    de: 'Projektleiter',
    fr: 'administrateur de projet',
    pt: 'Administrador de projetos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 80,
    id_parent: 3,
    it: 'Controllore di documenti',
    en: 'Document Controller',
    es: 'Controlador de documentos',
    de: 'Dokumenten-Controller',
    fr: 'Contrôleur de document',
    pt: 'Controlador de documento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 81,
    id_parent: 3,
    it: 'Coordinatore amministrativo',
    en: 'Administrative Coordinator',
    es: 'Coordinador administrativo',
    de: 'Verwaltungskoordinator',
    fr: 'Coordonnateur administratif',
    pt: 'Coordenador Administrativo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 82,
    id_parent: 3,
    it: 'Pianificatore strategico',
    en: 'Strategic Planner',
    es: 'Planificador estratégico',
    de: 'Strategischer Planer',
    fr: 'Planificateur stratégique',
    pt: 'Planejador Estratégico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 83,
    id_parent: 3,
    it: 'Impiegato della posta',
    en: 'Mail Clerk',
    es: 'Empleado de correo',
    de: 'Postangestellter',
    fr: 'Commis au courrier',
    pt: 'Correio',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 84,
    id_parent: 3,
    it: 'Assistente del personale',
    en: 'Staff Assistant',
    es: 'Asistente de personal',
    de: 'Personalassistent',
    fr: 'Assistant du personnel',
    pt: 'Assistente de equipe',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 85,
    id_parent: 3,
    it: 'Dattilografa',
    en: 'Typist',
    es: 'Mecanógrafo',
    de: 'Schreibkraft',
    fr: 'Dactylographe',
    pt: 'Datilógrafo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 86,
    id_parent: 3,
    it: 'Amministratore del contratto',
    en: 'Contract Administrator',
    es: 'Administrador de contratos',
    de: 'Vertragsverwalter',
    fr: 'Administrateur de contrat',
    pt: 'Administrador de Contratos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 87,
    id_parent: 3,
    it: 'Assistente esecutivo senior',
    en: 'Senior Executive Assistant',
    es: 'Asistente ejecutivo senior',
    de: 'Assistent der Geschäftsleitung',
    fr: 'Adjointe exécutive principale',
    pt: 'Assistente Executivo Sênior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 88,
    id_parent: 3,
    it: 'Assistente virtuale',
    en: 'Virtual Assistant',
    es: 'Asistente virtual',
    de: 'Virtueller Assistent',
    fr: 'Assistant virtuel',
    pt: 'Assistente virtual',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 89,
    id_parent: 3,
    it: 'Funzionario amministrativo',
    en: 'Administrative officer',
    es: 'Oficial administrativo',
    de: 'Verwaltungsbeamter',
    fr: 'Agent administratif',
    pt: 'Diretor administrativo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 90,
    id_parent: 3,
    it: 'Amministratore',
    en: 'Administrator',
    es: 'Administrador',
    de: 'Administrator',
    fr: 'Administrateur',
    pt: 'Administrador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 91,
    id_parent: 3,
    it: 'Capo squadra',
    en: 'Team Leader',
    es: 'Jefe de equipo',
    de: 'Teamleiter',
    fr: 'Chef d\'équipe',
    pt: 'Líder da equipe',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 92,
    id_parent: 3,
    it: 'Assistente amministrativo senior',
    en: 'Senior Administrative Assistant',
    es: 'Asistente Administrativo Senior',
    de: 'Leitender Verwaltungsassistent',
    fr: 'Assistant administratif senior',
    pt: 'Assistente administrativo Senior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 93,
    id_parent: 3,
    it: 'Responsabile del front office',
    en: 'Front Office Manager',
    es: 'Director de Front Office',
    de: 'Front-Office-Manager',
    fr: 'chef de réception',
    pt: 'Gerente principal do escritório',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 94,
    id_parent: 3,
    it: 'Assistente amministrativo esecutivo',
    en: 'Executive Administrative Assistant',
    es: 'Asistente administrativo ejecutivo',
    de: 'Assistentin der Geschäftsführung',
    fr: 'Adjointe administrative exécutive',
    pt: 'Assistente administrativo executivo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 95,
    id_parent: 3,
    it: 'Manager d\'affari',
    en: 'Business Manager',
    es: 'Gerente de negocios',
    de: 'Geschäftsführer',
    fr: 'Chef d\'entreprise',
    pt: 'Gerente de negócios',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 96,
    id_parent: 3,
    it: 'Assistente manager',
    en: 'Assistant Manager',
    es: 'Asistente de dirección',
    de: 'Direktionsassistent',
    fr: 'Directeur adjoint',
    pt: 'Assistente de gerente',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 97,
    id_parent: 3,
    it: 'Responsabile del programma',
    en: 'Program Manager',
    es: 'Director de programa',
    de: 'Progamm Manager',
    fr: 'Gestionnaire de programme',
    pt: 'Gerenciador de programa',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 98,
    id_parent: 3,
    it: 'Coordinatore del programma',
    en: 'Program Coordinator',
    es: 'Coordinador de programas',
    de: 'Programm Koordinator',
    fr: 'Coordonnateur de programme',
    pt: 'Coordenador de programa',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 99,
    id_parent: 3,
    it: 'Segretario',
    en: 'Secretary',
    es: 'Secretaria',
    de: 'Sekretär',
    fr: 'secrétaire',
    pt: 'secretário',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 100,
    id_parent: 3,
    it: 'Segretario esecutivo',
    en: 'Executive Secretary',
    es: 'Secretaria ejecutiva',
    de: 'Exekutivsekretär',
    fr: 'Secrétaire exécutif',
    pt: 'Secretaria Executiva',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 101,
    id_parent: 3,
    it: 'Responsabile dell\'amministrazione',
    en: 'Administration Manager',
    es: 'Director de Administración',
    de: 'Administration Manager',
    fr: 'Responsable Administratif',
    pt: 'Gerente da Administração',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 102,
    id_parent: 3,
    it: 'Direttore del programma',
    en: 'Program Director',
    es: 'Director de programa',
    de: 'Programmdirektor',
    fr: 'Directeur de programme',
    pt: 'Diretor de programa',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 103,
    id_parent: 3,
    it: 'Impiegato d\'archivio',
    en: 'File Clerk',
    es: 'Empleado de archivo',
    de: 'Aktenschreiber',
    fr: 'Archiviste',
    pt: 'Arquivista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 104,
    id_parent: 3,
    it: 'Coordinatore dell\'ufficio',
    en: 'Office Coordinator',
    es: 'Coordinador de oficina',
    de: 'Bürokoordinator',
    fr: 'Chef de bureau',
    pt: 'Coordenador de escritório',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 105,
    id_parent: 3,
    it: 'Impiegato per l\'inserimento dei dati',
    en: 'Data Entry Clerk',
    es: 'Empleado de entrada de datos',
    de: 'Dateneingabe Schreiber',
    fr: 'Saisie de données Commis',
    pt: 'Escriturário de Entrada de Dados',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 106,
    id_parent: 3,
    it: 'Amministratore dell\'ufficio',
    en: 'Office Administrator',
    es: 'Administrador de oficina',
    de: 'Büroverwalter',
    fr: 'Administrateur de bureau',
    pt: 'Administrador do escritório',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 107,
    id_parent: 3,
    it: 'Impiegato d\'ufficio',
    en: 'Office Clerk',
    es: 'Empleado de oficina',
    de: 'Bürokauffrau',
    fr: 'Commis de bureau',
    pt: 'Escriturário',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 108,
    id_parent: 3,
    it: 'Assistente d\'ufficio',
    en: 'Office Assistant',
    es: 'Asistente de oficina',
    de: 'Büroassistent',
    fr: 'Assistant de bureau',
    pt: 'Assistente de escritório',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 109,
    id_parent: 3,
    it: 'Assistente esecutivo',
    en: 'Executive Assistant',
    es: 'Asistente ejecutivo',
    de: 'Vorstandsassistent',
    fr: 'Assistant exécutif',
    pt: 'Assistente executivo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 110,
    id_parent: 3,
    it: 'Coordinatore di progetto',
    en: 'Project Coordinator',
    es: 'Coordinador de proyectos',
    de: 'Projektkoordinator',
    fr: 'Coordinateur de projet',
    pt: 'Coordenador de projeto',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 111,
    id_parent: 3,
    it: 'Consulente',
    en: 'Consultant',
    es: 'Consultor',
    de: 'Berater',
    fr: 'Consultant',
    pt: 'Consultor',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 112,
    id_parent: 3,
    it: 'Consulente aziendale',
    en: 'Business Consultant',
    es: 'Consultor de negocios',
    de: 'Unternehmensberater',
    fr: 'Consultant en affaires',
    pt: 'Consultor de negócios',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 113,
    id_parent: 3,
    it: 'Direttore di filiale',
    en: 'Branch Manager',
    es: 'Director de sucursal',
    de: 'Abteilungleiter',
    fr: 'Directeur de succursale',
    pt: 'Gerente de Filial',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 114,
    id_parent: 3,
    it: 'Responsabile delle operazioni',
    en: 'Operations Manager',
    es: 'Director de operaciones',
    de: 'Betriebsleiter',
    fr: 'directeur des opérations',
    pt: 'Gerente de Operações',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 115,
    id_parent: 3,
    it: 'Assistente personale',
    en: 'Personal Assistant',
    es: 'Asistente personal',
    de: 'Persönlicher Assistent',
    fr: 'Assistant personnel',
    pt: 'Assistente Pessoal',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 116,
    id_parent: 3,
    it: 'Manager di distretto',
    en: 'District Manager',
    es: 'Director de distrito',
    de: 'Bezirksleiter',
    fr: 'Chef de district',
    pt: 'Gerente de distrito',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 117,
    id_parent: 3,
    it: 'Supervisore',
    en: 'Supervisor',
    es: 'Supervisor',
    de: 'Aufsicht',
    fr: 'Superviseur',
    pt: 'Supervisor',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 118,
    id_parent: 3,
    it: 'Operatore di inserimento dati',
    en: 'Data Entry Operator',
    es: 'Operador de entrada de datos',
    de: 'Datenerfasser',
    fr: 'Opérateur de saisie de données',
    pt: 'Operador de entrada de dados',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 119,
    id_parent: 3,
    it: 'Direttore delle operazioni',
    en: 'Director of Operations',
    es: 'Director de Operaciones',
    de: 'Betriebsleiter',
    fr: 'directeur des opérations',
    pt: 'Diretor de Operações',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 120,
    id_parent: 3,
    it: 'CEO',
    en: 'CEO',
    es: 'CEO',
    de: 'CEO',
    fr: 'PDG',
    pt: 'CEO',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 121,
    id_parent: 3,
    it: 'Direttore esecutivo',
    en: 'Executive Director',
    es: 'Director Ejecutivo',
    de: 'Geschäftsführer',
    fr: 'Directeur exécutif',
    pt: 'Diretor-executivo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 122,
    id_parent: 3,
    it: 'Amministratore delegato',
    en: 'Managing Director',
    es: 'Director General',
    de: 'Geschäftsführer',
    fr: 'Directeur général',
    pt: 'Diretor-gerente',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 123,
    id_parent: 3,
    it: 'Assistente direttore',
    en: 'Assistant Director',
    es: 'Director adjunto',
    de: 'Stellvertretender Direktor',
    fr: 'Directeur assistant',
    pt: 'Diretor assistente',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 124,
    id_parent: 3,
    it: 'Direttore Amministrativo',
    en: 'Chief Administrative Officer',
    es: 'Director Administrativo',
    de: 'Hauptverwaltungsbeamter',
    fr: 'Directeur administratif',
    pt: 'Escritório do chefe administrativo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 125,
    id_parent: 3,
    it: 'COO',
    en: 'COO',
    es: 'COO',
    de: 'GURREN',
    fr: 'ROUCOULER',
    pt: 'COO',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 126,
    id_parent: 3,
    it: 'Assistente amministrativo',
    en: 'Administrative Assistant',
    es: 'Asistente administrativo',
    de: 'Administrative Assistent',
    fr: 'assistant administratif',
    pt: 'Auxiliar Administrativo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 127,
    id_parent: 3,
    it: 'Responsabile dell\'ufficio',
    en: 'Office Manager',
    es: 'Director de oficina',
    de: 'Büroleiter',
    fr: 'Responsable administratif',
    pt: 'Gerente',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 128,
    id_parent: 4,
    it: 'Direttore di Ingegneria',
    en: 'Director of Engineering',
    es: 'Director de Ingeniería',
    de: 'Direktor für Technik',
    fr: 'Directeur de l\'ingénierie',
    pt: 'Diretor de Engenharia',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 129,
    id_parent: 4,
    it: 'Sviluppatore Full Stack',
    en: 'Full Stack Developer',
    es: 'Desarrollador Full Stack',
    de: 'Full-Stack-Entwickler',
    fr: 'Développeur Full Stack',
    pt: 'Desenvolvedor Full Stack',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 130,
    id_parent: 4,
    it: 'Master Scrum',
    en: 'Scrum Master',
    es: 'Scrum Master',
    de: 'Scrum-Master',
    fr: 'Maître de mêlée',
    pt: 'Scrum Master',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 131,
    id_parent: 4,
    it: 'Responsabile delle operazioni IT',
    en: 'IT Operations Manager',
    es: 'Director de operaciones de TI',
    de: 'IT-Betriebsleiter',
    fr: 'Responsable des opérations informatiques',
    pt: 'Gerente de Operações de TI',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 132,
    id_parent: 4,
    it: 'Responsabile delle operazioni di business',
    en: 'Business Operations Manager',
    es: 'Director de Operaciones de Negocio',
    de: 'Geschäftsbetriebsleiter',
    fr: 'Responsable des opérations commerciales',
    pt: 'Gerente de Operações Comerciais',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 133,
    id_parent: 4,
    it: 'Ingegnere di elaborazione del linguaggio naturale',
    en: 'Natural Language Processing Engineer',
    es: 'Ingeniero de procesamiento del lenguaje natural',
    de: 'Ingenieur für Verarbeitung natürlicher Sprache',
    fr: 'Ingénieur en traitement du langage naturel',
    pt: 'Engenheiro de Processamento de Linguagem Natural',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 134,
    id_parent: 4,
    it: 'Ingegnere di apprendimento automatico',
    en: 'Machine Learning Engineer',
    es: 'Ingeniero de aprendizaje automático',
    de: 'Ingenieur für maschinelles Lernen',
    fr: 'Ingénieur en apprentissage automatique',
    pt: 'Engenheiro de aprendizado de máquina',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 135,
    id_parent: 4,
    it: 'Scienziato dei dati principale',
    en: 'Lead Data Scientist',
    es: 'Científico de datos principal',
    de: 'Leitender Datenwissenschaftler',
    fr: 'Scientifique principal des données',
    pt: 'Cientista de Dados Líder',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 136,
    id_parent: 4,
    it: 'Amministratore di sistema senior',
    en: 'Senior System Administrator',
    es: 'Administrador de sistemas senior',
    de: 'Leitender Systemadministrator',
    fr: 'Administrateur système principal',
    pt: 'Administrador de Sistema Sênior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 137,
    id_parent: 4,
    it: 'Sviluppatore Python senior',
    en: 'Senior Python Developer',
    es: 'Desarrollador senior de Python',
    de: 'Leitender Python-Entwickler',
    fr: 'Développeur Python Sénior',
    pt: 'Desenvolvedor Python Sênior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 138,
    id_parent: 4,
    it: 'Sviluppatore Python',
    en: 'Python Developer',
    es: 'Desarrollador de Python',
    de: 'Python-Entwickler',
    fr: 'Développeur Python',
    pt: 'Desenvolvedor Python',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 139,
    id_parent: 4,
    it: 'Sviluppatore Ruby senior',
    en: 'Senior Ruby Developer',
    es: 'Desarrollador Senior de Ruby',
    de: 'Senior Ruby-Entwickler',
    fr: 'Développeur Rubis Sénior',
    pt: 'Desenvolvedor Ruby Sênior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 140,
    id_parent: 4,
    it: 'Sviluppatore .NET senior',
    en: 'Senior .NET Developer',
    es: 'Desarrollador senior de .NET',
    de: 'Leitender .NET-Entwickler',
    fr: 'Développeur .NET senior',
    pt: 'Desenvolvedor .NET Sênior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 141,
    id_parent: 4,
    it: 'Sviluppatore Java senior',
    en: 'Senior Java Developer',
    es: 'Desarrollador senior de Java',
    de: 'Leitender Java-Entwickler',
    fr: 'Développeur Java Sénior',
    pt: 'Desenvolvedor Java Sênior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 142,
    id_parent: 4,
    it: 'Ingegnere software Java',
    en: 'Java Software Engineer',
    es: 'Ingeniero de software Java',
    de: 'Java-Software-Ingenieur',
    fr: 'Ingénieur logiciel Java',
    pt: 'Engenheiro de Software Java',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 143,
    id_parent: 4,
    it: 'Consulente BI',
    en: 'BI Consultant',
    es: 'Consultor BI',
    de: 'BI-Berater',
    fr: 'Consultant BI',
    pt: 'Consultor de BI',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 144,
    id_parent: 4,
    it: 'Proprietario del prodotto',
    en: 'Product Owner',
    es: 'Propietario de producto',
    de: 'Produkteigentümer',
    fr: 'Propriétaire du produit',
    pt: 'Proprietário do produto',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 145,
    id_parent: 4,
    it: 'Ingegnere DevOps',
    en: 'DevOps Engineer',
    es: 'Ingeniero DevOps',
    de: 'DevOps-Ingenieur',
    fr: 'Ingénieur DevOps',
    pt: 'Engenheiro de DevOps',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 146,
    id_parent: 4,
    it: 'Sviluppatore web senior',
    en: 'Senior Web Developer',
    es: 'Desarrollador web senior',
    de: 'Leitender Webentwickler',
    fr: 'Développeur Web Sénior',
    pt: 'Desenvolvedor Web Sênior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 147,
    id_parent: 4,
    it: 'Sviluppatore BI (Business Intelligence)',
    en: 'BI (Business Intelligence) Developer',
    es: 'Desarrollador de BI (Business Intelligence)',
    de: 'BI (Business Intelligence) Entwickler',
    fr: 'Développeur BI (Business Intelligence)',
    pt: 'Desenvolvedor de BI (Business Intelligence)',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 148,
    id_parent: 4,
    it: 'Architetto tecnico',
    en: 'Technical Architect',
    es: 'Arquitecto técnico',
    de: 'Technischer Architekt',
    fr: 'Architecte technique',
    pt: 'arquiteto técnico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 149,
    id_parent: 4,
    it: 'Ingegnere di rete senior',
    en: 'Senior Network Engineer',
    es: 'Ingeniero de redes senior',
    de: 'Leitender Netzwerkingenieur',
    fr: 'Ingénieur réseau sénior',
    pt: 'Engenheiro de Rede Sênior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 150,
    id_parent: 4,
    it: 'Ingegnere software senior',
    en: 'Senior Software Engineer',
    es: 'Ingeniero de software senior',
    de: 'Senior-Software-Entwickler',
    fr: 'Ingénieur logiciel principal',
    pt: 'Engenheiro de software senior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 151,
    id_parent: 4,
    it: 'Specialista della sicurezza informatica',
    en: 'Computer Security Specialist',
    es: 'Especialista en seguridad informática',
    de: 'Spezialist für Computersicherheit',
    fr: 'Spécialiste de la sécurité informatique',
    pt: 'Especialista em segurança informática',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 152,
    id_parent: 4,
    it: 'Sviluppatore .Net',
    en: '.Net Developer',
    es: 'Desarrollador .Net',
    de: '.Net-Entwickler',
    fr: 'Développeur .Net',
    pt: 'Desenvolvedor .Net',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 153,
    id_parent: 4,
    it: 'Amministratore di sistema',
    en: 'System Administrator',
    es: 'Administrador de sistemas',
    de: 'Systemadministrator',
    fr: 'Administrateur du système',
    pt: 'Administrador do sistema',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 154,
    id_parent: 4,
    it: 'Sviluppatore software',
    en: 'Software Developer',
    es: 'Desarrollador de software',
    de: 'Softwareentwickler',
    fr: 'Développeur de logiciels',
    pt: 'Desenvolvedor de software',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 155,
    id_parent: 4,
    it: 'Analista di dati sanitari',
    en: 'Healthcare Data Analyst',
    es: 'Analista de datos sanitarios',
    de: 'Datenanalyst im Gesundheitswesen',
    fr: 'Analyste de données de santé',
    pt: 'Analista de dados de saúde',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 156,
    id_parent: 4,
    it: 'Responsabile delle analisi',
    en: 'Analytics Manager',
    es: 'Gestor de análisis',
    de: 'Analytics-Manager',
    fr: 'Gestionnaire d\'analyse',
    pt: 'Gerente de análise',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 157,
    id_parent: 4,
    it: 'Webmaster',
    en: 'Webmaster',
    es: 'Webmaster',
    de: 'Webmaster',
    fr: 'Webmestre',
    pt: 'Webmaster',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 158,
    id_parent: 4,
    it: 'Tester di QA',
    en: 'QA Tester',
    es: 'Probador de control de calidad',
    de: 'QA-Tester',
    fr: 'Testeur AQ',
    pt: 'Testador de controle de qualidade',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 159,
    id_parent: 4,
    it: 'Scienziato dei dati',
    en: 'Data Scientist',
    es: 'Científico de datos',
    de: 'Datenwissenschaftler',
    fr: 'Scientifique des données',
    pt: 'Cientista de dados',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 160,
    id_parent: 4,
    it: 'Architetto dei dati',
    en: 'Data Architect',
    es: 'Arquitecto de datos',
    de: 'Datenarchitekt',
    fr: 'Architecte de données',
    pt: 'Arquiteto de dados',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 161,
    id_parent: 4,
    it: 'Analista IT',
    en: 'IT Analyst',
    es: 'Analista de TI',
    de: 'IT-Analyst',
    fr: 'Analyste informatique',
    pt: 'Analista de TI',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 162,
    id_parent: 4,
    it: 'CTO (Chief Technology Officer)',
    en: 'CTO (Chief Technology Officer)',
    es: 'CTO (Director de Tecnología)',
    de: 'CTO (Chief Technology Officer)',
    fr: 'CTO (Directeur de la technologie)',
    pt: 'CTO (Chief Technology Officer)',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 163,
    id_parent: 4,
    it: 'Senior Product Manager',
    en: 'Senior Product Manager',
    es: 'Gestor de productos senior',
    de: 'Führender Produkt Bereichsleiter',
    fr: 'Chef de produit sénior',
    pt: 'Gerente de Produto Sênior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 164,
    id_parent: 4,
    it: 'Analista di sistema',
    en: 'System Analyst',
    es: 'Analista de sistemas',
    de: 'Systemanalytiker',
    fr: 'Analyste de système',
    pt: 'Analista de sistemas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 165,
    id_parent: 4,
    it: 'Programmatore web',
    en: 'Web Programmer',
    es: 'Programador web',
    de: 'Webprogrammierer',
    fr: 'Développeur web',
    pt: 'Programador Web',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 166,
    id_parent: 4,
    it: 'Sviluppatore mobile',
    en: 'Mobile Developer',
    es: 'Desarrollador de móviles',
    de: 'Mobiler Entwickler',
    fr: 'Développeur mobile',
    pt: 'Desenvolvedor móvel',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 167,
    id_parent: 4,
    it: 'Sviluppatore back-end',
    en: 'Back-end Developer',
    es: 'Desarrollador de back-end',
    de: 'Backend-Entwickler',
    fr: 'Développeur back-end',
    pt: 'Desenvolvedor de back-end',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 168,
    id_parent: 4,
    it: 'Sviluppatore di database',
    en: 'Database Developer',
    es: 'Desarrollador de bases de datos',
    de: 'Datenbank-Entwickler',
    fr: 'Développeur de base de données',
    pt: 'Desenvolvedor de banco de dados',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 169,
    id_parent: 4,
    it: 'Sviluppatore front-end',
    en: 'Front-end Developer',
    es: 'Desarrollador Front-end',
    de: 'Entwickler für Benutzeroberflächen',
    fr: 'Développeur frontal',
    pt: 'Desenvolvedor Front-end',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 170,
    id_parent: 4,
    it: 'Amministratore di rete',
    en: 'Network Administrator',
    es: 'Administrador de redes',
    de: 'Netzwerkadministrator',
    fr: 'Administrateur réseau',
    pt: 'Administrador de rede',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 171,
    id_parent: 4,
    it: 'Ingegnere software incorporato',
    en: 'Embedded Software Engineer',
    es: 'Ingeniero de software embebido',
    de: 'Embedded-Software-Ingenieur',
    fr: 'Ingénieur logiciel embarqué',
    pt: 'Engenheiro de software embarcado',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 172,
    id_parent: 4,
    it: 'Architetto software',
    en: 'Software Architect',
    es: 'Arquitecto de software',
    de: 'Softwarearchitekt',
    fr: 'Architecte logiciel',
    pt: 'Arquiteto de software',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 173,
    id_parent: 4,
    it: 'Coordinatore IT',
    en: 'IT Coordinator',
    es: 'Coordinador de TI',
    de: 'IT-Koordinator',
    fr: 'Coordinateur informatique',
    pt: 'Coordenador de TI',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 174,
    id_parent: 4,
    it: 'Sviluppatore di applicazioni',
    en: 'Application Developer',
    es: 'Desarrollador de aplicaciones',
    de: 'Anwendungsentwickler',
    fr: 'Développeur d\'applications',
    pt: 'Desenvolvedor de aplicativos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 175,
    id_parent: 4,
    it: 'Manager dei dati',
    en: 'Data Manager',
    es: 'Gestor de datos',
    de: 'Datenmanager',
    fr: 'Manager de données',
    pt: 'Gerenciador de dados',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 176,
    id_parent: 4,
    it: 'Responsabile dell\'informazione - CIO',
    en: 'Chief Information Officer � CIO',
    es: 'Director de Información - CIO',
    de: 'Chief Information Officer – CIO',
    fr: 'Directeur de l\'information - CIO',
    pt: 'Diretor de Informação - CIO',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 177,
    id_parent: 4,
    it: 'Consulente IT',
    en: 'IT Consultant',
    es: 'Consultor de TI',
    de: 'IT Berater',
    fr: 'Consultant en informatique',
    pt: 'Consultor de TI',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 178,
    id_parent: 4,
    it: 'Programmatore',
    en: 'Programmer',
    es: 'Programador',
    de: 'Programmierer',
    fr: 'Programmeur',
    pt: 'Programador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 179,
    id_parent: 4,
    it: 'Direttore IT',
    en: 'IT Director',
    es: 'Director de TI',
    de: 'IT-Leiter',
    fr: 'directeur informatique',
    pt: 'Diretor de TI',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 180,
    id_parent: 4,
    it: 'Ingegnere del software',
    en: 'Software Engineer',
    es: 'Ingeniero de software',
    de: 'Softwareentwickler',
    fr: 'Ingénieur logiciel',
    pt: 'Engenheiro de software',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 181,
    id_parent: 4,
    it: 'Analista aziendale',
    en: 'Business Analyst',
    es: 'Analista de negocios',
    de: 'Business Analyst',
    fr: 'Analyste d\'affaires',
    pt: 'Analista de negócios',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 182,
    id_parent: 4,
    it: 'Amministratore di database (DBA)',
    en: 'Database Administrator (DBA)',
    es: 'Administrador de bases de datos (DBA)',
    de: 'Datenbankadministrator (DBA)',
    fr: 'Administrateur de base de données (DBA)',
    pt: 'Administrador de banco de dados (DBA)',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 183,
    id_parent: 4,
    it: 'Specialista delle telecomunicazioni',
    en: 'Telecommunications Specialist',
    es: 'Especialista en telecomunicaciones',
    de: 'Telekommunikationsspezialist',
    fr: 'Spécialiste des télécommunications',
    pt: 'Especialista em Telecomunicações',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 184,
    id_parent: 4,
    it: 'Manager IT',
    en: 'IT Manager',
    es: 'Gerente de TI',
    de: 'IT-Manager',
    fr: 'Responsable informatique',
    pt: 'Gerente de TI',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 185,
    id_parent: 4,
    it: 'Sviluppatore di giochi',
    en: 'Game Developer',
    es: 'Desarrollador de juegos',
    de: 'Spielentwickler',
    fr: 'Développeur de jeu',
    pt: 'Desenvolvedor de jogos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 186,
    id_parent: 4,
    it: 'Sviluppatore PHP',
    en: 'PHP Developer',
    es: 'Desarrollador de PHP',
    de: 'Php Entwickler',
    fr: 'Développeur PHP',
    pt: 'Desenvolvedor PHP',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 187,
    id_parent: 4,
    it: 'Tecnico informatico',
    en: 'Computer Technician',
    es: 'Técnico informático',
    de: 'Computertechniker',
    fr: 'Technicien informatique',
    pt: 'Técnico de Informática',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 188,
    id_parent: 4,
    it: 'Ingegnere della sicurezza del sistema',
    en: 'System Security Engineer',
    es: 'Ingeniero de seguridad de sistemas',
    de: 'Ingenieur für Systemsicherheit',
    fr: 'Ingénieur en sécurité système',
    pt: 'Engenheiro de segurança do sistema',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 189,
    id_parent: 4,
    it: 'Ingegnere della sicurezza del software',
    en: 'Software Security Engineer',
    es: 'Ingeniero de seguridad de software',
    de: 'Software-Sicherheitsingenieur',
    fr: 'Ingénieur en sécurité logicielle',
    pt: 'Engenheiro de segurança de software',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 190,
    id_parent: 4,
    it: 'Sviluppatore Web',
    en: 'Web Developer',
    es: 'Desarrollador Web',
    de: 'Web-Entwickler',
    fr: 'Développeur web',
    pt: 'Desenvolvedor Web',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 191,
    id_parent: 4,
    it: 'Sviluppatore Android',
    en: 'Android Developer',
    es: 'Desarrollador Android',
    de: 'Android-Entwickler',
    fr: 'Développeur Android',
    pt: 'Desenvolvedor Android',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 192,
    id_parent: 4,
    it: 'Analista di dati',
    en: 'Data Analyst',
    es: 'Analista de datos',
    de: 'Daten Analyst',
    fr: 'Analyste de données',
    pt: 'Analista de informações',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 193,
    id_parent: 4,
    it: 'Tecnico IT',
    en: 'IT Technician',
    es: 'Técnico de TI',
    de: 'IT-Techniker',
    fr: 'Technicien informatique',
    pt: 'Técnico de TI',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 194,
    id_parent: 4,
    it: 'Tecnico di rete',
    en: 'Network Technician',
    es: 'Técnico de redes',
    de: 'Netzwerktechniker',
    fr: 'Technicien réseau',
    pt: 'Técnico de rede',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 195,
    id_parent: 4,
    it: 'Ingegnere di rete',
    en: 'Network Engineer',
    es: 'Ingeniero de redes',
    de: 'Netzwerktechniker',
    fr: 'Ingénieur réseau',
    pt: 'Engenheiro de rede',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 196,
    id_parent: 4,
    it: 'Scrittore tecnico',
    en: 'Technical Writer',
    es: 'Escritor técnico',
    de: 'Technischer Schreiber',
    fr: 'Rédacteur technique',
    pt: 'Escritor técnico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 197,
    id_parent: 4,
    it: 'Sviluppatore Java',
    en: 'Java Developer',
    es: 'Desarrollador Java',
    de: 'Java Entwickler',
    fr: 'développeur Java',
    pt: 'desenvolvedor de Java',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 198,
    id_parent: 4,
    it: 'Ingegnere QA',
    en: 'QA Engineer',
    es: 'Ingeniero QA',
    de: 'Qualitätssicherungsingenieur',
    fr: 'Ingénieur AQ',
    pt: 'Engenheiro de QA',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 199,
    id_parent: 4,
    it: 'Sviluppatore Ruby on Rails',
    en: 'Ruby on Rails Developer',
    es: 'Desarrollador Ruby on Rails',
    de: 'Ruby on Rails-Entwickler',
    fr: 'Développeur Ruby on Rails',
    pt: 'Desenvolvedor Ruby on Rails',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 200,
    id_parent: 4,
    it: 'Sviluppatore iOS',
    en: 'iOS Developer',
    es: 'Desarrollador iOS',
    de: 'iOS-Entwickler',
    fr: 'Développeur iOS',
    pt: 'Desenvolvedor iOS',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 201,
    id_parent: 4,
    it: 'Responsabile di prodotto',
    en: 'Product Manager',
    es: 'Gestor de productos',
    de: 'Produktmanager',
    fr: 'Chef de produit',
    pt: 'Gerente de Produto',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 202,
    id_parent: 4,
    it: 'Ingegnere di sistemi',
    en: 'Systems Engineer',
    es: 'Ingeniero de sistemas',
    de: 'Systemingenieur',
    fr: 'Ingénieur Systèmes',
    pt: 'Engenheiro de sistemas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 203,
    id_parent: 4,
    it: 'Responsabile di progetto',
    en: 'Project Manager',
    es: 'Gestor de proyectos',
    de: 'Projektmanager',
    fr: 'Chef de projet',
    pt: 'Gestor de projeto',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 204,
    id_parent: 5,
    it: 'Designer di prodotti fisici',
    en: 'Physical Product Designer',
    es: 'Diseñador de productos físicos',
    de: 'Physischer Produktdesigner',
    fr: 'Concepteur de produits physiques',
    pt: 'Designer de Produto Físico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 205,
    id_parent: 5,
    it: 'Designer di prodotto',
    en: 'Product Designer',
    es: 'Diseñador de productos',
    de: 'Produktdesigner',
    fr: 'Concepteur de produits',
    pt: 'Designer de produto',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 206,
    id_parent: 5,
    it: 'Designer UI/UX',
    en: 'UI/UX Designer',
    es: 'Diseñador UI/UX',
    de: 'UI/UX-Designer',
    fr: 'Concepteur UI/UX',
    pt: 'Designer de UI/UX',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 207,
    id_parent: 5,
    it: 'Designer junior',
    en: 'Junior Designer',
    es: 'Diseñador junior',
    de: 'Junior-Designer',
    fr: 'Designer junior',
    pt: 'Designer Júnior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 208,
    id_parent: 5,
    it: 'Designer senior',
    en: 'Senior Designer',
    es: 'Diseñador Senior',
    de: 'Senior Designer',
    fr: 'Designer senior',
    pt: 'Designer Sênior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 209,
    id_parent: 5,
    it: 'Designer visivo',
    en: 'Visual Designer',
    es: 'Diseñador visual',
    de: 'Visueller Designer',
    fr: 'Concepteur visuel',
    pt: 'Designer Visual',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 210,
    id_parent: 5,
    it: 'Artista di produzione',
    en: 'Production Artist',
    es: 'Artista de producción',
    de: 'Produktionskünstler',
    fr: 'Artiste de production',
    pt: 'Artista de Produção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 211,
    id_parent: 5,
    it: 'Illustratore',
    en: 'Illustrator',
    es: 'Ilustrador',
    de: 'Illustrator',
    fr: 'Illustrateur',
    pt: 'Ilustrador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 212,
    id_parent: 5,
    it: 'Designer UX',
    en: 'UX Designer',
    es: 'Diseñador UX',
    de: 'UX-Designer',
    fr: 'Concepteur UX',
    pt: 'UX Designer',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 512,
    id_parent: 16,
    it: 'Recruiter',
    en: 'Recruiter',
    es: 'Reclutador',
    de: 'Personalvermittler',
    fr: 'Recruteur',
    pt: 'Recrutador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 213,
    id_parent: 5,
    it: 'Designer d\'interni',
    en: 'Interior Designer',
    es: 'Diseñador de interiores',
    de: 'Innenarchitekt',
    fr: 'Décorateur d\'intérieur',
    pt: 'Designer de interiores',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 214,
    id_parent: 5,
    it: 'Designer grafico',
    en: 'Graphic Designer',
    es: 'Diseñador gráfico',
    de: 'Grafikdesigner',
    fr: 'Designer graphique',
    pt: 'Designer gráfico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 215,
    id_parent: 5,
    it: 'UI Designer',
    en: 'UI Designer',
    es: 'Diseñador de UI',
    de: 'UI-Designer',
    fr: 'Concepteur d\'interface utilisateur',
    pt: 'Designer de IU',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 216,
    id_parent: 5,
    it: 'Web Designer',
    en: 'Web Designer',
    es: 'Diseñador web',
    de: 'Webdesigner',
    fr: 'Concepteur Web',
    pt: 'Web designer',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 217,
    id_parent: 6,
    it: 'Manager di turno',
    en: 'Duty Manager',
    es: 'Jefe de servicio',
    de: 'Duty-Manager',
    fr: 'Gestionnaire de service',
    pt: 'Gerente de plantão',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 218,
    id_parent: 6,
    it: 'Specialista dell\'assistenza clienti',
    en: 'Customer Support Specialist',
    es: 'Especialista en atención al cliente',
    de: 'Kundendienstspezialist',
    fr: 'Spécialiste du soutien à la clientèle',
    pt: 'Especialista em Suporte ao Cliente',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 219,
    id_parent: 6,
    it: 'Ingegnere di supporto desktop',
    en: 'Desktop Support Engineer',
    es: 'Ingeniero de soporte de escritorio',
    de: 'Desktop-Support-Techniker',
    fr: 'Ingénieur de support de bureau',
    pt: 'Engenheiro de suporte de desktop',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 220,
    id_parent: 6,
    it: 'Responsabile Help Desk',
    en: 'Help Desk Manager',
    es: 'Gestor del servicio de asistencia técnica',
    de: 'Helpdesk-Manager',
    fr: 'Responsable du service d\'assistance',
    pt: 'Gerente de Help Desk',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 221,
    id_parent: 6,
    it: 'Responsabile dell\'account tecnico',
    en: 'Technical Account Manager',
    es: 'Gestor técnico de cuentas',
    de: 'Technischer Account Manager',
    fr: 'Gestionnaire de compte technique',
    pt: 'Gerente Técnico de Conta',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 222,
    id_parent: 6,
    it: 'Addetto alla spedizione',
    en: 'Dispatcher',
    es: 'Despachador',
    de: 'Dispatcher',
    fr: 'Répartiteur',
    pt: 'Expedidor',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 223,
    id_parent: 6,
    it: 'Rappresentante del servizio sul campo',
    en: 'Field Service Representative',
    es: 'Representante de servicio de campo',
    de: 'Außendienstmitarbeiter',
    fr: 'Représentant du service sur le terrain',
    pt: 'Representante de serviço de campo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 224,
    id_parent: 6,
    it: 'Rappresentante del call center',
    en: 'Call Center Representative',
    es: 'Representante del centro de llamadas',
    de: 'Call-Center-Vertreter',
    fr: 'Appelez le représentant du Centre',
    pt: 'Representante da Central de Chamadas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 225,
    id_parent: 6,
    it: 'Responsabile del call center',
    en: 'Call Center Manager',
    es: 'Gestor del centro de llamadas',
    de: 'Callcenter-Manager',
    fr: 'Gestionnaire de centre d\'appels',
    pt: 'Gerente de Call Center',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 226,
    id_parent: 6,
    it: 'Tecnico del servizio sul campo',
    en: 'Field Service Technician',
    es: 'Técnico de servicio de campo',
    de: 'Außendiensttechniker',
    fr: 'Technicien de service sur le terrain',
    pt: 'Técnico de Serviço de Campo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 227,
    id_parent: 6,
    it: 'Tecnico IT Help Desk',
    en: 'IT Help Desk Technician',
    es: 'Técnico del servicio de asistencia informática',
    de: 'IT-Helpdesk-Techniker',
    fr: 'Technicien d\'assistance informatique',
    pt: 'Técnico de Help Desk de TI',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 228,
    id_parent: 6,
    it: 'Specialista di Help Desk',
    en: 'Help Desk Specialist',
    es: 'Especialista del servicio de asistencia técnica',
    de: 'Helpdesk-Spezialist',
    fr: 'Spécialiste du service d\'assistance',
    pt: 'Especialista em Help Desk',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 229,
    id_parent: 6,
    it: 'Supervisore del call center',
    en: 'Call Center Supervisor',
    es: 'Supervisor de centro de llamadas',
    de: 'Callcenter-Betreuer',
    fr: 'Superviseur centre d\'appels',
    pt: 'Supervisora de Call Center',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 230,
    id_parent: 6,
    it: 'Responsabile del servizio clienti',
    en: 'Customer Service Manager',
    es: 'Gestor del servicio de atención al cliente',
    de: 'Kundendienstbetreuer',
    fr: 'Gestionnaire de service à la clientèle',
    pt: 'Gerente de Serviços ao Cliente',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 231,
    id_parent: 6,
    it: 'Addetto al recupero crediti',
    en: 'Debt Collector',
    es: 'Recaudador de deudas',
    de: 'Schuldeneintreiber',
    fr: 'Agent de recouvrement',
    pt: 'Cobrador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 232,
    id_parent: 6,
    it: 'Rappresentante della reception',
    en: 'Front Desk Representative',
    es: 'Representante de recepción',
    de: 'Vertreter der Rezeption',
    fr: 'Représentant de la réception',
    pt: 'Representante da Recepção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 233,
    id_parent: 6,
    it: 'Rappresentante del servizio clienti',
    en: 'Customer Service Representative',
    es: 'Representante del servicio de atención al cliente',
    de: 'Kundendienstvertretung',
    fr: 'Représentant du service à la clientèle',
    pt: 'Representante de atendimento ao cliente',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 234,
    id_parent: 6,
    it: 'Receptionist',
    en: 'Receptionist',
    es: 'Recepcionista',
    de: 'Rezeptionist',
    fr: 'Réceptionniste',
    pt: 'Recepcionista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 235,
    id_parent: 6,
    it: 'Cassiere di banca',
    en: 'Bank Teller',
    es: 'Cajero de banco',
    de: 'Bankangestellter',
    fr: 'Caissier de banque',
    pt: 'Caixa de banco',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 236,
    id_parent: 6,
    it: 'Ingegnere di supporto tecnico',
    en: 'Technical Support Engineer',
    es: 'Ingeniero de soporte técnico',
    de: 'Technischer Support-Ingenieur',
    fr: 'Ingénieur du Support Technique',
    pt: 'Engenheiro de Suporte Técnico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 237,
    id_parent: 7,
    it: 'Preside',
    en: 'Principal',
    es: 'Director',
    de: 'Rektor',
    fr: 'Principal',
    pt: 'Diretor',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 238,
    id_parent: 7,
    it: 'Assistente sociale della scuola',
    en: 'School Social Worker',
    es: 'Trabajador social de la escuela',
    de: 'Schulsozialarbeiter',
    fr: 'Travailleur social scolaire',
    pt: 'Assistente Social Escolar',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 239,
    id_parent: 7,
    it: 'Insegnante ESL',
    en: 'ESL teacher',
    es: 'Profesor de ESL',
    de: 'ESL-Lehrer',
    fr: 'professeur d\'anglais langue seconde',
    pt: 'professor de ESL',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 240,
    id_parent: 7,
    it: 'Infermiera scolastica',
    en: 'School Nurse',
    es: 'Enfermera escolar',
    de: 'Schulkrankenschwester',
    fr: 'Infirmière scolaire',
    pt: 'Enfermeira escolar',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 241,
    id_parent: 7,
    it: 'Psicologo scolastico',
    en: 'School Psychologist',
    es: 'Psicólogo escolar',
    de: 'Schulpsychologe',
    fr: 'Psychologue scolaire',
    pt: 'Psicóloga Escolar',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 242,
    id_parent: 7,
    it: 'Direttore Atletico',
    en: 'Athletic Director',
    es: 'Director de Deportes',
    de: 'Sportlicher Leiter',
    fr: 'Directeur sportif',
    pt: 'Diretor Atlético',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 243,
    id_parent: 7,
    it: 'Bibliotecario',
    en: 'Librarian',
    es: 'Bibliotecario',
    de: 'Bibliothekar',
    fr: 'Bibliothécaire',
    pt: 'Bibliotecário',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 244,
    id_parent: 7,
    it: 'Amministratore scolastico',
    en: 'School Administrator',
    es: 'Administrador escolar',
    de: 'Schulverwalter',
    fr: 'Administrateur scolaire',
    pt: 'Administrador da escola',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 245,
    id_parent: 7,
    it: 'Assistente insegnante',
    en: 'Teacher Assistant',
    es: 'Asistente de profesor',
    de: 'Lehrer-Assistent',
    fr: 'Assistant enseignant',
    pt: 'Assistente de professor',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 246,
    id_parent: 7,
    it: 'Insegnante supplente',
    en: 'Substitute Teacher',
    es: 'Profesor sustituto',
    de: 'Vertretungslehrer',
    fr: 'Enseignant suppléant',
    pt: 'Professor substituto',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 247,
    id_parent: 7,
    it: 'Insegnante di inglese',
    en: 'English Teacher',
    es: 'Profesor de inglés',
    de: 'Englischlehrer',
    fr: 'Professeur d\'anglais',
    pt: 'Professor de inglês',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 248,
    id_parent: 7,
    it: 'Insegnante di educazione speciale',
    en: 'Special Education Teacher',
    es: 'Profesor de educación especial',
    de: 'Sonderschullehrerin',
    fr: 'Professeur d\'éducation spéciale',
    pt: 'Professor de educação especial',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 249,
    id_parent: 7,
    it: 'Consigliere di campo',
    en: 'Camp Counselor',
    es: 'Consejero de campamento',
    de: 'Lagerberater',
    fr: 'Conseiller de camp',
    pt: 'Conselheiro do acampamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 250,
    id_parent: 7,
    it: 'Tata',
    en: 'Nanny',
    es: 'Niñera',
    de: 'Tagesmutter',
    fr: 'Nounou',
    pt: 'Babá',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 251,
    id_parent: 7,
    it: 'Consulente di orientamento',
    en: 'Guidance Counselor',
    es: 'Consejero de orientación',
    de: 'Vertrauenslehrer',
    fr: 'Conseiller d\'orientation',
    pt: 'Conselheiro de Orientação',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 252,
    id_parent: 7,
    it: 'Insegnante di scuola materna',
    en: 'Preschool Teacher',
    es: 'Profesor de preescolar',
    de: 'Vorschullehrer',
    fr: 'Enseignant préscolaire',
    pt: 'Professor de pré-escola',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 253,
    id_parent: 7,
    it: 'Insegnante per la cura dei bambini',
    en: 'Child Care Teacher',
    es: 'Profesor de guardería',
    de: 'Lehrerin für Kinderbetreuung',
    fr: 'Professeur de garde d\'enfants',
    pt: 'Professora de cuidados infantis',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 254,
    id_parent: 7,
    it: 'Insegnante',
    en: 'Teacher',
    es: 'Profesor',
    de: 'Lehrer',
    fr: 'Prof',
    pt: 'Professora',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 255,
    id_parent: 7,
    it: 'Progettista didattico',
    en: 'Instructional Designer',
    es: 'Diseñador de instrucción',
    de: 'Lehr-Designer',
    fr: 'Concepteur pédagogique',
    pt: 'Designer instrucional',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 256,
    id_parent: 7,
    it: 'Consulente scolastico',
    en: 'School Counselor',
    es: 'Consejero escolar',
    de: 'Schulberater',
    fr: 'Conseiller scolaire',
    pt: 'Conselheiro escolar',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 257,
    id_parent: 7,
    it: 'Insegnante d\'asilo',
    en: 'Kindergarten Teacher',
    es: 'Profesor de jardín de infancia',
    de: 'Vorschullehrer',
    fr: 'Enseignante de maternelle',
    pt: 'Professor de jardim de infância',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 258,
    id_parent: 8,
    it: 'Formazione aziendale',
    en: 'Corporate training',
    es: 'Formación para empresas',
    de: 'Unternehmenstraining',
    fr: 'Formation en entreprise',
    pt: 'Treinamento Corporativo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 259,
    id_parent: 8,
    it: 'Direttore della formazione',
    en: 'Training Director',
    es: 'Director de formación',
    de: 'Ausbildungsleiter',
    fr: 'Directeur de la formation',
    pt: 'Diretor de Treinamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 260,
    id_parent: 8,
    it: 'Responsabile della formazione tecnica',
    en: 'Technical Training Manager',
    es: 'Director de formación técnica',
    de: 'Technischer Ausbildungsleiter',
    fr: 'Responsable Formation Technique',
    pt: 'Gerente de Treinamento Técnico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 261,
    id_parent: 8,
    it: 'Formatore al dettaglio',
    en: 'Retail Trainer',
    es: 'Formador de minoristas',
    de: 'Einzelhandelstrainer',
    fr: 'Formateur au détail',
    pt: 'Instrutor de varejo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 262,
    id_parent: 8,
    it: 'Allenatore delle vendite',
    en: 'Sales Coach',
    es: 'Entrenador de ventas',
    de: 'Verkaufscoach',
    fr: 'Coach de vente',
    pt: 'Coach de vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 263,
    id_parent: 8,
    it: 'Progettista di curriculum',
    en: 'Curriculum Designer',
    es: 'Diseñador de planes de estudio',
    de: 'Curriculum-Designer',
    fr: 'Concepteur de programmes d\'études',
    pt: 'Desenhista de Currículo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 264,
    id_parent: 8,
    it: 'Formatore del servizio clienti',
    en: 'Customer Service Trainer',
    es: 'Formador de servicio al cliente',
    de: 'Kundenservice-Trainer',
    fr: 'Formateur service client',
    pt: 'Instrutor de Atendimento ao Cliente',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 265,
    id_parent: 8,
    it: 'Facilitatore della formazione',
    en: 'Training Facilitator',
    es: 'Facilitador de formación',
    de: 'Schulungsleiter',
    fr: 'Facilitateur de formation',
    pt: 'Facilitador de treinamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 266,
    id_parent: 8,
    it: 'Consulente per la formazione',
    en: 'Training Consultant',
    es: 'Consultor de formación',
    de: 'Ausbildungsberater',
    fr: 'Conseiller en formation',
    pt: 'Consultor de treinamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 267,
    id_parent: 8,
    it: 'Formatore IT',
    en: 'IT Trainer',
    es: 'Formador de TI',
    de: 'IT-Trainer',
    fr: 'Formateur informatique',
    pt: 'Instrutor de TI',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 268,
    id_parent: 8,
    it: 'Formatore delle vendite',
    en: 'Sales Trainer',
    es: 'Formador de ventas',
    de: 'Verkaufstrainer',
    fr: 'Formateur en vente',
    pt: 'Instrutor de vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 269,
    id_parent: 8,
    it: 'Amministratore della formazione',
    en: 'Training Administrator',
    es: 'Administrador de formación',
    de: 'Schulungsadministrator',
    fr: 'Administrateur de formation',
    pt: 'Administrador de treinamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 270,
    id_parent: 8,
    it: 'Manager di apprendimento e sviluppo (L&D)',
    en: 'Learning and Development (L&D) Manager',
    es: 'Director de aprendizaje y desarrollo (L&D)',
    de: 'Manager für Lernen und Entwicklung (L&D).',
    fr: 'Responsable Apprentissage et Développement (L&D)',
    pt: 'Gerente de Aprendizagem e Desenvolvimento (T&D)',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 271,
    id_parent: 8,
    it: 'Formatore tecnico',
    en: 'Technical Trainer',
    es: 'Formador técnico',
    de: 'Technischer Trainer',
    fr: 'Formateur technique',
    pt: 'Instrutor Técnico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 272,
    id_parent: 8,
    it: 'Formatore aziendale',
    en: 'Corporate Trainer',
    es: 'Formador de empresa',
    de: 'Unternehmenstrainer',
    fr: 'Formateur en entreprise',
    pt: 'Instrutor Corporativo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 273,
    id_parent: 8,
    it: 'Specialista della formazione',
    en: 'Training Specialist',
    es: 'Especialista en formación',
    de: 'Trainingsspezialist',
    fr: 'Spécialiste de la formation',
    pt: 'Especialista em treinamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 274,
    id_parent: 8,
    it: 'Coordinatore della formazione',
    en: 'Training Coordinator',
    es: 'Coordinador de formación',
    de: 'Übungskoordinator',
    fr: 'Coordonnateur de la formation',
    pt: 'coordenador de treino',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 275,
    id_parent: 8,
    it: 'Manager della formazione',
    en: 'Training Manager',
    es: 'Gestor de formación',
    de: 'Trainer',
    fr: 'Responsable de la formation',
    pt: 'Gerente de treinamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 276,
    id_parent: 9,
    it: 'Ingegnere petrolifero',
    en: 'Petroleum Engineer',
    es: 'Ingeniero petrolero',
    de: 'Erdölingenieur',
    fr: 'Ingénieur pétrolier',
    pt: 'Engenheiro petrolífero',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 277,
    id_parent: 9,
    it: 'Meccanico di aerei',
    en: 'Aircraft Mechanic',
    es: 'Mecánico de aeronaves',
    de: 'Flugzeugmechaniker',
    fr: 'Mécanicien d\'aéronefs',
    pt: 'Mecânico de Aeronaves',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 278,
    id_parent: 9,
    it: 'Ingegnere di convalida',
    en: 'Validation Engineer',
    es: 'Ingeniero de validación',
    de: 'Validierungsingenieur',
    fr: 'Ingénieur validation',
    pt: 'Engenheiro de validação',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 279,
    id_parent: 9,
    it: 'Ingegnere strutturale',
    en: 'Structural Engineer',
    es: 'Ingeniero de estructuras',
    de: 'Statiker',
    fr: 'Ingénieur en structure',
    pt: 'Engenheiro estrutural',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 280,
    id_parent: 9,
    it: 'Ingegnere nucleare',
    en: 'Nuclear engineer',
    es: 'Ingeniero nuclear',
    de: 'Nuklearingenieur',
    fr: 'Ingénieur nucléaire',
    pt: 'Engenheiro nuclear',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 281,
    id_parent: 9,
    it: 'Ingegnere di campo',
    en: 'Field engineer',
    es: 'Ingeniero de campo',
    de: 'Feldingenieur',
    fr: 'Ingénieur de chantier',
    pt: 'Engenheiro de campo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 282,
    id_parent: 9,
    it: 'Ingegnere della robotica',
    en: 'Robotics engineer',
    es: 'Ingeniero de robótica',
    de: 'Robotik-Ingenieur',
    fr: 'Ingénieur robotique',
    pt: 'Engenheiro de robótica',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 283,
    id_parent: 9,
    it: 'Geometra',
    en: 'Surveyor',
    es: 'Topógrafo',
    de: 'Landvermesser',
    fr: 'Arpenteur',
    pt: 'Agrimensor',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 284,
    id_parent: 9,
    it: 'Ingegnere dei dati',
    en: 'Data Engineer',
    es: 'Ingeniero de datos',
    de: 'Dateningenieur',
    fr: 'Ingénieur de données',
    pt: 'Engenheiro de dados',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 285,
    id_parent: 9,
    it: 'Ingegnere di produzione',
    en: 'Manufacturing Engineer',
    es: 'Ingeniero de fabricación',
    de: 'Fertigungsingenieur',
    fr: 'Ingénieur de fabrication',
    pt: 'Engenheiro de Manufatura',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 286,
    id_parent: 9,
    it: 'Ingegnere di progettazione',
    en: 'Design Engineer',
    es: 'Ingeniero de diseño',
    de: 'Entwicklungsingenieur',
    fr: 'Ingénieur de conception',
    pt: 'Engenheiro de projeto',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 287,
    id_parent: 9,
    it: 'Ingegnere elettrico',
    en: 'Electrical Engineer',
    es: 'Ingeniero Eléctrico',
    de: 'Elektroingenieur',
    fr: 'Ingénieur éléctricien',
    pt: 'Engenheiro elétrico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 288,
    id_parent: 9,
    it: 'Disegnatore',
    en: 'Drafter',
    es: 'Delineante',
    de: 'Zeichner',
    fr: 'Dessinateur',
    pt: 'Desenhista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 289,
    id_parent: 9,
    it: 'Ingegnere di processo',
    en: 'Process Engineer',
    es: 'Ingeniero de procesos',
    de: 'Verfahrenstechniker',
    fr: 'Ingénieur de procédé',
    pt: 'Engenheiro de Processo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 290,
    id_parent: 9,
    it: 'Meccanico auto',
    en: 'Auto Mechanic',
    es: 'Mecánico de Automóviles',
    de: 'Automechaniker',
    fr: 'Mécanicien automobile',
    pt: 'Auto-Mecânica',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 291,
    id_parent: 9,
    it: 'Ingegnere meccanico',
    en: 'Mechanical Engineer',
    es: 'Ingeniero Mecánico',
    de: 'Maschinenbauingenieur',
    fr: 'Ingénieur mécanique',
    pt: 'Engenheiro mecânico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 292,
    id_parent: 9,
    it: 'Ingegnere ambientale',
    en: 'Environmental Engineer',
    es: 'Ingeniero Ambiental',
    de: 'Umweltingenieur',
    fr: 'Ingénieur environnemental',
    pt: 'Engenheiro ambiental',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 293,
    id_parent: 9,
    it: 'Ingegnere di progetto',
    en: 'Project Engineer',
    es: 'Ingeniero de Proyectos',
    de: 'Projektingenieur',
    fr: 'Ingénieur de projet',
    pt: 'Engenheiro de Projetos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 294,
    id_parent: 9,
    it: 'Ingegnere civile',
    en: 'Civil Engineer',
    es: 'Ingeniero Civil',
    de: 'Hoch-und Tiefbauingenieur',
    fr: 'Ingénieur civil',
    pt: 'Engenheiro civil',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 295,
    id_parent: 10,
    it: 'Operatore di gru',
    en: 'Crane Operator',
    es: 'Operador de grúa',
    de: 'Kranführer',
    fr: 'Grutier',
    pt: 'Operador de guindaste',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 296,
    id_parent: 10,
    it: 'Idrologo',
    en: 'Hydrologist',
    es: 'Hidrólogo',
    de: 'Hydrologe',
    fr: 'Hydrologue',
    pt: 'Hidrologista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 297,
    id_parent: 10,
    it: 'Montatore di tubi',
    en: 'Pipe fitter',
    es: 'Instalador de tuberías',
    de: 'Installateur',
    fr: 'Tuyauteur',
    pt: 'Montador de tubos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 298,
    id_parent: 10,
    it: 'Conciatetti',
    en: 'Roofer',
    es: 'Techador',
    de: 'Dachdecker',
    fr: 'Couvreur',
    pt: 'Telhador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 299,
    id_parent: 10,
    it: 'Tecnico elettronico',
    en: 'Electronic technician',
    es: 'Técnico electrónico',
    de: 'Elektroniker',
    fr: 'Technicien électronique',
    pt: 'Técnico em eletrônica',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 300,
    id_parent: 10,
    it: 'Sovrintendente alle costruzioni',
    en: 'Construction Superintendent',
    es: 'Superintendente de la construcción',
    de: 'Bauleiter',
    fr: 'Surintendant de construction',
    pt: 'Superintendente de Construção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 301,
    id_parent: 10,
    it: 'Geologo',
    en: 'Geologist',
    es: 'Geólogo',
    de: 'Geologe',
    fr: 'Géologue',
    pt: 'Geólogo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 302,
    id_parent: 10,
    it: 'Ingegnere di prova',
    en: 'Test Engineer',
    es: 'Ingeniero de pruebas',
    de: 'Testingenieur',
    fr: 'Ingénieur d\'essais',
    pt: 'Engenheiro de testes',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 303,
    id_parent: 10,
    it: 'Ingegnere della qualità',
    en: 'Quality Engineer',
    es: 'Ingeniero de calidad',
    de: 'Qualitätsingenieur',
    fr: 'Ingénieur Qualité',
    pt: 'Engenheiro de Qualidade',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 304,
    id_parent: 10,
    it: 'Operaio generale',
    en: 'General Laborer',
    es: 'Obrero general',
    de: 'General Arbeiter',
    fr: 'Ouvrier général',
    pt: 'Operário Geral',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 305,
    id_parent: 10,
    it: 'Millwright',
    en: 'Millwright',
    es: 'Millwright',
    de: 'Mühlenbauer',
    fr: 'Mécanicien de chantier',
    pt: 'Montador de máquinas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 306,
    id_parent: 10,
    it: 'Responsabile della costruzione',
    en: 'Construction Manager',
    es: 'Director de la construcción',
    de: 'Bauleiter',
    fr: 'Directeur de la construction',
    pt: 'Gerente de construção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 307,
    id_parent: 10,
    it: 'Architetto',
    en: 'Architect',
    es: 'Arquitecto',
    de: 'Architekt',
    fr: 'Architecte',
    pt: 'Arquiteto',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 308,
    id_parent: 10,
    it: 'Estimatore',
    en: 'Estimator',
    es: 'Estimador',
    de: 'Schätzer',
    fr: 'Estimateur',
    pt: 'Estimador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 309,
    id_parent: 10,
    it: 'Esploratore edile',
    en: 'Construction Expeditor',
    es: 'Expedidor de la construcción',
    de: 'Baubegleiter',
    fr: 'Expéditeur de construction',
    pt: 'Expedidor de construção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 310,
    id_parent: 10,
    it: 'Operaio edile',
    en: 'Construction Worker',
    es: 'Obrero de la construcción',
    de: 'Bauarbeiter',
    fr: 'Ouvrier du batiment',
    pt: 'Trabalhador da construção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 311,
    id_parent: 10,
    it: 'Caposquadra edile',
    en: 'Construction Foreman',
    es: 'Capataz de construcción',
    de: 'Baumeister',
    fr: 'Contremaître de chantier',
    pt: 'Encarregado de construção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 312,
    id_parent: 10,
    it: 'Responsabile del progetto di costruzione',
    en: 'Construction Project Manager',
    es: 'Jefe de proyecto de construcción',
    de: 'Bauprojektleiter',
    fr: 'Chef de projet de construction',
    pt: 'Gerente de Projetos de Construção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 313,
    id_parent: 10,
    it: 'Estimatore edile',
    en: 'Construction Estimator',
    es: 'Estimador de la construcción',
    de: 'Baukalkulator',
    fr: 'Estimateur en construction',
    pt: 'Estimador de construção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 314,
    id_parent: 10,
    it: 'Saldatore',
    en: 'Welder',
    es: 'Soldador',
    de: 'Schweißer',
    fr: 'Soudeur',
    pt: 'Soldador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 315,
    id_parent: 10,
    it: 'Idraulico',
    en: 'Plumber',
    es: 'Fontanero',
    de: 'Klempner',
    fr: 'Plombier',
    pt: 'Encanador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 316,
    id_parent: 10,
    it: 'Imbianchino',
    en: 'Painter',
    es: 'Pintor',
    de: 'Maler',
    fr: 'Peintre',
    pt: 'Pintor',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 317,
    id_parent: 10,
    it: 'Elettricista',
    en: 'Electrician',
    es: 'Electricista',
    de: 'Elektriker',
    fr: 'Électricien',
    pt: 'Eletricista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 318,
    id_parent: 10,
    it: 'Falegname',
    en: 'Carpenter',
    es: 'Carpintero',
    de: 'Tischler',
    fr: 'Charpentier',
    pt: 'Carpinteiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 319,
    id_parent: 11,
    it: 'Meccanico di manutenzione',
    en: 'Maintenance mechanic',
    es: 'Mecánico de mantenimiento',
    de: 'Wartungsmechaniker',
    fr: 'Mécanicien d\'entretien',
    pt: 'Mecânico de manutenção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 320,
    id_parent: 11,
    it: 'Operaio di produzione',
    en: 'Production Worker',
    es: 'Trabajador de producción',
    de: 'Produktionsarbeiter',
    fr: 'Ouvrier de production',
    pt: 'Trabalhador de Produção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 321,
    id_parent: 11,
    it: 'Maestro coltivatore',
    en: 'Master grower',
    es: 'Cultivador principal',
    de: 'Meisterzüchter',
    fr: 'Maître cultivateur',
    pt: 'Mestre cultivador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 322,
    id_parent: 11,
    it: 'Responsabile della qualità',
    en: 'Quality Manager',
    es: 'Responsable de calidad',
    de: 'Qualitätsmanager',
    fr: 'Responsable qualité',
    pt: 'Gerente de qualidade',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 323,
    id_parent: 11,
    it: 'Ispettore della qualità',
    en: 'Quality Inspector',
    es: 'Inspector de calidad',
    de: 'Qualitätsprüfer',
    fr: 'Inspecteur de la qualité',
    pt: 'Inspetor de qualidade',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 324,
    id_parent: 11,
    it: 'Manager della qualità',
    en: 'Manager of Quality Assurance',
    es: 'Responsable de calidad',
    de: 'Leiter der Qualitätssicherung',
    fr: 'Responsable Assurance Qualité',
    pt: 'Gerente de Garantia de Qualidade',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 325,
    id_parent: 11,
    it: 'Meccanico',
    en: 'Mechanic',
    es: 'Mecánico',
    de: 'Mechaniker',
    fr: 'Mécanicien',
    pt: 'Mecânico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 326,
    id_parent: 11,
    it: 'Macchinista',
    en: 'Machinist',
    es: 'Maquinista',
    de: 'Maschinist',
    fr: 'Machinist',
    pt: 'Maquinista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 327,
    id_parent: 11,
    it: 'Operatore di macchina',
    en: 'Machine Operator',
    es: 'Operador de máquinas',
    de: 'Maschinenbediener',
    fr: 'Opérateur',
    pt: 'Operador de máquinas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 328,
    id_parent: 11,
    it: 'Pianificatore di produzione',
    en: 'Production Planner',
    es: 'Planificador de la producción',
    de: 'Produktionsplaner',
    fr: 'Planificateur de production',
    pt: 'Planejador de produção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 329,
    id_parent: 11,
    it: 'Direttore di produzione',
    en: 'Production Manager',
    es: 'Director de producción',
    de: 'Produktionsleiter',
    fr: 'Directeur de production',
    pt: 'Gerente de Produção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 330,
    id_parent: 11,
    it: 'Supervisore di produzione',
    en: 'Production Supervisor',
    es: 'Supervisor de producción',
    de: 'Produktionsleiter',
    fr: 'Superviseur de production',
    pt: 'supervisor de produção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 331,
    id_parent: 11,
    it: 'Assemblatore',
    en: 'Assembler',
    es: 'Ensamblador',
    de: 'Monteur',
    fr: 'Assembleur',
    pt: 'Montador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 332,
    id_parent: 12,
    it: 'Operatore CNC',
    en: 'CNC Operator',
    es: 'Operador de CNC',
    de: 'CNC-Bediener',
    fr: 'Opérateur CNC',
    pt: 'Operador CNC',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 333,
    id_parent: 12,
    it: 'Dermatologo',
    en: 'Dermatologist',
    es: 'Dermatólogo',
    de: 'Dermatologe',
    fr: 'Dermatologue',
    pt: 'Dermatologista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 334,
    id_parent: 12,
    it: 'Assistente di terapia occupazionale',
    en: 'Occupational Therapist Assistant',
    es: 'Auxiliar de Terapia Ocupacional',
    de: 'Assistentin für Ergotherapie',
    fr: 'Assistante Ergothérapeute',
    pt: 'Assistente de terapeuta ocupacional',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 335,
    id_parent: 12,
    it: 'Formatore personale',
    en: 'Personal Trainer',
    es: 'Entrenador personal',
    de: 'Persönlicher Trainer',
    fr: 'Entraîneur personnel',
    pt: 'Treinador pessoal',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 336,
    id_parent: 12,
    it: 'Dentista',
    en: 'Dentist',
    es: 'Dentista',
    de: 'Zahnarzt',
    fr: 'Dentiste',
    pt: 'Dentista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 337,
    id_parent: 12,
    it: 'Allenatore di fitness',
    en: 'Fitness Trainer',
    es: 'Entrenador de fitness',
    de: 'Fitnesstrainer',
    fr: 'Entraîneur',
    pt: 'Instrutor de fitness',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 338,
    id_parent: 12,
    it: 'Assistente veterinario',
    en: 'Veterinary Assistant',
    es: 'Asistente veterinario',
    de: 'Veterinärassistent',
    fr: 'Assistant vétérinaire',
    pt: 'Assistente veterinário',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 339,
    id_parent: 12,
    it: 'Psichiatra',
    en: 'Psychiatrist',
    es: 'Psiquiatra',
    de: 'Psychiater',
    fr: 'Psychiatre',
    pt: 'Psiquiatra',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 340,
    id_parent: 12,
    it: 'Igienista dentale',
    en: 'Dental Hygienist',
    es: 'Higienista dental',
    de: 'Zahnhygieniker',
    fr: 'Hygiéniste dentaire',
    pt: 'Higienista dental',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 341,
    id_parent: 12,
    it: 'Veterinario',
    en: 'Veterinarian',
    es: 'Veterinario',
    de: 'Tierarzt',
    fr: 'Vétérinaire',
    pt: 'Veterinário',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 342,
    id_parent: 12,
    it: 'Optometrista',
    en: 'Optometrist',
    es: 'Optometrista',
    de: 'Optiker',
    fr: 'Optométriste',
    pt: 'Optometrista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 343,
    id_parent: 12,
    it: 'Flebotomista',
    en: 'Phlebotomist',
    es: 'Flebotomista',
    de: 'Phlebotomist',
    fr: 'Phlébotomiste',
    pt: 'Flebotomista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 344,
    id_parent: 12,
    it: 'Podologo',
    en: 'Podiatrist',
    es: 'Podólogo',
    de: 'Podologe',
    fr: 'Podologue',
    pt: 'Podólogo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 345,
    id_parent: 12,
    it: 'Stilista di capelli',
    en: 'Hair Stylist',
    es: 'Estilista',
    de: 'Friseur',
    fr: 'Coiffeur',
    pt: 'Estilista de cabelo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 346,
    id_parent: 12,
    it: 'Specialista di bellezza',
    en: 'Beauty Specialist',
    es: 'Especialista en belleza',
    de: 'Schönheitsspezialist',
    fr: 'Spécialiste de la beauté',
    pt: 'Especialista em beleza',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 347,
    id_parent: 12,
    it: 'Responsabile del salone di bellezza',
    en: 'Beauty Salon Manager',
    es: 'Director de salón de belleza',
    de: 'Schönheitssalon-Manager',
    fr: 'Responsable institut de beauté',
    pt: 'Gerente de salão de beleza',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 348,
    id_parent: 12,
    it: 'Assistente direttore di salone di parrucchiere',
    en: 'Hair Salon Assistant Manager',
    es: 'Asistente de director de salón de belleza',
    de: 'Assistentin des Friseursalons',
    fr: 'Directrice adjointe de salon de coiffure',
    pt: 'Gerente auxiliar de cabeleireiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 349,
    id_parent: 12,
    it: 'Receptionist del salone',
    en: 'Salon Receptionist',
    es: 'Recepcionista de salón',
    de: 'Empfangsdame im Salon',
    fr: 'Réceptionniste de salon',
    pt: 'Recepcionista de salão',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 350,
    id_parent: 12,
    it: 'Tecnico delle unghie',
    en: 'Nail Technician',
    es: 'Técnico de uñas',
    de: 'Nagel-Techniker',
    fr: 'Technicien de l\'ongle',
    pt: 'Manicure',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 351,
    id_parent: 12,
    it: 'Estetista',
    en: 'Beautician',
    es: 'Esteticista',
    de: 'Kosmetikerin',
    fr: 'Esthéticien',
    pt: 'Esteticista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 352,
    id_parent: 12,
    it: 'Terapista di bellezza',
    en: 'Beauty Therapist',
    es: 'Terapeuta de belleza',
    de: 'Kosmetikerin',
    fr: 'Esthéticienne',
    pt: 'Terapeuta de beleza',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 353,
    id_parent: 12,
    it: 'Responsabile dell\'amministrazione dell\'ospedale',
    en: 'Hospital Administration Manager',
    es: 'Director de administración de hospital',
    de: 'Leiter der Krankenhausverwaltung',
    fr: 'Responsable Administration Hospitalière',
    pt: 'Gerente Administrativo Hospitalar',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 354,
    id_parent: 12,
    it: 'Segretaria medica',
    en: 'Medical Secretary',
    es: 'Secretaria médica',
    de: 'Arzthelferin',
    fr: 'Secrétaire médicale',
    pt: 'Secretária médica',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 355,
    id_parent: 12,
    it: 'Amministratore sanitario',
    en: 'Healthcare Administrator',
    es: 'Administrador sanitario',
    de: 'Verwalter des Gesundheitswesens',
    fr: 'Administrateur de la santé',
    pt: 'Administrador de assistência médica',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 356,
    id_parent: 12,
    it: 'Responsabile dell\'ufficio medico',
    en: 'Medical Office Manager',
    es: 'Director de oficina médica',
    de: 'Leiter der Arztpraxis',
    fr: 'Responsable cabinet médical',
    pt: 'Gerente de consultório médico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 357,
    id_parent: 12,
    it: 'Supervisore infermieristico',
    en: 'Nursing Supervisor',
    es: 'Supervisor de enfermería',
    de: 'Pflegedienstleiter',
    fr: 'Superviseur des soins infirmiers',
    pt: 'Supervisora de Enfermagem',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 358,
    id_parent: 12,
    it: 'Assistente amministrativo medico',
    en: 'Medical Administrative Assistant',
    es: 'Asistente administrativo médico',
    de: 'Medizinische Verwaltungsassistentin',
    fr: 'Adjoint administratif médical',
    pt: 'Auxiliar Administrativo Médico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 359,
    id_parent: 12,
    it: 'Assistente medico',
    en: 'Physician Assistant',
    es: 'Asistente médico',
    de: 'Arzthelferin',
    fr: 'Médecin assistant',
    pt: 'Médico Assistente',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 360,
    id_parent: 12,
    it: 'Assistente di cura personale',
    en: 'Personal Care Assistant',
    es: 'Asistente de cuidados personales',
    de: 'Persönliche Pflegeassistentin',
    fr: 'Assistant de soins personnels',
    pt: 'Assistente de cuidados pessoais',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 361,
    id_parent: 12,
    it: 'Badante',
    en: 'Caregiver',
    es: 'Cuidador',
    de: 'Betreuer',
    fr: 'Soignant',
    pt: 'Cuidador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 362,
    id_parent: 12,
    it: 'Pediatra',
    en: 'Pediatrician',
    es: 'Pediatra',
    de: 'Kinderarzt',
    fr: 'Pédiatre',
    pt: 'Pediatra',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 363,
    id_parent: 12,
    it: 'Medico',
    en: 'Physician',
    es: 'Médico',
    de: 'Arzt',
    fr: 'Médecin',
    pt: 'Médico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 364,
    id_parent: 12,
    it: 'Assistente sociale',
    en: 'Social Worker',
    es: 'Trabajador social',
    de: 'Sozialarbeiter',
    fr: 'Travailleur social',
    pt: 'Assistente social',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 365,
    id_parent: 12,
    it: 'Cosmetologo',
    en: 'Cosmetologist',
    es: 'Cosmetóloga',
    de: 'Kosmetikerin',
    fr: 'Esthéticienne',
    pt: 'Cosmetologista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 366,
    id_parent: 12,
    it: 'Paramedico',
    en: 'Paramedic',
    es: 'Paramédico',
    de: 'Sanitäter',
    fr: 'Paramédical',
    pt: 'Paramédico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 367,
    id_parent: 12,
    it: 'Assistente alla dieta',
    en: 'Dietary Aide',
    es: 'Ayudante dietético',
    de: 'Ernährungsberater',
    fr: 'Aide diététique',
    pt: 'Auxiliar de Dieta',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 368,
    id_parent: 12,
    it: 'Nutrizionista',
    en: 'Nutritionist',
    es: 'Nutricionista',
    de: 'Ernährungsberater',
    fr: 'Nutritionniste',
    pt: 'Nutricionista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 369,
    id_parent: 12,
    it: 'Assistente domiciliare',
    en: 'Home Health Aide',
    es: 'Auxiliar de salud en el hogar',
    de: 'Hauskrankenpfleger',
    fr: 'Aide à domicile',
    pt: 'Auxiliar de Saúde Domiciliar',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 370,
    id_parent: 12,
    it: 'Tecnico di laboratorio',
    en: 'Lab Technician',
    es: 'Técnico de laboratorio',
    de: 'Labortechniker',
    fr: 'Un technicien de laboratoire',
    pt: 'Técnico de laboratório',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 371,
    id_parent: 12,
    it: 'Assistente di terapia fisica',
    en: 'Physical Therapist Assistant',
    es: 'Asistente de fisioterapeuta',
    de: 'Physiotherapeut Assistent',
    fr: 'Assistante physiothérapeute',
    pt: 'Assistente de Fisioterapeuta',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 372,
    id_parent: 12,
    it: 'Terapista Fisico',
    en: 'Physical Therapist',
    es: 'Fisioterapeuta',
    de: 'Physiotherapeut',
    fr: 'Physiothérapeute',
    pt: 'Fisioterapeuta',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 373,
    id_parent: 12,
    it: 'Terapista occupazionale',
    en: 'Occupational Therapist',
    es: 'Terapeuta ocupacional',
    de: 'Ergotherapeutin',
    fr: 'Ergothérapeute',
    pt: 'Terapeuta ocupacional',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 374,
    id_parent: 12,
    it: 'Responsabile del caso',
    en: 'Case Manager',
    es: 'Gestor de casos',
    de: 'Fallmanager',
    fr: 'Chargé de dossiers',
    pt: 'Gerente de caso',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 375,
    id_parent: 12,
    it: 'Assistente di laboratorio',
    en: 'Lab Assistant',
    es: 'Asistente de laboratorio',
    de: 'Labor Assistent',
    fr: 'Assistant de laboratoire',
    pt: 'Assistente de laboratório',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 376,
    id_parent: 12,
    it: 'Tecnico della cura del paziente',
    en: 'Patient Care Technician',
    es: 'Técnico de atención al paciente',
    de: 'Techniker für Patientenversorgung',
    fr: 'Technicien en soins aux patients',
    pt: 'Técnico de Atendimento ao Paciente',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 377,
    id_parent: 12,
    it: 'Direttore clinico',
    en: 'Clinical Director',
    es: 'Director clínico',
    de: 'Klinischer Direktor',
    fr: 'Directeur clinique',
    pt: 'Diretor Clínico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 378,
    id_parent: 12,
    it: 'Assistente dentale',
    en: 'Dental Assistant',
    es: 'Asistente dental',
    de: 'Zahnarzthelferin',
    fr: 'Assistante dentaire',
    pt: 'Assistente de dentista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 379,
    id_parent: 12,
    it: 'Assistente medico',
    en: 'Medical Assistant',
    es: 'Asistente médico',
    de: 'Medizinischer Assistent',
    fr: 'Assistant médical',
    pt: 'Assistente médico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 380,
    id_parent: 12,
    it: 'Coordinatore dell\'unità sanitaria',
    en: 'Health Unit Coordinator',
    es: 'Coordinador de la Unidad de Salud',
    de: 'Koordinatorin der Gesundheitsabteilung',
    fr: 'Coordonnatrice de l\'unité de santé',
    pt: 'Coordenador da Unidade de Saúde',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 381,
    id_parent: 12,
    it: 'Terapista termale',
    en: 'Spa Therapist',
    es: 'Terapeuta de Spa',
    de: 'Spa-Therapeut',
    fr: 'Spa thérapeute',
    pt: 'Terapeuta de Spa',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 382,
    id_parent: 12,
    it: 'LPN',
    en: 'LPN',
    es: 'LPN',
    de: 'LPN',
    fr: 'IAA',
    pt: 'LPN',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 383,
    id_parent: 12,
    it: 'Direttore infermieristico',
    en: 'Director of Nursing',
    es: 'Director de enfermería',
    de: 'Direktorin der Pflege',
    fr: 'Directrice des soins infirmiers',
    pt: 'Diretor de Enfermagem',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 384,
    id_parent: 12,
    it: 'Infermiera registrata',
    en: 'Registered Nurse',
    es: 'Enfermera titulada',
    de: 'Staatlich geprüfte Krankenschwester',
    fr: 'Infirmière autorisée',
    pt: 'Enfermeira registrada',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 385,
    id_parent: 12,
    it: 'Infermiere',
    en: 'Nurse',
    es: 'Enfermero/a',
    de: 'Krankenschwester',
    fr: 'Infirmière',
    pt: 'Enfermeira',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 386,
    id_parent: 12,
    it: 'Infermiere di ospizio',
    en: 'Hospice Nurse',
    es: 'Enfermera de hospicio',
    de: 'Hospizkrankenschwester',
    fr: 'Infirmière en soins palliatifs',
    pt: 'Enfermeira do hospício',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 387,
    id_parent: 12,
    it: 'Infermiera di sala operatoria',
    en: 'Operating Room Nurse',
    es: 'Enfermera de quirófano',
    de: 'Operationsschwester',
    fr: 'Infirmière de salle d\'opération',
    pt: 'Enfermeira do Centro Cirúrgico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 388,
    id_parent: 13,
    it: 'Chimico',
    en: 'Chemist',
    es: 'Químico',
    de: 'Chemiker',
    fr: 'Chimiste',
    pt: 'Químico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 389,
    id_parent: 13,
    it: 'Tecnico di farmacia',
    en: 'Pharmacy Technician',
    es: 'Técnico de Farmacia',
    de: 'Apothekentechniker',
    fr: 'Préparateur en pharmacie',
    pt: 'Técnico farmacêutico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 390,
    id_parent: 13,
    it: 'Farmacista clinico',
    en: 'Clinical Pharmacist',
    es: 'Farmacéutico clínico',
    de: 'Klinischer Apotheker',
    fr: 'Pharmacien clinicien',
    pt: 'Farmacêutico Clínico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 391,
    id_parent: 13,
    it: 'Farmacista',
    en: 'Pharmacist',
    es: 'Farmacéutico',
    de: 'Apotheker',
    fr: 'Pharmacien',
    pt: 'Farmacêutico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 392,
    id_parent: 13,
    it: 'Rappresentante di vendite farmaceutiche',
    en: 'Pharmaceutical Sales Representative',
    es: 'Representante de ventas farmacéuticas',
    de: 'Pharmazeutischer Handelsvertreter',
    fr: 'Représentant des ventes pharmaceutiques',
    pt: 'Representante de vendas farmacêuticas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 393,
    id_parent: 14,
    it: 'Barback',
    en: 'Barback',
    es: 'Barback',
    de: 'Barback',
    fr: 'Barback',
    pt: 'Barback',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 394,
    id_parent: 14,
    it: 'Facchino di cucina',
    en: 'Kitchen Porter',
    es: 'Portero de cocina',
    de: 'Küchenportier',
    fr: 'Portier de cuisine',
    pt: 'Porteiro de cozinha',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 395,
    id_parent: 14,
    it: 'Maggiordomo',
    en: 'Butler',
    es: 'Mayordomo',
    de: 'Diener',
    fr: 'majordome',
    pt: 'Mordomo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 396,
    id_parent: 14,
    it: 'Responsabile delle risorse umane del ristorante',
    en: 'Restaurant HR Manager',
    es: 'Responsable de RRHH del restaurante',
    de: 'Personalleiter Restaurant',
    fr: 'Responsable RH restauration',
    pt: 'Gerente de RH Restaurante',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 397,
    id_parent: 14,
    it: 'Supervisore delle pulizie',
    en: 'Housekeeping Supervisor',
    es: 'Supervisor de limpieza',
    de: 'Hausmeister',
    fr: 'Superviseur de l\'entretien ménager',
    pt: 'supervisor de limpeza doméstica',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 398,
    id_parent: 14,
    it: 'Responsabile delle risorse umane dell\'hotel',
    en: 'Hotel HR Manager',
    es: 'Director de RRHH del hotel',
    de: 'HR-Manager des Hotels',
    fr: 'Responsable RH hôtel',
    pt: 'Gerente de RH Hoteleiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 399,
    id_parent: 14,
    it: 'Agente della reception dell\'hotel',
    en: 'Hotel Front Desk Agent',
    es: 'Agente de recepción de hotel',
    de: 'Mitarbeiter an der Hotelrezeption',
    fr: 'Agent de la réception de l\'hôtel',
    pt: 'Agente de recepção do hotel',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 400,
    id_parent: 14,
    it: 'Busser',
    en: 'Busser',
    es: 'Busser',
    de: 'Busser',
    fr: 'Busser',
    pt: 'Busser',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 401,
    id_parent: 14,
    it: 'Assistente direttore del ristorante',
    en: 'Restaurant Assistant Manager',
    es: 'Ayudante de dirección de restaurante',
    de: 'Restaurant-Assistent-Manager',
    fr: 'Gérant adjoint de restauration',
    pt: 'Gerente auxiliar de restaurante',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 402,
    id_parent: 14,
    it: 'Pizza Chef',
    en: 'Pizza Chef',
    es: 'Chef de Pizza',
    de: 'Pizzakoch',
    fr: 'Pizzaiolo',
    pt: 'Chef de pizza',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 403,
    id_parent: 14,
    it: 'Sushi Chef',
    en: 'Sushi Chef',
    es: 'Chef de Sushi',
    de: 'Sushi-Koch',
    fr: 'Sushi Chef',
    pt: 'Sushi Chef',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 404,
    id_parent: 14,
    it: 'Sommelier',
    en: 'Sommelier',
    es: 'Sommelier',
    de: 'Sommelier',
    fr: 'Sommelier',
    pt: 'sommelier',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 405,
    id_parent: 14,
    it: 'Cuoco di dispensa',
    en: 'Pantry Cook',
    es: 'Cocinero de despensa',
    de: 'Pantry-Koch',
    fr: 'Cuisinier du garde-manger',
    pt: 'Cozinheiro de Despensa',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 406,
    id_parent: 14,
    it: 'Manager del catering',
    en: 'Catering Manager',
    es: 'Gerente de Catering',
    de: 'Catering-Manager',
    fr: 'Responsable restauration',
    pt: 'Gerente de refeições',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 407,
    id_parent: 14,
    it: 'Responsabile del caffè',
    en: 'Cafe Manager',
    es: 'Encargado de cafetería',
    de: 'Café-Manager',
    fr: 'Gérant de café',
    pt: 'Gerente de Café',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 408,
    id_parent: 14,
    it: 'Corridore di cibo',
    en: 'Food Runner',
    es: 'Corredor de comida',
    de: 'Essenslieferant',
    fr: 'Coureur de nourriture',
    pt: 'Food Runner',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 409,
    id_parent: 14,
    it: 'Panettiere',
    en: 'Baker',
    es: 'Panadero',
    de: 'Bäcker',
    fr: 'boulanger',
    pt: 'padeiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 410,
    id_parent: 14,
    it: 'Responsabile della cucina',
    en: 'Kitchen Manager',
    es: 'Encargado de cocina',
    de: 'Küchenleiter',
    fr: 'Chef de cuisine',
    pt: 'Gerente de cozinha',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 411,
    id_parent: 14,
    it: 'Responsabile dei turni',
    en: 'Shift Manager',
    es: 'Encargado de turno',
    de: 'Schichtführer',
    fr: 'Chef de quart',
    pt: 'Gerente de turno',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 412,
    id_parent: 14,
    it: 'Assistente di sala',
    en: 'Room Attendant',
    es: 'Encargado de sala',
    de: 'Zimmermädchen',
    fr: 'Préposé aux chambres',
    pt: 'Atendente de quarto',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 413,
    id_parent: 14,
    it: 'Facchino',
    en: 'Porter',
    es: 'Portero',
    de: 'Porter',
    fr: 'Porter',
    pt: 'Porteiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 414,
    id_parent: 14,
    it: 'Lavapiatti',
    en: 'Dishwasher',
    es: 'Lavavajillas',
    de: 'Geschirrspüler',
    fr: 'Lave-vaisselle',
    pt: 'Lava-louças',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 415,
    id_parent: 14,
    it: 'Host o Hostess',
    en: 'Host or Hostess',
    es: 'Anfitrión o Anfitriona',
    de: 'Gastgeber oder Gastgeberin',
    fr: 'Hôte ou hôtesse',
    pt: 'Anfitrião ou Hospedeira',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 416,
    id_parent: 14,
    it: 'Barista',
    en: 'Barista',
    es: 'Barista',
    de: 'Barista',
    fr: 'Barista',
    pt: 'Barista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: false
  },
  {
    id: 417,
    id_parent: 14,
    it: 'Chef',
    en: 'Chef',
    es: 'Chef',
    de: 'Koch',
    fr: 'Chef',
    pt: 'Chefe de cozinha',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 418,
    id_parent: 14,
    it: 'Addetto alle relazioni con gli ospiti',
    en: 'Guest Relation Officer',
    es: 'Oficial de relaciones con los huéspedes',
    de: 'Guest-Relation-Beauftragter',
    fr: 'Chargée de relations clients',
    pt: 'Diretor de Relacionamento com Hóspedes',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 419,
    id_parent: 14,
    it: 'Responsabile della manutenzione dell\'hotel',
    en: 'Hotel Maintenance Manager',
    es: 'Jefe de mantenimiento del hotel',
    de: 'Instandhaltungsleiter des Hotels',
    fr: 'Responsable de l\'entretien de l\'hôtel',
    pt: 'Gerente de Manutenção Hoteleira',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 420,
    id_parent: 14,
    it: 'Pasticcere',
    en: 'Pastry Chef',
    es: 'Pastelero',
    de: 'Konditor',
    fr: 'Chef pâtissier',
    pt: 'Pasteleiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 421,
    id_parent: 14,
    it: 'Manager dell\'ospitalità',
    en: 'Hospitality Manager',
    es: 'Director de hostelería',
    de: 'Hospitality-Manager',
    fr: 'Responsable de l\'accueil',
    pt: 'Gerente de Hospitalidade',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 422,
    id_parent: 14,
    it: 'Cuoco',
    en: 'Cook',
    es: 'Cocinero',
    de: 'Koch',
    fr: 'cuisiner',
    pt: 'cozinheiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 423,
    id_parent: 14,
    it: 'Sous chef',
    en: 'Sous Chef',
    es: 'Sous Chef',
    de: 'Sous-Chef',
    fr: 'Sous-chef',
    pt: 'Sous Chef',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 424,
    id_parent: 14,
    it: 'Preparatore di cucina',
    en: 'Prep Cook',
    es: 'Cocinero de preparación',
    de: 'Hilfskoch',
    fr: 'Cuisinier',
    pt: 'Preparar Cozinheiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 425,
    id_parent: 14,
    it: 'Capo cuoco',
    en: 'Head Chef',
    es: 'Jefe de cocina',
    de: 'Chefkoch',
    fr: 'Chef de cuisine',
    pt: 'Chefe de cozinha',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 426,
    id_parent: 14,
    it: 'Governante',
    en: 'Housekeeper',
    es: 'Ama de casa',
    de: 'Haushälterin',
    fr: 'Gouvernante',
    pt: 'Empregada',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 427,
    id_parent: 14,
    it: 'Chef de Partie',
    en: 'Chef de Partie',
    es: 'Chef de Partie',
    de: 'Chef de Partie',
    fr: 'Chef de partie',
    pt: 'Chef de Partie',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 428,
    id_parent: 14,
    it: 'Cameriere',
    en: 'Wait Staff',
    es: 'Camareros',
    de: 'Kellner',
    fr: 'Personnel d\'attente',
    pt: 'Equipe de espera',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 429,
    id_parent: 14,
    it: 'Responsabile delle relazioni con gli ospiti',
    en: 'Guest Relations Manager',
    es: 'Director de relaciones con los clientes',
    de: 'Guest-Relations-Manager',
    fr: 'Responsable des relations clients',
    pt: 'Gerente de Relacionamento com Hóspedes',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 430,
    id_parent: 14,
    it: 'Manager del ristorante',
    en: 'Restaurant Manager',
    es: 'Director de restaurante',
    de: 'Restaurantmanager',
    fr: 'Gérant de restaurant',
    pt: 'Gerente de restaurante',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 431,
    id_parent: 14,
    it: 'Manager di cibo e bevande',
    en: 'Food and Beverage Manager',
    es: 'Gerente de Alimentos y Bebidas',
    de: 'Lebensmittel-und Getränkemanager',
    fr: 'Chef de département aliments et boissons',
    pt: 'Gerente de Alimentos e Bebidas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 432,
    id_parent: 14,
    it: 'Cuoco di linea',
    en: 'Line Cook',
    es: 'Cocinero de línea',
    de: 'Linienkoch',
    fr: 'Cuisinier à la chaîne',
    pt: 'Cozimento de linha',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 433,
    id_parent: 14,
    it: 'Barista',
    en: 'Bartender',
    es: 'Camarero',
    de: 'Barmann',
    fr: 'Barman',
    pt: 'Barman',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 434,
    id_parent: 14,
    it: 'Server del ristorante',
    en: 'Restaurant Server',
    es: 'Servidor de restaurante',
    de: 'Restaurant-Server',
    fr: 'Serveur de restaurant',
    pt: 'Servidor de restaurante',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 435,
    id_parent: 14,
    it: 'Esploratore di cibo',
    en: 'Food Expeditor',
    es: 'Expedidor de alimentos',
    de: 'Lebensmittel-Expeditor',
    fr: 'Expéditeur alimentaire',
    pt: 'Expedidor de Alimentos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 436,
    id_parent: 14,
    it: 'Cameriere/cameriera',
    en: 'Waiter/Waitress',
    es: 'Camarero/a',
    de: 'Kellner Kellnerin',
    fr: 'Serveur serveuse',
    pt: 'Garçom garçonete',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 437,
    id_parent: 14,
    it: 'Concierge d\'albergo',
    en: 'Hotel Concierge',
    es: 'Conserje de hotel',
    de: 'Hotel-Concierge',
    fr: 'Conciergerie de l\'hôtel',
    pt: 'Concierge do hotel',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 438,
    id_parent: 14,
    it: 'Direttore d\'albergo',
    en: 'Hotel Manager',
    es: 'Director de hotel',
    de: 'Hotelmanager',
    fr: 'Directeur de l\'hôtel',
    pt: 'Gerente do hotel',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 439,
    id_parent: 14,
    it: 'Cameriera',
    en: 'Maid',
    es: 'Camarera',
    de: 'Dienstmädchen',
    fr: 'Femme de ménage',
    pt: 'Empregada',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 440,
    id_parent: 14,
    it: 'Manager di eventi',
    en: 'Event Manager',
    es: 'Director de eventos',
    de: 'Event Manager',
    fr: 'Responsable de l\'événement',
    pt: 'Gerente de eventos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 441,
    id_parent: 14,
    it: 'Coordinatore di eventi',
    en: 'Event Coordinator',
    es: 'Coordinador de eventos',
    de: 'Event-Koordinator',
    fr: 'Coordinateur d\'évenements',
    pt: 'Coordenador do evento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 442,
    id_parent: 14,
    it: 'Organizzatore di eventi',
    en: 'Event Planner',
    es: 'Planificador de eventos',
    de: 'Eventplaner',
    fr: 'Planificateur d\'événements',
    pt: 'Planeador de eventos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 443,
    id_parent: 14,
    it: 'Chef Esecutivo',
    en: 'Executive Chef',
    es: 'Jefe de cocina',
    de: 'Geschäftsführer',
    fr: 'Chef executif',
    pt: 'Chefe executivo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 444,
    id_parent: 15,
    it: 'Pilota di linea',
    en: 'Airline Pilot',
    es: 'Piloto de avión',
    de: 'Fluglinienpilot',
    fr: 'Pilote de ligne',
    pt: 'Piloto de avião',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 445,
    id_parent: 15,
    it: 'Manager di viaggi aziendali',
    en: 'Corporate Travel Manager',
    es: 'Gestor de viajes de empresa',
    de: 'Reisemanager für Unternehmen',
    fr: 'Responsable voyages d\'affaires',
    pt: 'Gerente de viagens corporativas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 446,
    id_parent: 15,
    it: 'Consulente di viaggio',
    en: 'Travel Consultant',
    es: 'Consultor de viajes',
    de: 'Reiseberater',
    fr: 'Consultant Voyage',
    pt: 'Consultor de viagem',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 447,
    id_parent: 15,
    it: 'Equipaggio di cabina',
    en: 'Cabin Crew',
    es: 'Tripulación de cabina',
    de: 'Kabinenbesatzung',
    fr: 'Équipage de cabine',
    pt: 'Tripulação da cabine',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 448,
    id_parent: 15,
    it: 'Agente di viaggio',
    en: 'Travel Agent',
    es: 'Agente de viajes',
    de: 'Reisebüro',
    fr: 'Agent de voyage',
    pt: 'Agente de viagens',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 449,
    id_parent: 15,
    it: 'Assistente di volo',
    en: 'Flight Attendant',
    es: 'Auxiliar de vuelo',
    de: 'Flugbegleiterin',
    fr: 'Hôtesse de l\'air',
    pt: 'Comissária de bordo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 450,
    id_parent: 16,
    it: 'Responsabile dei talenti',
    en: 'Chief Talent Officer',
    es: 'Director de Talento',
    de: 'Chief Talent Officer',
    fr: 'Directeur des talents',
    pt: 'Diretor de Talentos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 451,
    id_parent: 16,
    it: 'Assistente al reclutamento',
    en: 'Recruitment Assistant',
    es: 'Asistente de contratación',
    de: 'Rekrutierungsassistent',
    fr: 'Assistante Recrutement',
    pt: 'Assistente de Recrutamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 452,
    id_parent: 16,
    it: 'Reclutatore junior',
    en: 'Junior Recruiter',
    es: 'Reclutador junior',
    de: 'Junior Recruiter',
    fr: 'Recruteur junior',
    pt: 'Recrutador Júnior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 453,
    id_parent: 16,
    it: 'Reclutatore a contratto',
    en: 'Contract Recruiter',
    es: 'Reclutador de contratos',
    de: 'Vertragsanwerber',
    fr: 'Recruteur contractuel',
    pt: 'Recrutador de contrato',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 454,
    id_parent: 16,
    it: 'Talent Scout',
    en: 'Talent Scout',
    es: 'Explorador de talentos',
    de: 'Talentscout',
    fr: 'Découvreur de talents',
    pt: 'Caçador de talentos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 455,
    id_parent: 16,
    it: 'Direttore del libro paga',
    en: 'Payroll Director',
    es: 'Director de nóminas',
    de: 'Lohnbuchhalter',
    fr: 'Directeur Paie',
    pt: 'Diretor de folha de pagamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 456,
    id_parent: 16,
    it: 'Analista del libro paga',
    en: 'Payroll Analyst',
    es: 'Analista de nóminas',
    de: 'Gehaltsanalyst',
    fr: 'Analyste de paie',
    pt: 'Analista de folha de pagamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 457,
    id_parent: 16,
    it: 'Specialista di reclutamento',
    en: 'Recruitment Specialist',
    es: 'Especialista en contratación',
    de: 'Rekrutierungsspezialist',
    fr: 'Spécialiste du recrutement',
    pt: 'Especialista em Recrutamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 458,
    id_parent: 16,
    it: 'Coordinatore del libro paga',
    en: 'Payroll Coordinator',
    es: 'Coordinador de nóminas',
    de: 'Lohnkoordinator',
    fr: 'Coordonnateur de la paie',
    pt: 'Coordenador de Folha de Pagamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 459,
    id_parent: 16,
    it: 'Reclutatore delle vendite',
    en: 'Sales Recruiter',
    es: 'Reclutador de ventas',
    de: 'Verkaufsrekrutierer',
    fr: 'Recruteur des ventes',
    pt: 'Recrutador de vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 460,
    id_parent: 16,
    it: 'Reclutatore a ciclo completo',
    en: 'Full Cycle Recruiter',
    es: 'Reclutador de ciclo completo',
    de: 'Full-Cycle-Recruiter',
    fr: 'Recruteur Cycle Complet',
    pt: 'Recrutador de Ciclo Completo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 461,
    id_parent: 16,
    it: 'Reclutatore per la diversità',
    en: 'Diversity Recruiter',
    es: 'Reclutador de Diversidad',
    de: 'Diversity Recruiter',
    fr: 'Recruteur Diversité',
    pt: 'Recrutador de Diversidade',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 462,
    id_parent: 16,
    it: 'Reclutatore aziendale',
    en: 'Corporate Recruiter',
    es: 'Reclutador corporativo',
    de: 'Corporate Recruiter',
    fr: 'Recruteur d\'entreprise',
    pt: 'Recrutador Corporativo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 463,
    id_parent: 16,
    it: 'Consulente per la compensazione',
    en: 'Compensation Consultant',
    es: 'Consultor de compensación',
    de: 'Vergütungsberater',
    fr: 'Conseiller en rémunération',
    pt: 'Consultor de Remuneração',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 464,
    id_parent: 16,
    it: 'Manager della compensazione e dei benefici',
    en: 'Compensation and Benefits Manager',
    es: 'Gerente de Compensación y Beneficios',
    de: 'Vergütungs- und Leistungsmanager',
    fr: 'Gestionnaire de la rémunération et des avantages sociaux',
    pt: 'Gerente de Remuneração e Benefícios',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 465,
    id_parent: 16,
    it: 'Reclutatore bilingue',
    en: 'Bilingual Recruiter',
    es: 'Reclutador bilingüe',
    de: 'Zweisprachiger Personalvermittler',
    fr: 'Recruteur Bilingue',
    pt: 'Recrutador bilíngue',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 466,
    id_parent: 16,
    it: 'Responsabile delle risorse umane (CHRO)',
    en: 'Chief Human Resources Officer (CHRO)',
    es: 'Director de Recursos Humanos (CHRO)',
    de: 'Chief Human Resources Officer (CHRO)',
    fr: 'Directeur des ressources humaines (CHRO)',
    pt: 'Diretor de Recursos Humanos (CHRO)',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 467,
    id_parent: 16,
    it: 'Responsabile marketing del reclutamento',
    en: 'Recruitment Marketing Manager',
    es: 'Director de marketing de contratación',
    de: 'Leiter Personalmarketing',
    fr: 'Responsable Marketing Recrutement',
    pt: 'Gerente de Marketing de Recrutamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 468,
    id_parent: 16,
    it: 'Amministratore HRIS',
    en: 'HRIS Administrator',
    es: 'Administrador de HRIS',
    de: 'HRIS-Administrator',
    fr: 'Administrateur SIRH',
    pt: 'Administrador HRIS',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 469,
    id_parent: 16,
    it: 'Analista HR',
    en: 'HR Analyst',
    es: 'Analista de RRHH',
    de: 'HR-Analyst',
    fr: 'Analyste RH',
    pt: 'Analista de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 470,
    id_parent: 16,
    it: 'Capo delle operazioni HR',
    en: 'Head of HR Operations',
    es: 'Jefe de Operaciones de RRHH',
    de: 'Leiter HR Operations',
    fr: 'Responsable des Opérations RH',
    pt: 'Head de Operações de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 471,
    id_parent: 16,
    it: 'Funzionario HR & Admin',
    en: 'HR & Admin Officer',
    es: 'Responsable de RRHH y Administración',
    de: 'Personal- und Verwaltungsbeauftragter',
    fr: 'Responsable RH & Administratif',
    pt: 'Diretor de RH e Admin',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 472,
    id_parent: 16,
    it: 'Specialista delle assunzioni',
    en: 'Hiring Specialist',
    es: 'Especialista en contratación',
    de: 'Einstellungsspezialist',
    fr: 'Spécialiste du recrutement',
    pt: 'Especialista em Contratação',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 473,
    id_parent: 16,
    it: 'Responsabile HRIS',
    en: 'HRIS Manager',
    es: 'Gestor del SIRH',
    de: 'HRIS-Manager',
    fr: 'Responsable SIRH',
    pt: 'Gerente de HRIS',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 474,
    id_parent: 16,
    it: 'Stagista HR',
    en: 'HR Intern',
    es: 'Becario de RRHH',
    de: 'HR Intern',
    fr: 'Stagiaire RH',
    pt: 'Estagiário de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 513,
    id_parent: 16,
    it: 'Allenatore di lavoro',
    en: 'Job Coach',
    es: 'Preparador laboral',
    de: 'Job-Coach',
    fr: 'Coach d\'emploi',
    pt: 'Coach de Trabalho',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 475,
    id_parent: 16,
    it: 'Responsabile Diversità e Inclusione',
    en: 'Diversity and Inclusion Manager',
    es: 'Director de Diversidad e Inclusión',
    de: 'Manager für Vielfalt und Inklusion',
    fr: 'Responsable Diversité et Inclusion',
    pt: 'Gerente de Diversidade e Inclusão',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 476,
    id_parent: 16,
    it: 'Reclutatore del campus',
    en: 'Campus Recruiter',
    es: 'Reclutador de campus',
    de: 'Campus-Recruiter',
    fr: 'Recruteur universitaire',
    pt: 'Recrutador do campus',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 477,
    id_parent: 16,
    it: 'Reclutatore di agenzie di personale',
    en: 'Staffing Agency Recruiter',
    es: 'Reclutador de agencias de empleo',
    de: 'Personalvermittler für Personalagenturen',
    fr: 'Recruteur d\'agence de placement',
    pt: 'Recrutador de agência de recrutamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 478,
    id_parent: 16,
    it: 'Consulente per l\'acquisizione dei talenti',
    en: 'Talent Acquisition Consultant',
    es: 'Consultor de adquisición de talentos',
    de: 'Berater für Talentakquise',
    fr: 'Consultante en acquisition de talents',
    pt: 'Consultor de Aquisição de Talentos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 479,
    id_parent: 16,
    it: 'Manager regionale delle risorse umane',
    en: 'Regional HR Manager',
    es: 'Director regional de RRHH',
    de: 'Regionaler Personalleiter',
    fr: 'Responsable RH Régional',
    pt: 'Gerente Regional de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 480,
    id_parent: 16,
    it: 'Manager senior delle risorse umane',
    en: 'Senior HR Manager',
    es: 'Director senior de RRHH',
    de: 'Leitender Personalleiter',
    fr: 'Responsable RH Sénior',
    pt: 'Gerente Sênior de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 481,
    id_parent: 16,
    it: 'Manager delle operazioni HR',
    en: 'HR Operations Manager',
    es: 'Director de operaciones de RRHH',
    de: 'HR Operations Manager',
    fr: 'Responsable des opérations RH',
    pt: 'Gerente de Operações de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 482,
    id_parent: 16,
    it: 'Impiegato HR',
    en: 'HR Clerk',
    es: 'Empleado de RRHH',
    de: 'Personalsachbearbeiter',
    fr: 'Commis RH',
    pt: 'Atendente de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 483,
    id_parent: 16,
    it: 'Addetto al libro paga',
    en: 'Payroll Officer',
    es: 'Responsable de nóminas',
    de: 'Lohnbuchhalter',
    fr: 'Agent de paie',
    pt: 'Oficial de folha de pagamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 484,
    id_parent: 16,
    it: 'Consulente HR',
    en: 'HR Advisor',
    es: 'Asesor de RRHH',
    de: 'Personalberater',
    fr: 'Conseiller RH',
    pt: 'Consultor de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 485,
    id_parent: 16,
    it: 'Reclutatore HR',
    en: 'HR Recruiter',
    es: 'Reclutador de RRHH',
    de: 'HR-Recruiter',
    fr: 'Recruteur RH',
    pt: 'Recrutador de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 486,
    id_parent: 16,
    it: 'Responsabile delle risorse umane',
    en: 'HR Executive',
    es: 'Ejecutivo de RRHH',
    de: 'Personalleiter',
    fr: 'Responsable RH',
    pt: 'Executivo de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 487,
    id_parent: 16,
    it: 'Responsabile HR Onboarding',
    en: 'HR Onboarding Manager',
    es: 'Gestor de incorporación de RRHH',
    de: 'HR-Onboarding-Manager',
    fr: 'Responsable RH Onboarding',
    pt: 'Gerente de Integração de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 488,
    id_parent: 16,
    it: 'Specialista dell\'inserimento delle risorse umane',
    en: 'HR Onboarding Specialist',
    es: 'Especialista en incorporación de RRHH',
    de: 'HR-Onboarding-Spezialist',
    fr: 'Spécialiste de l\'intégration RH',
    pt: 'Especialista em Integração de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 489,
    id_parent: 16,
    it: 'VP dell\'acquisizione dei talenti',
    en: 'VP of Talent Acquisition',
    es: 'VP de Adquisición de Talentos',
    de: 'VP für Talentakquise',
    fr: 'Vice-président de l\'acquisition de talents',
    pt: 'VP de Aquisição de Talentos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 490,
    id_parent: 16,
    it: 'Specialista dell\'acquisizione dei talenti',
    en: 'Talent Acquisition Specialist',
    es: 'Especialista en Adquisición de Talentos',
    de: 'Spezialist für Talentakquise',
    fr: 'Spécialiste de l\'acquisition de talents',
    pt: 'Especialista em Aquisição de Talentos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 491,
    id_parent: 16,
    it: 'Cercatore di talenti',
    en: 'Talent Sourcer',
    es: 'Buscador de talentos',
    de: 'Talent Sourcer',
    fr: 'Sourceur de talents',
    pt: 'Fonte de Talentos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 492,
    id_parent: 16,
    it: 'Coordinatore dell\'acquisizione dei talenti',
    en: 'Talent Acquisition Coordinator',
    es: 'Coordinador de Adquisición de Talentos',
    de: 'Koordinator für Talentakquise',
    fr: 'Coordonnatrice de l\'acquisition de talents',
    pt: 'Coordenador de Aquisição de Talentos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 493,
    id_parent: 16,
    it: 'Specialista del personale',
    en: 'Staffing Specialist',
    es: 'Especialista en contratación de personal',
    de: 'Personalspezialist',
    fr: 'Spécialiste en affectation du personnel',
    pt: 'Especialista de Pessoal',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 494,
    id_parent: 16,
    it: 'Direttore dell\'acquisizione dei talenti',
    en: 'Talent Acquisition Director',
    es: 'Director de Adquisición de Talentos',
    de: 'Direktor für Talentakquise',
    fr: 'Directeur de l\'acquisition de talents',
    pt: 'Diretor de Aquisição de Talentos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 495,
    id_parent: 16,
    it: 'Specialista del sourcing',
    en: 'Sourcing Specialist',
    es: 'Especialista en contratación',
    de: 'Beschaffungs-Spezialist',
    fr: 'Spécialiste de l\'approvisionnement',
    pt: 'especialista em terceirização',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 496,
    id_parent: 16,
    it: 'Headhunter',
    en: 'Headhunter',
    es: 'Cazatalentos',
    de: 'Kopfjäger',
    fr: 'Chasseur de têtes',
    pt: 'Caçador de cabeças',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 497,
    id_parent: 16,
    it: 'Direttore del talento',
    en: 'Director of Talent',
    es: 'Director de Talento',
    de: 'Direktor des Talents',
    fr: 'Directeur des Talents',
    pt: 'Diretor de Talentos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 498,
    id_parent: 16,
    it: 'VP Gestione dei talenti',
    en: 'VP Talent Management',
    es: 'Vicepresidente de Gestión del Talento',
    de: 'VP Talentmanagement',
    fr: 'VP Gestion des talents',
    pt: 'VP Gestão de Talentos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 499,
    id_parent: 16,
    it: 'Reclutamento Business Partner',
    en: 'Recruitment Business Partner',
    es: 'Socio de negocios de reclutamiento',
    de: 'Geschäftspartner für die Rekrutierung',
    fr: 'Partenaire d\'affaires en recrutement',
    pt: 'Parceiro de negócios de recrutamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 500,
    id_parent: 16,
    it: 'Reclutatore tecnico',
    en: 'Technical Recruiter',
    es: 'Reclutador técnico',
    de: 'Technischer Recruiter',
    fr: 'Recruteur technique',
    pt: 'Recrutador Técnico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 501,
    id_parent: 16,
    it: 'Specialista delle risorse umane',
    en: 'HR Specialist',
    es: 'Especialista en RRHH',
    de: 'HR-Spezialist',
    fr: 'spécialiste en ressources humaines',
    pt: 'Especialista em RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 502,
    id_parent: 16,
    it: 'Reclutatore interno',
    en: 'Internal Recruiter',
    es: 'Reclutador interno',
    de: 'Interner Personalvermittler',
    fr: 'Recruteur interne',
    pt: 'Recrutador Interno',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 503,
    id_parent: 16,
    it: 'Manager del reclutamento',
    en: 'Recruitment Manager',
    es: 'Director de contratación',
    de: 'Rekrutierungsmanager',
    fr: 'Responsable du recrutement',
    pt: 'Gerente de Recrutamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 504,
    id_parent: 16,
    it: 'Responsabile del libro paga',
    en: 'Payroll Manager',
    es: 'Gestor de nóminas',
    de: 'Lohnbuchhalter',
    fr: 'Gestionnaire de paie',
    pt: 'Gerente de folha de pagamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 505,
    id_parent: 16,
    it: 'Amministratore delle risorse umane',
    en: 'HR Administrator',
    es: 'Administrador de RRHH',
    de: 'HR-Administrator',
    fr: 'Administrateur RH',
    pt: 'Administrador de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 506,
    id_parent: 16,
    it: 'Assistente amministrativo HR',
    en: 'HR Administrative Assistant',
    es: 'Asistente administrativo de RRHH',
    de: 'Assistentin der Personalverwaltung',
    fr: 'Assistante administrative RH',
    pt: 'Auxiliar Administrativo de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 507,
    id_parent: 16,
    it: 'Coordinatore del reclutamento',
    en: 'Recruiting Coordinator',
    es: 'Coordinador de contratación',
    de: 'Rekrutierungskoordinator',
    fr: 'Coordonnatrice du recrutement',
    pt: 'Coordenador de Recrutamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 508,
    id_parent: 16,
    it: 'HR Business Partner',
    en: 'HR Business Partner',
    es: 'Socio empresarial de RRHH',
    de: 'HR Business Partner',
    fr: 'Partenaire des Resources Humaines',
    pt: 'Parceiro de negócios de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 509,
    id_parent: 16,
    it: 'Reclutatore esecutivo',
    en: 'Executive Recruiter',
    es: 'Reclutador ejecutivo',
    de: 'Executive Recruiter',
    fr: 'Recruteur de cadres',
    pt: 'Recrutador Executivo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 510,
    id_parent: 16,
    it: 'Specialista della compensazione e dei benefici',
    en: 'Compensation and Benefits Specialist',
    es: 'Especialista en compensación y beneficios',
    de: 'Vergütungs- und Leistungsspezialist',
    fr: 'Spécialiste de la rémunération et des avantages sociaux',
    pt: 'Especialista em Remuneração e Benefícios',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 511,
    id_parent: 16,
    it: 'Manager dell\'acquisizione dei talenti',
    en: 'Talent Acquisition Manager',
    es: 'Director de adquisición de talentos',
    de: 'Talent Acquisition Manager',
    fr: 'Gestionnaire d\'acquisition de talents',
    pt: 'Gerente de Aquisição de Talentos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 514,
    id_parent: 16,
    it: 'Coordinatore dei volontari',
    en: 'Volunteer Coordinator',
    es: 'Coordinador de voluntarios',
    de: 'Freiwilligenkoordinator',
    fr: 'Coordonnatrice des bénévoles',
    pt: 'Coordenador Voluntário',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 515,
    id_parent: 16,
    it: 'Coordinatore del personale',
    en: 'Staffing Coordinator',
    es: 'Coordinador de personal',
    de: 'Personalkoordinator',
    fr: 'Coordonnatrice de la dotation',
    pt: 'Coordenador de Pessoal',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 516,
    id_parent: 16,
    it: 'Amministratore dei benefici',
    en: 'Benefits Administratorn',
    es: 'Administrador de beneficios',
    de: 'Vorteilsadministrator',
    fr: 'Administrateur des avantages sociaux',
    pt: 'Administrador de Benefícios',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 517,
    id_parent: 16,
    it: 'Addetto alle risorse umane',
    en: 'HR Officer',
    es: 'Responsable de RRHH',
    de: 'Personalreferent',
    fr: 'Responsable RH',
    pt: 'Diretor de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 518,
    id_parent: 16,
    it: 'Consulente HR',
    en: 'HR Consultant',
    es: 'Consultor de RRHH',
    de: 'Personalberater',
    fr: 'Consultante RH',
    pt: 'Consultor de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 519,
    id_parent: 16,
    it: 'Consulente per il reclutamento',
    en: 'Recruitment Consultant',
    es: 'Consultor de contratación',
    de: 'Personalberater',
    fr: 'Consultante en Recrutement',
    pt: 'Consultor de recrutamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 520,
    id_parent: 16,
    it: 'Vicepresidente delle Risorse Umane',
    en: 'VP of HR',
    es: 'Vicepresidente de RRHH',
    de: 'Vizepräsident der Personalabteilung',
    fr: 'VP des RH',
    pt: 'vice-presidente de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 521,
    id_parent: 16,
    it: 'Direttore delle Risorse Umane',
    en: 'HR Director',
    es: 'Director de RRHH',
    de: 'Leiter der Personalabteilung',
    fr: 'Directeur des ressources humaines',
    pt: 'Diretor de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 522,
    id_parent: 16,
    it: 'Manager delle risorse umane (HR)',
    en: 'Human Resources (HR) Manager',
    es: 'Director de Recursos Humanos (RH)',
    de: 'Personalleiter (HR).',
    fr: 'Responsable des Ressources Humaines (RH)',
    pt: 'Gerente de Recursos Humanos (RH)',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 523,
    id_parent: 16,
    it: 'Coordinatore delle risorse umane',
    en: 'HR Coordinator',
    es: 'Coordinador de RRHH',
    de: 'Personalkoordinator',
    fr: 'coordinateur en ressources humaines',
    pt: 'Coordenador de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 524,
    id_parent: 16,
    it: 'Specialista del libro paga',
    en: 'Payroll Specialist',
    es: 'Especialista en nóminas',
    de: 'Spezialist für Lohnbuchhaltung',
    fr: 'Spécialiste de la paie',
    pt: 'Especialista em folha de pagamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 525,
    id_parent: 16,
    it: 'Impiegato del libro paga',
    en: 'Payroll Clerk',
    es: 'Empleado de nóminas',
    de: 'Lohnbuchhalter',
    fr: 'Commis à la paie',
    pt: 'Caixeiro da folha de pagamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 526,
    id_parent: 16,
    it: 'Assistente HR',
    en: 'HR Assistant',
    es: 'Asistente de RRHH',
    de: 'HR-Assistent',
    fr: 'Assistante RH',
    pt: 'assistente de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 527,
    id_parent: 16,
    it: 'Generalista delle Risorse Umane',
    en: 'HR Generalist',
    es: 'Generalista de RRHH',
    de: 'HR Generalist',
    fr: 'Généraliste RH',
    pt: 'Generalista de RH',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 528,
    id_parent: 17,
    it: 'Agente di polizia',
    en: 'Police Officer',
    es: 'Oficial de policía',
    de: 'Polizist',
    fr: 'Officier de police',
    pt: 'Policial',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 529,
    id_parent: 17,
    it: 'Investigatore criminale',
    en: 'Criminal Investigator',
    es: 'Investigador penal',
    de: 'Verbrechensbekämpfer',
    fr: 'Enquêteur criminel',
    pt: 'Investigador criminal',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 530,
    id_parent: 17,
    it: 'Responsabile della sicurezza',
    en: 'Security Manager',
    es: 'Director de seguridad',
    de: 'Sicherheitsmanager',
    fr: 'Responsable de la sécurité',
    pt: 'Gerente de segurança',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 531,
    id_parent: 17,
    it: 'Agente di sicurezza',
    en: 'Security Officer',
    es: 'Oficial de seguridad',
    de: 'Sicherheitsbeauftragter',
    fr: 'Agent de sécurité',
    pt: 'Oficial de segurança',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 532,
    id_parent: 17,
    it: 'Guardia di sicurezza',
    en: 'Security Guard',
    es: 'Guardia de seguridad',
    de: 'Sicherheitsbeamter',
    fr: 'Garde de sécurité',
    pt: 'Segurança',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 533,
    id_parent: 17,
    it: 'Investigatore di frodi',
    en: 'Fraud Investigator',
    es: 'Investigador de fraudes',
    de: 'Betrugsermittler',
    fr: 'Enquêteur sur la fraude',
    pt: 'Investigador de fraudes',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 534,
    id_parent: 17,
    it: 'Vigile del fuoco',
    en: 'Firefighter',
    es: 'Bombero',
    de: 'Feuerwehrmann',
    fr: 'Sapeur pompier',
    pt: 'Bombeiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 535,
    id_parent: 18,
    it: 'Responsabile protezione dati GDPR',
    en: 'GDPR Data Protection Officer',
    es: 'Responsable de protección de datos del GDPR',
    de: 'DSGVO Datenschutzbeauftragter',
    fr: 'Délégué à la protection des données RGPD',
    pt: 'Diretor de Proteção de Dados GDPR',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 536,
    id_parent: 18,
    it: 'Responsabile del rischio',
    en: 'Risk Manager',
    es: 'Gestor de riesgos',
    de: 'Risikomanager',
    fr: 'Gestionnaire des risques',
    pt: 'Gerente de risco',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 537,
    id_parent: 18,
    it: 'Responsabile della conformità',
    en: 'Compliance Officer',
    es: 'Responsable de Cumplimiento Normativo',
    de: 'Compliance-Beauftragter',
    fr: 'Agent de conformité',
    pt: 'Diretor de Conformidade',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 538,
    id_parent: 18,
    it: 'Segretaria legale',
    en: 'Legal Secretary',
    es: 'Secretario Jurídico',
    de: 'Rechtssekretär',
    fr: 'Secrétaire juridique',
    pt: 'Secretário Jurídico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 539,
    id_parent: 18,
    it: 'Consulente legale',
    en: 'Legal Counsel',
    es: 'Asesor jurídico',
    de: 'Rechtsbeistand',
    fr: 'Conseiller juridique',
    pt: 'Consultor Jurídico',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 540,
    id_parent: 18,
    it: 'Assistente legale',
    en: 'Legal Assistant',
    es: 'Asistente jurídico',
    de: 'Rechtsanwaltsgehilfe',
    fr: 'Assistant juridique',
    pt: 'Assistente legal',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 541,
    id_parent: 18,
    it: 'Consulente generale',
    en: 'General Counsel',
    es: 'Asesor general',
    de: 'General Counsel',
    fr: 'Avocat général',
    pt: 'Conselho Geral',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 542,
    id_parent: 18,
    it: 'Responsabile della conformità',
    en: 'Compliance Manager',
    es: 'Director de Cumplimiento Normativo',
    de: 'Wirtschaftsprüfer',
    fr: 'Gestionnaire de la conformité',
    pt: 'Gerente de conformidade',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 543,
    id_parent: 18,
    it: 'Revisore interno',
    en: 'Internal Auditor',
    es: 'Auditor interno',
    de: 'Interne Rechnungsprüfer',
    fr: 'Auditeur interne',
    pt: 'Auditor interno',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 544,
    id_parent: 18,
    it: 'Paralegale di contenzioso',
    en: 'Litigation Paralegal',
    es: 'Paralegal de Litigios',
    de: 'Rechtsanwaltsfachangestellte für Rechtsstreitigkeiten',
    fr: 'Parajuriste en litige',
    pt: 'Paralegal de Contencioso',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 545,
    id_parent: 18,
    it: 'Avvocato aziendale',
    en: 'Corporate Attorney',
    es: 'Abogado de empresa',
    de: 'Unternehmensanwalt',
    fr: 'Avocat d\'entreprise',
    pt: 'Advogado Corporativo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 546,
    id_parent: 19,
    it: 'Responsabile della catena di approvvigionamento',
    en: 'Supply Chain Manager',
    es: 'Gestor de la cadena de suministro',
    de: 'Supply-Chain-Manager',
    fr: 'Gestionnaire de la chaine d\'approvisionnement',
    pt: 'Gerente da Cadeia de Suprimentos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 547,
    id_parent: 19,
    it: 'Addetto ai materiali',
    en: 'Material Handler',
    es: 'Manipulador de materiales',
    de: 'Materialhandler',
    fr: 'Manutentionnaire',
    pt: 'Movimentação de materiais',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 548,
    id_parent: 19,
    it: 'Autista',
    en: 'Driver',
    es: 'Conductor',
    de: 'Treiber',
    fr: 'Chauffeur',
    pt: 'Condutor',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 549,
    id_parent: 19,
    it: 'Amministratore di logistica',
    en: 'Logistics Administrator',
    es: 'Administrador de logística',
    de: 'Logistikadministrator',
    fr: 'Administrateur Logistique',
    pt: 'Administrador de Logística',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 550,
    id_parent: 19,
    it: 'Assistente agli acquisti',
    en: 'Purchasing Assistant',
    es: 'Asistente de compras',
    de: 'Einkaufsassistent',
    fr: 'Assistante Achats',
    pt: 'Assistente de compras',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 551,
    id_parent: 19,
    it: 'Controllore di magazzino',
    en: 'Stock Controller',
    es: 'Controlador de existencias',
    de: 'Stock-Controller',
    fr: 'Controleur de bourse',
    pt: 'Controlador de estoque',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 552,
    id_parent: 19,
    it: 'Addetto agli acquisti',
    en: 'Purchasing Officer',
    es: 'Oficial de compras',
    de: 'Einkaufsleiter',
    fr: 'Acheteur',
    pt: 'Diretor de compras',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 553,
    id_parent: 19,
    it: 'Responsabile dei trasporti',
    en: 'Transportation Manager',
    es: 'Gestor de transporte',
    de: 'Transportmanager',
    fr: 'Gestionnaire de transport',
    pt: 'Gerente de Transporte',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 554,
    id_parent: 19,
    it: 'Analista della catena di approvvigionamento',
    en: 'Supply Chain Analyst',
    es: 'Analista de la cadena de suministro',
    de: 'Supply-Chain-Analyst',
    fr: 'Analyste de la chaîne d\'approvisionnement',
    pt: 'Analista de cadeia de suprimentos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 555,
    id_parent: 19,
    it: 'Responsabile degli acquisti',
    en: 'Procurement Manager',
    es: 'Director de compras',
    de: 'Beschaffungsmanager',
    fr: 'Responsable des achats',
    pt: 'Gerente de Compras',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 556,
    id_parent: 19,
    it: 'Responsabile acquisti',
    en: 'Purchasing Manager',
    es: 'Director de compras',
    de: 'Einkaufsleiter',
    fr: 'Directeur des achats',
    pt: 'Gerente de compras',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 557,
    id_parent: 19,
    it: 'Responsabile del sourcing',
    en: 'Sourcing Manager',
    es: 'Director de compras',
    de: 'Beschaffungsmanager',
    fr: 'Responsable de l\'approvisionnement',
    pt: 'Gerente de abastecimento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 558,
    id_parent: 19,
    it: 'Agente d\'acquisto',
    en: 'Purchasing Agent',
    es: 'Agente de compras',
    de: 'Einkäufer',
    fr: 'Agent d\'achat',
    pt: 'Agente de compra',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 559,
    id_parent: 19,
    it: 'Supervisore di magazzino',
    en: 'Warehouse Supervisor',
    es: 'Supervisor de almacén',
    de: 'Lagerleiter',
    fr: 'Superviseur d\'entrepôt',
    pt: 'Supervisor de Armazém',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 560,
    id_parent: 19,
    it: 'Responsabile dell\'inventario',
    en: 'Inventory Manager',
    es: 'Gestor de inventarios',
    de: 'Inventar-Manager',
    fr: 'Gestionnaire d\'inventaire',
    pt: 'Gerente de Estoque',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 561,
    id_parent: 19,
    it: 'Assistente di magazzino',
    en: 'Warehouse Associate',
    es: 'Asociado de almacén',
    de: 'Lagermitarbeiter',
    fr: 'Associé d\'entrepôt',
    pt: 'Associado de Armazém',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 562,
    id_parent: 19,
    it: 'Responsabile di magazzino',
    en: 'Warehouse Manager',
    es: 'Director de almacén',
    de: 'Lagerhausmanager',
    fr: 'Directeur d\'entrepôt',
    pt: 'Gerente de armazém',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 563,
    id_parent: 19,
    it: 'Responsabile dei materiali',
    en: 'Materials Manager',
    es: 'Gestor de materiales',
    de: 'Materialmanager',
    fr: 'Responsable des matériaux',
    pt: 'Gerente de Materiais',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 564,
    id_parent: 19,
    it: 'Operaio di magazzino',
    en: 'Warehouse Worker',
    es: 'Trabajador de almacén',
    de: 'Lagerarbeiter',
    fr: 'Ouvrier d\'entrepôt',
    pt: 'Trabalhador do armazém',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 565,
    id_parent: 19,
    it: 'Autista di consegna',
    en: 'Delivery Driver',
    es: 'Conductor de reparto',
    de: 'Auslieferungsfahrer',
    fr: 'Livreur',
    pt: 'Motorista de entrega',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 566,
    id_parent: 19,
    it: 'Coordinatore della logistica',
    en: 'Logistics Coordinator',
    es: 'Coordinador de logística',
    de: 'Logistikkoordinator',
    fr: 'Coordinateur Logistique',
    pt: 'coordenador de Logistica',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 567,
    id_parent: 19,
    it: 'Responsabile delle spedizioni',
    en: 'Shipping Manager',
    es: 'Gestor de envíos',
    de: 'Versandleiter',
    fr: 'Gestionnaire d\'envoi',
    pt: 'Gerente de transporte',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 568,
    id_parent: 19,
    it: 'Addetto al prelievo ordini',
    en: 'Order Picker',
    es: 'Recogedor de pedidos',
    de: 'Auftragssammler',
    fr: 'Préparateur de commandes',
    pt: 'Selecionador de pedidos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 569,
    id_parent: 19,
    it: 'Addetto alla ricezione',
    en: 'Receiving Clerk',
    es: 'Empleado de recepción',
    de: 'Empfangender Angestellter',
    fr: 'Réceptionniste',
    pt: 'Atendente de recebimento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 570,
    id_parent: 19,
    it: 'Responsabile della logistica',
    en: 'Logistics Manager',
    es: 'Director de Logística',
    de: 'Logistik-Manager',
    fr: 'Gestionnaire de la logistique',
    pt: 'Gerente de logística',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 571,
    id_parent: 19,
    it: 'Autista di camion',
    en: 'Truck Driver',
    es: 'Conductor de camiones',
    de: 'LKW Fahrer',
    fr: 'Conducteur de camion',
    pt: 'Motorista de caminhão',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 572,
    id_parent: 20,
    it: 'Giardiniere',
    en: 'Gardener',
    es: 'Jardinero',
    de: 'Gärtner',
    fr: 'Jardinier',
    pt: 'Jardineiro',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 573,
    id_parent: 20,
    it: 'Addetto alle pulizie',
    en: 'Cleaner',
    es: 'Limpiador',
    de: 'Reiniger',
    fr: 'Nettoyeur',
    pt: 'Limpador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 574,
    id_parent: 20,
    it: 'Responsabile della sicurezza',
    en: 'Safety Manager',
    es: 'Responsable de seguridad',
    de: 'Sicherheitsbeauftragter',
    fr: 'Chef de la sécurité',
    pt: 'Gerente de segurança',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 575,
    id_parent: 20,
    it: 'Addetto alla sicurezza',
    en: 'Safety Officer',
    es: 'Responsable de seguridad',
    de: 'Sicherheitsbeauftragter',
    fr: 'Officier de sécurité',
    pt: 'Oficial de segurança',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 576,
    id_parent: 20,
    it: 'Coordinatore della sicurezza',
    en: 'Safety Coordinator',
    es: 'Coordinador de seguridad',
    de: 'Sicherheitskoordinator',
    fr: 'Coordonnateur de la sécurité',
    pt: 'Coordenador de Segurança',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 577,
    id_parent: 20,
    it: 'Coordinatore delle strutture',
    en: 'Facilities Coordinator',
    es: 'Coordinador de instalaciones',
    de: 'Koordinator für Einrichtungen',
    fr: 'Coordonnateur des installations',
    pt: 'Coordenador de Instalações',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 578,
    id_parent: 20,
    it: 'Responsabile delle strutture',
    en: 'Facilities Manager',
    es: 'Gerente de instalaciones',
    de: 'Hausmeister',
    fr: 'Gestionnaire des installations',
    pt: 'Gerente de Instalações',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 579,
    id_parent: 20,
    it: 'Operatore del carrello elevatore',
    en: 'Forklift Operator',
    es: 'Operador de carretillas elevadoras',
    de: 'Gabelstaplerfahrer',
    fr: 'Opérateur de chariot élévateur',
    pt: 'Operador de empilhadeira',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 580,
    id_parent: 20,
    it: 'Responsabile dell\'impianto',
    en: 'Plant Manager',
    es: 'Director de planta',
    de: 'Betriebsleiter',
    fr: 'Directeur d\'usine',
    pt: 'Gerente de fábrica',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 581,
    id_parent: 20,
    it: 'Tuttofare',
    en: 'Handyman',
    es: 'Manitas',
    de: 'Heimwerker',
    fr: 'Bricoleur',
    pt: 'Faz-tudo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 582,
    id_parent: 20,
    it: 'Supervisore della manutenzione',
    en: 'Maintenance Supervisor',
    es: 'Supervisor de mantenimiento',
    de: 'Instandhaltungsleiter',
    fr: 'Superviseur de l\'entretien',
    pt: 'Supervisor De Manutenção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 583,
    id_parent: 20,
    it: 'Addetto alla manutenzione',
    en: 'Maintenance Worker',
    es: 'Trabajador de mantenimiento',
    de: 'Wartungsarbeiter',
    fr: 'Ouvrier d\'entretien',
    pt: 'Funcionário de manutenção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 584,
    id_parent: 20,
    it: 'Responsabile della manutenzione',
    en: 'Maintenance Manager',
    es: 'Jefe de mantenimiento',
    de: 'Wartungsmanager',
    fr: 'Responsable maintenance',
    pt: 'gerente de manutenção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 585,
    id_parent: 20,
    it: 'Tecnico della manutenzione',
    en: 'Maintenance Technician',
    es: 'Técnico de mantenimiento',
    de: 'Wartungstechniker',
    fr: 'Technicien de maintenance',
    pt: 'técnico de manutenção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 586,
    id_parent: 20,
    it: 'Custode',
    en: 'Custodian',
    es: 'Custodio',
    de: 'Verwahrer',
    fr: 'Gardien',
    pt: 'Custodiante',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 587,
    id_parent: 20,
    it: 'Inserviente',
    en: 'Janitor',
    es: 'Conserje',
    de: 'Hausmeister',
    fr: 'Concierge',
    pt: 'Zelador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 588,
    id_parent: 21,
    it: 'Analista di marketing',
    en: 'Marketing Analyst',
    es: 'Analista de marketing',
    de: 'Marktforscher',
    fr: 'Analyste Marketing',
    pt: 'Analista de marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 589,
    id_parent: 21,
    it: 'Capo del marketing',
    en: 'Head of Marketing',
    es: 'Jefe de Marketing',
    de: 'Marketingleiter',
    fr: 'Directeur du marketing',
    pt: 'Chefe de Marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 590,
    id_parent: 21,
    it: 'Assistente creativo',
    en: 'Creative Assistant',
    es: 'Asistente creativo',
    de: 'Kreativer Assistent',
    fr: 'Assistante de création',
    pt: 'Assistente de criação',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 591,
    id_parent: 21,
    it: 'Analista SEO',
    en: 'SEO Analyst',
    es: 'Analista de SEO',
    de: 'SEO-Analyst',
    fr: 'Analyste SEO',
    pt: 'Analista de SEO',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 592,
    id_parent: 21,
    it: 'Strategista del marchio',
    en: 'Brand Strategist',
    es: 'Estratega de marca',
    de: 'Markenstratege',
    fr: 'Stratège de marque',
    pt: 'Estrategista de marca',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 593,
    id_parent: 21,
    it: 'Copywriter Junior',
    en: 'Junior Copywriter',
    es: 'Redactor publicitario junior',
    de: 'Junior Texter',
    fr: 'Rédacteur Junior',
    pt: 'Redator Júnior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 594,
    id_parent: 21,
    it: 'Copywriter Senior',
    en: 'Senior Copywriter',
    es: 'Redactor publicitario senior',
    de: 'Leitender Texter',
    fr: 'Rédacteur principal',
    pt: 'Redator Sênior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 595,
    id_parent: 21,
    it: 'Copywriter di prodotto',
    en: 'Product Copywriter',
    es: 'Redactor de producto',
    de: 'Produkttexter',
    fr: 'Rédacteur produit',
    pt: 'Redator de produto',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 596,
    id_parent: 21,
    it: 'Social Media Copywriter',
    en: 'Social Media Copywriter',
    es: 'Redactor de redes sociales',
    de: 'Texter für soziale Medien',
    fr: 'Rédacteur réseaux sociaux',
    pt: 'Redatora de Mídias Sociais',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 597,
    id_parent: 21,
    it: 'Editore di contenuti',
    en: 'Content Editor',
    es: 'Editor de contenidos',
    de: 'Content-Editor',
    fr: 'Éditeur de contenu',
    pt: 'Editor de conteúdo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 598,
    id_parent: 21,
    it: 'Strategista dei contenuti',
    en: 'Content Strategist',
    es: 'Estratega de contenidos',
    de: 'Content-Stratege',
    fr: 'Stratège de contenu',
    pt: 'Estrategista de Conteúdo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 599,
    id_parent: 21,
    it: 'Creatore di contenuti',
    en: 'Content Creator',
    es: 'Creador de contenidos',
    de: 'Inhaltsersteller',
    fr: 'Créateur de contenu',
    pt: 'Criador de conteúdo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 600,
    id_parent: 21,
    it: 'Specialista in comunicazioni di marketing',
    en: 'Marketing Communications Specialist',
    es: 'Especialista en comunicaciones de marketing',
    de: 'Spezialist für Marketingkommunikation',
    fr: 'Spécialiste en communication marketing',
    pt: 'Especialista em Comunicação de Marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 601,
    id_parent: 21,
    it: 'PPC (Pay Per Click) Manager',
    en: 'PPC (Pay Per Click) Manager',
    es: 'Gestor de PPC (pago por clic)',
    de: 'PPC-Manager (Pay-per-Click).',
    fr: 'Responsable PPC (Pay Per Click)',
    pt: 'Gerente de PPC (Pay Per Click)',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 602,
    id_parent: 21,
    it: 'Assistente alle comunicazioni',
    en: 'Communications Assistant',
    es: 'Asistente de comunicación',
    de: 'Kommunikationsassistent',
    fr: 'Assistante Communication',
    pt: 'Assistente de comunicação',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 603,
    id_parent: 21,
    it: 'Manager di prodotto associato',
    en: 'Associate Product Manager',
    es: 'Gestor de productos asociado',
    de: 'Assoziierter Produktmanager',
    fr: 'Chef de produit associé',
    pt: 'Gerente de Produto Associado',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 604,
    id_parent: 21,
    it: 'Manager associato del marchio',
    en: 'Associate Brand Manager',
    es: 'Gestor de marca asociado',
    de: 'Associate Brand Manager',
    fr: 'Chef de marque associé',
    pt: 'Gerente de Marca Associado',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 605,
    id_parent: 21,
    it: 'Vice Presidente (VP) di Marketing',
    en: 'Vice President (VP) of Marketing',
    es: 'Vicepresidente (VP) de Marketing',
    de: 'Vizepräsident (VP) Marketing',
    fr: 'Vice-président (VP) du marketing',
    pt: 'Vice-presidente (VP) de Marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 606,
    id_parent: 21,
    it: 'Direttore dei media',
    en: 'Media Director',
    es: 'Director de medios de comunicación',
    de: 'Mediendirektor',
    fr: 'Directeur des médias',
    pt: 'Diretor de mídia',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 607,
    id_parent: 21,
    it: 'Analista dei social media',
    en: 'Social Media Analyst',
    es: 'Analista de medios sociales',
    de: 'Social-Media-Analyst',
    fr: 'Analyste des médias sociaux',
    pt: 'Analista de Mídias Sociais',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 608,
    id_parent: 21,
    it: 'Media Buyer',
    en: 'Media Buyer',
    es: 'Comprador de medios de comunicación',
    de: 'Medienkäufer',
    fr: 'Acheteur média',
    pt: 'Comprador de mídia',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 609,
    id_parent: 21,
    it: 'Assistente media',
    en: 'Media Assistant',
    es: 'Asistente de medios de comunicación',
    de: 'Medienassistent',
    fr: 'Assistante média',
    pt: 'Assistente de mídia',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 610,
    id_parent: 21,
    it: 'Manager SEO',
    en: 'SEO Manager',
    es: 'Director de SEO',
    de: 'SEO-Manager',
    fr: 'Gestionnaire de référencement',
    pt: 'Gerente de SEO',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 611,
    id_parent: 21,
    it: 'Product Marketing Manager',
    en: 'Product Marketing Manager',
    es: 'Director de marketing de productos',
    de: 'Produktmarketing-Manager',
    fr: 'Responsable Marketing Produit',
    pt: 'Gerente de Marketing de Produto',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 612,
    id_parent: 21,
    it: 'Media Planner',
    en: 'Media Planner',
    es: 'Planificador de medios',
    de: 'Medienplaner',
    fr: 'Planificateur média',
    pt: 'Planejador de mídia',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 613,
    id_parent: 21,
    it: 'Associato di marketing',
    en: 'Marketing Associate',
    es: 'Asociado de marketing',
    de: 'Marketingbeauftragter',
    fr: 'Associé marketing',
    pt: 'Associado de Marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 614,
    id_parent: 21,
    it: 'Ambasciatore del marchio',
    en: 'Brand Ambassador',
    es: 'Embajador de la marca',
    de: 'Markenbotschafter',
    fr: 'Ambassadeur de la marque',
    pt: 'Embaixador da marca',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 615,
    id_parent: 21,
    it: 'Direttore CRM',
    en: 'CRM Director',
    es: 'Director de CRM',
    de: 'CRM-Direktor',
    fr: 'Directeur CRM',
    pt: 'Diretor de CRM',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 616,
    id_parent: 21,
    it: 'Assistente Brand Manager',
    en: 'Assistant Brand Manager',
    es: 'Asistente del Director de Marca',
    de: 'Assistent des Markenmanagers',
    fr: 'Assistante chef de marque',
    pt: 'Assistente de gerente de marca',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 617,
    id_parent: 21,
    it: 'Coordinatore marketing',
    en: 'Marketing Coordinator',
    es: 'Coordinador de marketing',
    de: 'Verkaufs Koordinator',
    fr: 'Coordinatrice Marketing',
    pt: 'Coordenador de Marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 618,
    id_parent: 21,
    it: 'Community Manager',
    en: 'Community Manager',
    es: 'Gestor de la comunidad',
    de: 'Community Manager',
    fr: 'Gestionnaire de communauté',
    pt: 'Gerente de Comunidade',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 619,
    id_parent: 21,
    it: 'Manager dei social media',
    en: 'Social Media Manager',
    es: 'Gestor de redes sociales',
    de: 'Social-Media-Manager',
    fr: 'Gestionnaire des médias sociaux',
    pt: 'Gerente de mídia social',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 620,
    id_parent: 21,
    it: 'Stagista di marketing',
    en: 'Marketing Intern',
    es: 'Becario de marketing',
    de: 'Marketing-Praktikant',
    fr: 'Stagiaire en marketing',
    pt: 'Estagiário de Marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 621,
    id_parent: 21,
    it: 'Specialista dei media digitali',
    en: 'Digital Media Specialist',
    es: 'Especialista en medios digitales',
    de: 'Spezialist für digitale Medien',
    fr: 'Spécialiste des médias numériques',
    pt: 'Especialista em Mídias Digitais',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 622,
    id_parent: 21,
    it: 'Email Marketing Manager',
    en: 'Email Marketing Manager',
    es: 'Gestor de Email Marketing',
    de: 'E-Mail-Marketing-Manager',
    fr: 'Responsable marketing par e-mail',
    pt: 'Gerente de E-mail Marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 623,
    id_parent: 21,
    it: 'Responsabile dell\'account digitale',
    en: 'Digital Account Manager',
    es: 'Gestor de cuentas digitales',
    de: 'Digitaler Account-Manager',
    fr: 'Gestionnaire de compte numérique',
    pt: 'Gerente de contas digitais',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 624,
    id_parent: 21,
    it: 'Direttore Marketing Digitale',
    en: 'Digital Marketing Director',
    es: 'Director de Marketing Digital',
    de: 'Direktor für digitales Marketing',
    fr: 'Directeur Marketing Digital',
    pt: 'Diretor de Marketing Digital',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 625,
    id_parent: 21,
    it: 'Strategista di marketing',
    en: 'Marketing Strategist',
    es: 'Estratega de marketing',
    de: 'Marketingstratege',
    fr: 'Stratège Marketing',
    pt: 'Estrategista de Marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 626,
    id_parent: 21,
    it: 'Direttore Digitale',
    en: 'Digital Director',
    es: 'Director Digital',
    de: 'Digitaler Direktor',
    fr: 'Directeur numérique',
    pt: 'Diretor digital',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 627,
    id_parent: 21,
    it: 'Strategista dei social media',
    en: 'Social Media Strategist',
    es: 'Estratega de medios sociales',
    de: 'Social-Media-Stratege',
    fr: 'Stratège des médias sociaux',
    pt: 'Estrategista de Mídias Sociais',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 628,
    id_parent: 21,
    it: 'Responsabile del progetto digitale',
    en: 'Digital Project Manager',
    es: 'Gestor de proyectos digitales',
    de: 'Digitaler Projektmanager',
    fr: 'Chef de projet numérique',
    pt: 'Gerente de Projetos Digitais',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 629,
    id_parent: 21,
    it: 'Responsabile del marketing dei contenuti',
    en: 'Content Marketing Manager',
    es: 'Director de marketing de contenidos',
    de: 'Content-Marketing-Manager',
    fr: 'Responsable marketing de contenu',
    pt: 'Gerente de Marketing de Conteúdo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 630,
    id_parent: 21,
    it: 'Strategista di marketing digitale',
    en: 'Digital Marketing Strategist',
    es: 'Estratega de marketing digital',
    de: 'Stratege für digitales Marketing',
    fr: 'Stratège en marketing numérique',
    pt: 'Estrategista de Marketing Digital',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 631,
    id_parent: 21,
    it: 'Coordinatore dei social media',
    en: 'Social Media Coordinator',
    es: 'Coordinador de medios sociales',
    de: 'Koordinator für soziale Medien',
    fr: 'Coordonnatrice des médias sociaux',
    pt: 'Coordenador de Mídias Sociais',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 632,
    id_parent: 21,
    it: 'Manager dei contenuti',
    en: 'Content Manager',
    es: 'Gestor de contenidos',
    de: 'Content-Manager',
    fr: 'Gestionnaire de contenu',
    pt: 'Gestor de conteúdos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 633,
    id_parent: 21,
    it: 'Manager del marchio',
    en: 'Brand Manager',
    es: 'Director de marca',
    de: 'Brand Manager',
    fr: 'Chef de marque',
    pt: 'Gerente de marca',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 634,
    id_parent: 21,
    it: 'Account Executive pubblicitario',
    en: 'Advertising Account Executive',
    es: 'Ejecutivo de cuentas de publicidad',
    de: 'Werbekundenbetreuer',
    fr: 'Responsable de compte publicitaire',
    pt: 'Executivo de contas de publicidade',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 635,
    id_parent: 21,
    it: 'Direttore creativo',
    en: 'Creative Director',
    es: 'Director creativo',
    de: 'Kreativdirektor',
    fr: 'Directeur de création',
    pt: 'Diretor criativo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 636,
    id_parent: 21,
    it: 'Responsabile marketing',
    en: 'Chief Marketing Officer',
    es: 'Director de Marketing',
    de: 'Marketing-Chef',
    fr: 'Directeur du marketing',
    pt: 'Diretor de marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 637,
    id_parent: 21,
    it: 'Specialista di marketing',
    en: 'Marketing Specialist',
    es: 'Especialista en marketing',
    de: 'Marketing Spezialist',
    fr: 'Spécialiste en marketing',
    pt: 'Especialista em marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 638,
    id_parent: 21,
    it: 'Assistente marketing',
    en: 'Marketing Assistant',
    es: 'Asistente de marketing',
    de: 'Marketing-Assistentin',
    fr: 'Assistant Marketing',
    pt: 'Assistente de marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 639,
    id_parent: 21,
    it: 'Consulente di marketing',
    en: 'Marketing Consultant',
    es: 'Consultor de marketing',
    de: 'Marketing-Berater',
    fr: 'Consultant en marketing',
    pt: 'Consultor de marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 640,
    id_parent: 21,
    it: 'Direttore marketing',
    en: 'Marketing Director',
    es: 'Director de Marketing',
    de: 'Marketingleiter',
    fr: 'Directeur marketing',
    pt: 'Diretor de marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 641,
    id_parent: 21,
    it: 'Dirigente di marketing',
    en: 'Marketing Executive',
    es: 'Ejecutivo de marketing',
    de: 'Marketingleiter',
    fr: 'Responsable Marketing',
    pt: 'Executivo de Marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 642,
    id_parent: 21,
    it: 'Addetto al marketing',
    en: 'Marketing Officer',
    es: 'Oficial de marketing',
    de: 'Verkaufsleiter',
    fr: 'Agent de commercialisation',
    pt: 'Diretor de Marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 643,
    id_parent: 21,
    it: 'Responsabile marketing',
    en: 'Marketing Manager',
    es: 'Director de marketing',
    de: 'Marketing Manager',
    fr: 'Responsable Marketing',
    pt: 'Gerente de marketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 644,
    id_parent: 21,
    it: 'Analista di ricerche di mercato',
    en: 'Market Research Analyst',
    es: 'Analista de investigación de mercado',
    de: 'Marktforschungsanalyst',
    fr: 'Analyste de marché',
    pt: 'Analista de Pesquisa de Mercado',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 645,
    id_parent: 21,
    it: 'Promoter',
    en: 'Promoter',
    es: 'Promotor',
    de: 'Promoter',
    fr: 'Promoteur',
    pt: 'Promotor',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 646,
    id_parent: 21,
    it: 'Specialista dei social media',
    en: 'Social Media Specialist',
    es: 'Especialista en redes sociales',
    de: 'Social-Media-Spezialist',
    fr: 'Spécialiste des médias sociaux',
    pt: 'Especialista em Mídias Sociais',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 647,
    id_parent: 21,
    it: 'Direttore artistico',
    en: 'Art Director',
    es: 'Director de Arte',
    de: 'Künstlerischer Leiter',
    fr: 'Directeur artistique',
    pt: 'Diretor de arte',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 648,
    id_parent: 21,
    it: 'Responsabile marketing digitale',
    en: 'Digital Marketing Executive',
    es: 'Ejecutivo de marketing digital',
    de: 'Führungskraft für digitales Marketing',
    fr: 'Chargée de Marketing Digital',
    pt: 'Executivo de Marketing Digital',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 649,
    id_parent: 21,
    it: 'Responsabile marketing digitale',
    en: 'Digital Marketing Manager',
    es: 'Director de marketing digital',
    de: 'Manager für digitales Marketing',
    fr: 'Responsable marketing numérique',
    pt: 'Gerente de Marketing Digital',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 650,
    id_parent: 21,
    it: 'Specialista in marketing dei motori di ricerca (SEM)',
    en: 'Search Engine Marketing (SEM) Specialist',
    es: 'Especialista en marketing de motores de búsqueda (SEM)',
    de: 'Spezialist für Suchmaschinenmarketing (SEM).',
    fr: 'Spécialiste en marketing des moteurs de recherche (SEM)',
    pt: 'Especialista em Search Engine Marketing (SEM)',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 651,
    id_parent: 21,
    it: 'Specialista dell\'ottimizzazione dei motori di ricerca (SEO)',
    en: 'Search Engine Optimization (SEO) Specialist',
    es: 'Especialista en optimización de motores de búsqueda (SEO)',
    de: 'Spezialist für Suchmaschinenoptimierung (SEO).',
    fr: 'Spécialiste de l\'optimisation des moteurs de recherche (SEO)',
    pt: 'Especialista em Search Engine Optimization (SEO)',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 652,
    id_parent: 22,
    it: 'Direttore delle relazioni pubbliche (PR)',
    en: 'Public Relations (PR) Director',
    es: 'Director de Relaciones Públicas (PR)',
    de: 'Direktor für Öffentlichkeitsarbeit (PR).',
    fr: 'Directeur des relations publiques (RP)',
    pt: 'Diretor de Relações Públicas (RP)',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 653,
    id_parent: 22,
    it: 'Addetto alle pubbliche relazioni (PR)',
    en: 'Public Relations (PR) Officer',
    es: 'Oficial de Relaciones Públicas (PR)',
    de: 'Beauftragter für Öffentlichkeitsarbeit (PR).',
    fr: 'Chargé des relations publiques (RP)',
    pt: 'Diretor de Relações Públicas (RP)',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 654,
    id_parent: 22,
    it: 'Assistente alle relazioni pubbliche',
    en: 'Public Relations Assistant',
    es: 'Asistente de relaciones públicas',
    de: 'Assistentin für Öffentlichkeitsarbeit',
    fr: 'Assistante Relations Publiques',
    pt: 'Assistente de Relações Públicas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 655,
    id_parent: 22,
    it: 'Specialista in comunicazioni',
    en: 'Communications Specialist',
    es: 'Especialista en comunicaciones',
    de: 'Kommunikationsspezialist',
    fr: 'Spécialiste des communications',
    pt: 'Especialista em Comunicação',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 656,
    id_parent: 22,
    it: 'Dirigente PR',
    en: 'PR Executive',
    es: 'Ejecutivo de relaciones públicas',
    de: 'PR-Manager',
    fr: 'Responsable des relations publiques',
    pt: 'Executivo de relações públicas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 657,
    id_parent: 22,
    it: 'Stagista in Relazioni Pubbliche',
    en: 'Public Relations Intern',
    es: 'Practicante de relaciones públicas',
    de: 'PR-Praktikant',
    fr: 'Stagiaire en relations publiques',
    pt: 'Estagiária de Relações Públicas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 658,
    id_parent: 22,
    it: 'Specialista in Pubbliche Relazioni',
    en: 'Public Relations Specialist',
    es: 'Especialista en relaciones públicas',
    de: 'Spezialist für Öffentlichkeitsarbeit',
    fr: 'Spécialiste des relations publiques',
    pt: 'Especialista em Relações Públicas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 659,
    id_parent: 22,
    it: 'Responsabile Pubbliche Relazioni',
    en: 'Public Relations Manager',
    es: 'Director de Relaciones Públicas',
    de: 'Manager für Öffentlichkeitsarbeit',
    fr: 'Responsable des relations publiques',
    pt: 'Gerente de Relações Públicas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 660,
    id_parent: 23,
    it: 'Truccatore di film',
    en: 'Movie Makeup Artist',
    es: 'Maquillador de películas',
    de: 'Film Maskenbildner',
    fr: 'Artiste de maquillage de film',
    pt: 'Maquiador de filmes',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 661,
    id_parent: 23,
    it: 'Stilista per servizi fotografici',
    en: 'Photoshoot Stylist',
    es: 'Estilista de sesión de fotos',
    de: 'Fotoshooting-Stylistin',
    fr: 'Styliste de séance photo',
    pt: 'Estilista de sessão de fotos',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 662,
    id_parent: 23,
    it: 'Copywriter di moda',
    en: 'Fashion Copywriter',
    es: 'Redactor de moda',
    de: 'Mode-Werbetexter',
    fr: 'Rédactrice mode',
    pt: 'Redatora de moda',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 663,
    id_parent: 23,
    it: 'Scrittore di contenuti',
    en: 'Content Writer',
    es: 'Escritor de contenidos',
    de: 'Inhalts Autor',
    fr: 'Rédacteur de contenu',
    pt: 'Escritor de conteúdo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 664,
    id_parent: 23,
    it: 'Copywriter',
    en: 'Copywriter',
    es: 'Redactor de contenidos',
    de: 'Texter',
    fr: 'Rédacteur',
    pt: 'Copiadora',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 665,
    id_parent: 23,
    it: 'Editore digitale',
    en: 'Digital Editor',
    es: 'Editor digital',
    de: 'Digitaler Editor',
    fr: 'Éditeur numérique',
    pt: 'Editor digital',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 666,
    id_parent: 23,
    it: 'Scrittore personale',
    en: 'Staff Writer',
    es: 'Redactor de plantilla',
    de: 'Angestellter Autor',
    fr: 'Rédacteur personnel',
    pt: 'Funcionário escritor',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 667,
    id_parent: 23,
    it: 'Assistente redattore',
    en: 'Assistant Editor',
    es: 'Ayudante de redacción',
    de: 'Schnittassistenz',
    fr: 'Rédacteur adjoint',
    pt: 'Editor assistente',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 668,
    id_parent: 23,
    it: 'Produttore di notizie',
    en: 'News Producer',
    es: 'Productor de noticias',
    de: 'Nachrichtenproduzent',
    fr: 'Producteur de nouvelles',
    pt: 'Produtor de notícias',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 669,
    id_parent: 23,
    it: 'Web Editor',
    en: 'Web Editor',
    es: 'Editor web',
    de: 'Web-Editor',
    fr: 'Éditeur Web',
    pt: 'Editor da Web',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 670,
    id_parent: 23,
    it: 'Conduttore di notizie',
    en: 'News Anchor',
    es: 'Presentador de noticias',
    de: 'Nachrichtensprecher',
    fr: 'Présentateur de nouvelles',
    pt: 'Âncora de notícias',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 671,
    id_parent: 23,
    it: 'Direttore',
    en: 'Director',
    es: 'Director',
    de: 'Direktor',
    fr: 'Réalisateur',
    pt: 'Diretor',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 672,
    id_parent: 23,
    it: 'Analista politico',
    en: 'Political Analyst',
    es: 'Analista político',
    de: 'Politischer Analyst',
    fr: 'Analyste politique',
    pt: 'Analista Político',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 673,
    id_parent: 23,
    it: 'Redattore di moda',
    en: 'Fashion Editor',
    es: 'Editor de moda',
    de: 'Moderedakteurin',
    fr: 'Rédactrice de mode',
    pt: 'Editor de moda',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 674,
    id_parent: 23,
    it: 'Redattore foto',
    en: 'Photo Editor',
    es: 'Editor de fotografía',
    de: 'Foto Editor',
    fr: 'Éditeur de photos',
    pt: 'Editor de foto',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 675,
    id_parent: 23,
    it: 'Redattore capo',
    en: 'Managing Editor',
    es: 'Redactor jefe',
    de: 'Chefredakteur',
    fr: 'Rédacteur en chef',
    pt: 'Editor chefe',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 676,
    id_parent: 23,
    it: 'Ingegnere del suono',
    en: 'Sound Engineer',
    es: 'Ingeniero de sonido',
    de: 'Toningenieur',
    fr: 'Ingénieur du son',
    pt: 'Engenheiro de som',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 677,
    id_parent: 23,
    it: 'Giornalista',
    en: 'Journalist',
    es: 'Periodista',
    de: 'Journalist',
    fr: 'Journaliste',
    pt: 'Jornalista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 678,
    id_parent: 23,
    it: 'Fotografo',
    en: 'Photographer',
    es: 'Fotógrafo',
    de: 'Fotograf',
    fr: 'Photographe',
    pt: 'Fotógrafo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 679,
    id_parent: 23,
    it: 'Animatore',
    en: 'Animator',
    es: 'Animador',
    de: 'Animator',
    fr: 'Animateur',
    pt: 'Animador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 680,
    id_parent: 23,
    it: 'Costumista',
    en: 'Costume Designer',
    es: 'Diseñador de vestuario',
    de: 'Kostümbildnerin',
    fr: 'Costumière',
    pt: 'Figurinista',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 681,
    id_parent: 23,
    it: 'Direttore del casting',
    en: 'Casting Director',
    es: 'Director de casting',
    de: 'Casting-Direktor',
    fr: 'Directeur de casting',
    pt: 'Diretor de elenco',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 682,
    id_parent: 23,
    it: 'Assistente di produzione',
    en: 'Production Assistant',
    es: 'Asistente de producción',
    de: 'Produktionsassistent',
    fr: 'Assistant de production',
    pt: 'Assistente de produção',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 683,
    id_parent: 23,
    it: 'Truccatore',
    en: 'Makeup Artist',
    es: 'Maquillador',
    de: 'Maskenbildner',
    fr: 'Maquilleur',
    pt: 'Maquiador',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 684,
    id_parent: 23,
    it: 'Operatore di ripresa',
    en: 'Camera Operator',
    es: 'Operador de cámara',
    de: 'Kameramann',
    fr: 'Opérateur camera',
    pt: 'Operador de câmera',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 685,
    id_parent: 23,
    it: 'Montatore video',
    en: 'Video Editor',
    es: 'Editor de vídeo',
    de: 'Videobearbeiter',
    fr: 'Editeur de vidéo',
    pt: 'Editor de vídeo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 686,
    id_parent: 23,
    it: 'Montatore',
    en: 'Editor',
    es: 'Editor',
    de: 'Editor',
    fr: 'Éditeur',
    pt: 'editor',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 687,
    id_parent: 23,
    it: 'Reporter',
    en: 'Reporter',
    es: 'Reportero',
    de: 'Reporter',
    fr: 'Journaliste',
    pt: 'Repórter',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 688,
    id_parent: 24,
    it: 'Consulente per il leasing',
    en: 'Leasing Consultant',
    es: 'Consultor de arrendamiento',
    de: 'Leasingberater',
    fr: 'Conseiller en location',
    pt: 'Consultor de Leasing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 689,
    id_parent: 24,
    it: 'Broker immobiliare',
    en: 'Real Estate Broker',
    es: 'Agente inmobiliario',
    de: 'Immobilienmakler',
    fr: 'Courtier immobilier',
    pt: 'Corretor de imóveis',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 690,
    id_parent: 24,
    it: 'Agente di leasing',
    en: 'Leasing Agent',
    es: 'Agente de arrendamiento',
    de: 'Leasing-Agent',
    fr: 'Agent de location',
    pt: 'Agente de Leasing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 691,
    id_parent: 24,
    it: 'Responsabile della proprietà',
    en: 'Property Manager',
    es: 'Gestor inmobiliario',
    de: 'Hausverwaltung',
    fr: 'Gestionnaire de l\'immeuble',
    pt: 'Gerente proprietário',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 692,
    id_parent: 24,
    it: 'Valutatore immobiliare',
    en: 'Real Estate Appraiser',
    es: 'Tasador inmobiliario',
    de: 'Immobilien Gutachter',
    fr: 'Évaluateur immobilier',
    pt: 'Avaliador imobiliário',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 693,
    id_parent: 24,
    it: 'Agente immobiliare',
    en: 'Real Estate Agent',
    es: 'Agente inmobiliario',
    de: 'Immobilienmakler',
    fr: 'Agent immobilier',
    pt: 'Corretor de imóveis',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 694,
    id_parent: 25,
    it: 'Rappresentante delle vendite sul territorio',
    en: 'Territory Sales Representative',
    es: 'Representante de ventas territoriales',
    de: 'Gebietsverkaufsrepräsentant',
    fr: 'Représentant des ventes de territoire',
    pt: 'Representante de vendas do território',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 695,
    id_parent: 25,
    it: 'Addetto al conto',
    en: 'Account Officer',
    es: 'Responsable de cuentas',
    de: 'Kundenbetreuer',
    fr: 'Agent de compte',
    pt: 'Diretor de contas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 696,
    id_parent: 25,
    it: 'Responsabile delle relazioni con i clienti',
    en: 'Client Relations Manager',
    es: 'Director de relaciones con los clientes',
    de: 'Client-Relations-Manager',
    fr: 'Responsable Relation Clients',
    pt: 'Gerente de Relacionamento com o Cliente',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 697,
    id_parent: 25,
    it: 'Assistente Account Executive',
    en: 'Assistant Account Executive',
    es: 'Asistente del Ejecutivo de Cuentas',
    de: 'Assistent Account Executive',
    fr: 'Chargé de compte adjoint',
    pt: 'Executivo de contas assistente',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 698,
    id_parent: 25,
    it: 'Supervisore dell\'account',
    en: 'Account Supervisor',
    es: 'Supervisor de cuentas',
    de: 'Kontobetreuer',
    fr: 'Superviseur de compte',
    pt: 'Supervisor de contas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 699,
    id_parent: 25,
    it: 'Rappresentante dello sviluppo del business',
    en: 'Business Development Representative',
    es: 'Representante de desarrollo comercial',
    de: 'Vertreter der Wirtschaftsförderung',
    fr: 'Représentant du développement des affaires',
    pt: 'Representante de Desenvolvimento de Negócios',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 700,
    id_parent: 25,
    it: 'Coordinatore dell\'account',
    en: 'Account Coordinator',
    es: 'Coordinador de cuentas',
    de: 'Account-Koordinator',
    fr: 'Coordinateur de compte',
    pt: 'Coordenador de contas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 701,
    id_parent: 25,
    it: 'Manager del territorio',
    en: 'Territory Manager',
    es: 'Director de territorio',
    de: 'Gebietsmanager',
    fr: 'Manager de territoire',
    pt: 'gerente de território',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 702,
    id_parent: 25,
    it: 'Responsabile regionale delle vendite',
    en: 'Regional Sales Manager',
    es: 'Director regional de ventas',
    de: 'Regionaler Verkausmanager',
    fr: 'Responsable régional des ventes',
    pt: 'Gerente Regional de Vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 703,
    id_parent: 25,
    it: 'Rappresentante dell\'account',
    en: 'Account Representative',
    es: 'Representante de cuentas',
    de: 'Kundenberater',
    fr: 'Représentant de compte',
    pt: 'Conta representativa',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 704,
    id_parent: 25,
    it: 'Junior Account Manager',
    en: 'Junior Account Manager',
    es: 'Gestor de cuentas junior',
    de: 'Junior Account Manager',
    fr: 'Gestionnaire de compte junior',
    pt: 'Gerente de contas júnior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 705,
    id_parent: 25,
    it: 'Manager dell\'impegno',
    en: 'Engagement Manager',
    es: 'Gerente de compromiso',
    de: 'Engagement Manager',
    fr: 'Chef de recrutement',
    pt: 'Gerente de Engajamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 706,
    id_parent: 25,
    it: 'Manager delle relazioni',
    en: 'Relationship Manager',
    es: 'Gestor de relaciones',
    de: 'Beziehungsmanager',
    fr: 'Gestionnaire de relation',
    pt: 'Gerente de Relacionamento',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 707,
    id_parent: 25,
    it: 'Amministratore delle vendite',
    en: 'Sales Administrator',
    es: 'Administrador de ventas',
    de: 'verkaufsadministrator',
    fr: 'Administrateur des ventes',
    pt: 'Administrador de vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 708,
    id_parent: 25,
    it: 'Specialista del supporto alle vendite',
    en: 'Sales Support Specialist',
    es: 'Especialista en apoyo a las ventas',
    de: 'Spezialist für Vertriebsunterstützung',
    fr: 'Spécialiste de l\'assistance aux ventes',
    pt: 'Especialista em Suporte a Vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 709,
    id_parent: 25,
    it: 'Consulente delle vendite',
    en: 'Sales Consultant',
    es: 'Consultor de ventas',
    de: 'Verkaufsberater',
    fr: 'Conseiller des ventes',
    pt: 'Consultor de vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 710,
    id_parent: 25,
    it: 'Specialista della formazione delle vendite',
    en: 'Sales Training Specialist',
    es: 'Especialista en formación de ventas',
    de: 'Spezialist für Verkaufstraining',
    fr: 'Spécialiste de la formation commerciale',
    pt: 'Especialista em Treinamento de Vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 711,
    id_parent: 25,
    it: 'Responsabile del conto strategico',
    en: 'Strategic Account Manager',
    es: 'Gestor de cuentas estratégicas',
    de: 'Strategischer Account Manager',
    fr: 'Gestionnaire de compte stratégique',
    pt: 'Gerente de contas estratégicas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 712,
    id_parent: 25,
    it: 'Responsabile nazionale delle vendite',
    en: 'National Sales Manager',
    es: 'Director de ventas nacional',
    de: 'nationaler Verkaufsleiter',
    fr: 'Directeur national des ventes',
    pt: 'gerente de vendas nacionais',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 713,
    id_parent: 25,
    it: 'Account Executive Senior',
    en: 'Senior Account Executive',
    es: 'Ejecutivo de cuentas senior',
    de: 'Senior Account Executive',
    fr: 'Chargé de compte senior',
    pt: 'Executivo de contas sênior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 714,
    id_parent: 25,
    it: 'Responsabile delle vendite',
    en: 'Sales Account Executive',
    es: 'Ejecutivo de cuentas de ventas',
    de: 'Vertriebsleiter',
    fr: 'Chargé de compte commercial',
    pt: 'Executivo de contas de vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 715,
    id_parent: 25,
    it: 'Responsabile interno delle vendite',
    en: 'Inside Sales Manager',
    es: 'Director de ventas internas',
    de: 'Verkaufsleiter im Innendienst',
    fr: 'Responsable des ventes internes',
    pt: 'Gerente de vendas interno',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 716,
    id_parent: 25,
    it: 'Account Manager Nazionale',
    en: 'National Account Manager',
    es: 'Gestor de cuentas nacionales',
    de: 'Nationaler Account-Manager',
    fr: 'Gestionnaire de comptes nationaux',
    pt: 'Gerente Nacional de Contas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 717,
    id_parent: 25,
    it: 'Account manager senior',
    en: 'Senior Account Manager',
    es: 'Gestor de cuentas senior',
    de: 'Senior Account Manager',
    fr: 'Gestionnaire de comptes sénior',
    pt: 'Gerente de Contas Sênior',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 718,
    id_parent: 25,
    it: 'Responsabile del conto vendite',
    en: 'Sales Account Manager',
    es: 'Gestor de cuentas de ventas',
    de: 'Sales Account Manager',
    fr: 'Gestionnaire de compte de vente',
    pt: 'Gerente de contas de vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 719,
    id_parent: 25,
    it: 'Rappresentante delle vendite assicurative',
    en: 'Insurance Sales Representative',
    es: 'Representante de ventas de seguros',
    de: 'Versicherungsvertreter',
    fr: 'Représentant des ventes d\'assurance',
    pt: 'Representante de vendas de seguros',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 720,
    id_parent: 25,
    it: 'Account Executive',
    en: 'Account Executive',
    es: 'Ejecutivo de cuentas',
    de: 'Account Executive',
    fr: 'Compte executif',
    pt: 'Executivo de contas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 721,
    id_parent: 25,
    it: 'Direttore delle vendite',
    en: 'Sales Executive',
    es: 'Ejecutivo de ventas',
    de: 'Verkaufsleiter',
    fr: 'Directeur des ventes',
    pt: 'Executivo de vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 722,
    id_parent: 25,
    it: 'Direttore commerciale',
    en: 'Commercial Director',
    es: 'Director comercial',
    de: 'Kaufmännischer Leiter',
    fr: 'Directeur commercial',
    pt: 'Diretor comercial',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 723,
    id_parent: 25,
    it: 'Rappresentante Telesales',
    en: 'Telesales Representative',
    es: 'Representante de televentas',
    de: 'Telesales-Vertreter',
    fr: 'Représentant télévente',
    pt: 'Representante de televendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 724,
    id_parent: 25,
    it: 'Responsabile dello sviluppo del business',
    en: 'Business Development Manager',
    es: 'Director de Desarrollo Comercial',
    de: 'Geschäftsentwicklungsmanager',
    fr: 'Responsable du développement commercial',
    pt: 'Gerente de Desenvolvimento de Negócios',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 725,
    id_parent: 25,
    it: 'Telemarketer',
    en: 'Telemarketer',
    es: 'Telemarketer',
    de: 'Telemarketer',
    fr: 'Télévendeur',
    pt: 'Operador de telemarketing',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 726,
    id_parent: 25,
    it: 'Merchandiser visivo',
    en: 'Visual Merchandiser',
    es: 'Comerciante visual',
    de: 'Visual Merchandiser',
    fr: 'Marchandiseur visuel',
    pt: 'Anunciante visual',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 727,
    id_parent: 25,
    it: 'Coordinatore delle vendite',
    en: 'Sales Coordinator',
    es: 'Coordinador de ventas',
    de: 'Verkaufskoordinator',
    fr: 'coordonnateur des ventes',
    pt: 'Coordenador de Vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 728,
    id_parent: 25,
    it: 'Responsabile delle vendite',
    en: 'Sales Manager',
    es: 'Director de ventas',
    de: 'Verkaufsleiter',
    fr: 'Directeur commercial',
    pt: 'Gerente de vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 729,
    id_parent: 25,
    it: 'Assistente alle vendite',
    en: 'Sales Associate',
    es: 'Asociado de ventas',
    de: 'Verkaufsmitarbeiter',
    fr: 'Associé aux ventes',
    pt: 'Associado de vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 730,
    id_parent: 25,
    it: 'Assistente alle vendite',
    en: 'Sales Assistant',
    es: 'Asistente de ventas',
    de: 'Verkäufer',
    fr: 'Assistant des ventes',
    pt: 'Assistente de vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 731,
    id_parent: 25,
    it: 'Rappresentante delle vendite sul campo (vendite esterne)',
    en: 'Field Sales (Outside Sales) Representative',
    es: 'Representante de ventas de campo (ventas externas)',
    de: 'Außendienstmitarbeiter (Outside Sales).',
    fr: 'Représentant des ventes sur le terrain (ventes externes)',
    pt: 'Representante de vendas de campo (vendas externas)',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 732,
    id_parent: 25,
    it: 'Agente assicurativo',
    en: 'Insurance Agent',
    es: 'Agente de seguros',
    de: 'Versicherungsvertreter',
    fr: 'Agent d\'assurance',
    pt: 'Agente de seguros',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 733,
    id_parent: 25,
    it: 'Rappresentante delle vendite',
    en: 'Sales Representative',
    es: 'Representante de ventas',
    de: 'Außendienstmitarbeiter',
    fr: 'Commercial',
    pt: 'Representante de vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 734,
    id_parent: 25,
    it: 'Rappresentante delle vendite interne',
    en: 'Inside Sales Representative',
    es: 'Representante de ventas internas',
    de: 'Inside Sales Representative',
    fr: 'Représentant des ventes internes',
    pt: 'Representante de vendas interno',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 735,
    id_parent: 25,
    it: 'Key Account Manager',
    en: 'Key Account Manager',
    es: 'Gestor de cuentas clave',
    de: 'Großkundenbetreuer',
    fr: 'Responsable d\'Affaires',
    pt: 'Gerente de Contas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 736,
    id_parent: 25,
    it: 'Direttore dell\'account',
    en: 'Account Director',
    es: 'Director de cuentas',
    de: 'Kontodirektor',
    fr: 'Directeur de compte',
    pt: 'Diretor de contas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 737,
    id_parent: 25,
    it: 'Account Manager',
    en: 'Account Manager',
    es: 'Gestor de cuentas',
    de: 'Account Manager',
    fr: 'Gestionnaire de compte',
    pt: 'Gerente de contas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 738,
    id_parent: 25,
    it: 'Direttore delle vendite',
    en: 'Sales Director',
    es: 'Director de ventas',
    de: 'Verkaufsleiter',
    fr: 'Directeur des ventes',
    pt: 'Diretor de vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 739,
    id_parent: 25,
    it: 'Ingegnere delle vendite',
    en: 'Sales Engineer',
    es: 'Ingeniero de ventas',
    de: 'Verkaufsingenieur',
    fr: 'Ingénieur commercial',
    pt: 'Engenheiro de vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 740,
    id_parent: 26,
    it: 'Responsabile delle risorse umane al dettaglio',
    en: 'Retail HR Manager',
    es: 'Responsable de RRHH en el sector minorista',
    de: 'Personalleiter Einzelhandel',
    fr: 'Responsable RH Retail',
    pt: 'Gerente de RH Varejo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 741,
    id_parent: 26,
    it: 'Stilista abiti da sposa',
    en: 'Bridal Stylist',
    es: 'Estilista nupcial',
    de: 'Brautstylistin',
    fr: 'Styliste mariée',
    pt: 'Estilista de noivas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 742,
    id_parent: 26,
    it: 'Consulente di bellezza',
    en: 'Beauty Advisor',
    es: 'Asesor de belleza',
    de: 'Schönheitsberater',
    fr: 'Conseiller en beauté',
    pt: 'Consultor de beleza',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 743,
    id_parent: 26,
    it: 'Stilista al dettaglio',
    en: 'Retail Stylist',
    es: 'Estilista minorista',
    de: 'Einzelhandels-Stylist',
    fr: 'Styliste de détail',
    pt: 'Estilista de varejo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 744,
    id_parent: 26,
    it: 'Rappresentante delle vendite al dettaglio',
    en: 'Retail Sales Representative',
    es: 'Representante de ventas al por menor',
    de: 'Handelsvertreter im Einzelhandel',
    fr: 'Représentant des ventes au détail',
    pt: 'Representante de vendas no varejo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 745,
    id_parent: 26,
    it: 'Cassiere',
    en: 'Cashier',
    es: 'Cajero',
    de: 'Kassierer',
    fr: 'La caissière',
    pt: 'Caixa',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 746,
    id_parent: 26,
    it: 'Manager della vendita al dettaglio',
    en: 'Retail Manager',
    es: 'Gerente de ventas al por menor',
    de: 'Einzelhandelverkäufer',
    fr: 'Gestionnaire de détail',
    pt: 'Gerente de varejo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 747,
    id_parent: 26,
    it: 'Consulente delle vendite',
    en: 'Sales Advisor',
    es: 'Asesor de ventas',
    de: 'Verkaufsberater',
    fr: 'Conseiller de vente',
    pt: 'Consultor de vendas',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 748,
    id_parent: 26,
    it: 'Responsabile di zona',
    en: 'Area Manager',
    es: 'Gerente de área',
    de: 'Bereichsleiter',
    fr: 'Chef de secteur',
    pt: 'Gerente da área',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 749,
    id_parent: 26,
    it: 'Acquirente al dettaglio',
    en: 'Retail Buyer',
    es: 'Comprador minorista',
    de: 'Einzelhandel Käufer',
    fr: 'Acheteur au détail',
    pt: 'Comprador de varejo',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 750,
    id_parent: 26,
    it: 'Manager di categoria',
    en: 'Category Manager',
    es: 'Director de categoría',
    de: 'Kategoriemanager',
    fr: 'Gestionnaire de catégorie',
    pt: 'Gerente de categoria',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 751,
    id_parent: 26,
    it: 'Merchandiser',
    en: 'Merchandiser',
    es: 'Comerciante',
    de: 'Kaufmann',
    fr: 'Marchandiseur',
    pt: 'Comerciante',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  },
  {
    id: 752,
    id_parent: 26,
    it: 'Store Manager',
    en: 'Store Manager',
    es: 'Director de tienda',
    de: 'Store-Manager',
    fr: 'Gérant de magasin',
    pt: 'Gerente de loja',
    creation_date: '2022-08-26 15:27:17.951',
    update_date: '',
    active: true
  }
]
export function getMicrocategories (lang, idParent) {
  const filtred = []
  microcategories.forEach((m) => {
    if (m.active && ((m.id_parent === idParent) || idParent === 0)) {
      filtred.push({
        id: m.id,
        id_parent: m.id_parent,
        [lang]: m[lang],
        active: true
      })
    }
  })
  return filtred
}

export function formattedCategories (lang, macroList) {
  const formatted = []
  macroList.forEach((macro) => {
    const micro = getMicrocategories(lang, macro.id)
    formatted.push({
      macrocategory: macro[lang],
      macrocategoryId: macro.id,
      macroVertical: macro.id_verticals,
      microcategory: micro
    })
  })
  return formatted
}

export function microById(lang, ids) {
  const formatted = []
  microcategories.forEach((m) => {
    if (ids.includes(m.id.toString())) {
      formatted.push({
        id: m.id,
        id_parent: m.id_parent,
        [lang]: m[lang],
        active: true
      })
    }
  })
  return formatted
}
