export default {
  getPosts: state => state.posts,
  getCurrentPage: state => state.currentPage,
  getSearchTitle: state => state.search_title,
  getCity: state => state.city,
  getRadius: state => state.radius,
  getLat: state => state.lat,
  getLon: state => state.lon,
  getGoogleKey: state => state.googleKey,
  getOrpSuggestion: state => state.orpSuggestion,
  getOrpOutcome: state => state.orpOutcome,
  getRefresh: state => state.refresh,
  getVerticaliRadar: state => state.verticali
}
