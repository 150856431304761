const languagesLevel = [
  {
    id: 1,
    language: 'it',
    correlation_id: 1,
    description: 'A1 - Livello base',
    active: true
  },
  {
    id: 2,
    language: 'it',
    correlation_id: 2,
    description: 'A2 - Livello elementare',
    active: true
  },
  {
    id: 3,
    language: 'it',
    correlation_id: 3,
    description: 'B1 - Livello intermedio',
    active: true
  },
  {
    id: 4,
    language: 'it',
    correlation_id: 4,
    description: 'B2 - Livello intermedio superiore',
    active: true
  },
  {
    id: 5,
    language: 'it',
    correlation_id: 5,
    description: 'C1 - Livello Avanzato',
    active: true
  },
  {
    id: 6,
    language: 'it',
    correlation_id: 6,
    description: 'C2 - Livello esperto',
    active: true
  },
  {
    id: 7,
    language: 'en',
    correlation_id: 1,
    description: 'A1 - Basic Level',
    active: true
  },
  {
    id: 8,
    language: 'en',
    correlation_id: 2,
    description: 'A2 - Elementary level',
    active: true
  },
  {
    id: 9,
    language: 'en',
    correlation_id: 3,
    description: 'B1 - Intermediate level',
    active: true
  },
  {
    id: 10,
    language: 'en',
    correlation_id: 4,
    description: 'B2 - Upper intermediate level',
    active: true
  },
  {
    id: 11,
    language: 'en',
    correlation_id: 5,
    description: 'C1 - Advanced level',
    active: true
  },
  {
    id: 12,
    language: 'en',
    correlation_id: 6,
    description: 'C2 - Proficient level',
    active: true
  },
  {
    id: 13,
    language: 'es',
    correlation_id: 1,
    description: 'A1 - Nivel básico',
    active: true
  },
  {
    id: 14,
    language: 'es',
    correlation_id: 2,
    description: 'A2 - Nivel elemental',
    active: true
  },
  {
    id: 15,
    language: 'es',
    correlation_id: 3,
    description: 'B1 - Nivel intermedio',
    active: true
  },
  {
    id: 16,
    language: 'es',
    correlation_id: 4,
    description: 'B2 - Nivel intermedio alto',
    active: true
  },
  {
    id: 17,
    language: 'es',
    correlation_id: 5,
    description: 'C1 - Nivel avanzado',
    active: true
  },
  {
    id: 18,
    language: 'es',
    correlation_id: 6,
    description: 'C2 - Nivel experto',
    active: true
  },
  {
    id: 19,
    language: 'de',
    correlation_id: 1,
    description: 'A1 - Anfänger',
    active: true
  },
  {
    id: 20,
    language: 'de',
    correlation_id: 2,
    description: 'A2 - Grundlegende Kenntnisse',
    active: true
  },
  {
    id: 21,
    language: 'de',
    correlation_id: 3,
    description: 'B1 - Fortgeschrittene Sprachverwendung',
    active: true
  },
  {
    id: 22,
    language: 'de',
    correlation_id: 4,
    description: 'B2 - Selbstständige Sprachverwendung',
    active: true
  },
  {
    id: 23,
    language: 'de',
    correlation_id: 5,
    description: 'C1 - Fachkundige Sprachkenntnisse',
    active: true
  },
  {
    id: 24,
    language: 'de',
    correlation_id: 6,
    description: 'C2 - Annähernd muttersprachliche Kenntnisse',
    active: true
  },
  {
    id: 25,
    language: 'fr',
    correlation_id: 1,
    description: 'A1 - Utilisateur élémentaire',
    active: true
  },
  {
    id: 26,
    language: 'fr',
    correlation_id: 2,
    description: 'A2 - Utilisateur élémentaire',
    active: true
  },
  {
    id: 27,
    language: 'fr',
    correlation_id: 3,
    description: 'B1 - Utilisateur indépendant',
    active: true
  },
  {
    id: 28,
    language: 'fr',
    correlation_id: 4,
    description: 'B2 - Utilisateur indépendant',
    active: true
  },
  {
    id: 29,
    language: 'fr',
    correlation_id: 5,
    description: 'C1 - Utilisateur expérimenté',
    active: true
  },
  {
    id: 30,
    language: 'fr',
    correlation_id: 6,
    description: 'C2 - Utilisateur expérimenté',
    active: true
  },
  {
    id: 31,
    language: 'pt',
    correlation_id: 1,
    description: 'A1 - Nível básico',
    active: true
  },
  {
    id: 32,
    language: 'pt',
    correlation_id: 2,
    description: 'A2 - Nível elementar',
    active: true
  },
  {
    id: 33,
    language: 'pt',
    correlation_id: 3,
    description: 'B1 - Nível Intermediário',
    active: true
  },
  {
    id: 34,
    language: 'pt',
    correlation_id: 4,
    description: 'B2 - Nível intermediário superior',
    active: true
  },
  {
    id: 35,
    language: 'pt',
    correlation_id: 5,
    description: 'C1 - Nível avançado',
    active: true
  },
  {
    id: 36,
    language: 'pt',
    correlation_id: 6,
    description: 'C2 - Nível de especialista',
    active: true
  }
]
export function getLanguagesLevel (lang) {
  return languagesLevel.filter(e => e.active && e.language === lang)
}
