
import Vue from 'vue'
import lottie from 'lottie-web'
import { mapGetters } from 'vuex'
export default Vue.extend({
  props: {
    cid: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'generic'
    },
    mandatory: {
      type: Boolean,
      default: true
    },
    money: {
      type: Boolean,
      default: true
    },
    image: {
      type: String,
      default: ''
    },
    lottie: {
      type: Boolean,
      default: false
    },
    changecv: {
      type: Boolean,
      default: false
    },
    errorCoid: {
      type: Object,
      default () {
        return {}
      }
    },
    error: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      emailNotValid: false,
      orpName: '',
      orpEmail: '',
      orpMessage: '',
      messageVisible: false,
      checkStep: 0,
      tryToConfirmOrp: false,
      selectedCV: '',
      textString: '',
      cvs: [],
      cvFile: '',
      cvName: '',
      cvLastModified: '',
      complexErrorMessage: '',
      errorAlreadyApplied: false,
      errorCode: '',
      errorId: '',
      titolo: '',
      confirmDelete: '',
      rule1: false,
      rule2: false,
      sharedLink: '',
      orpError: false,
      orpErrorMessage: '',
      popupError: false,
      copied: false
    }
  },
  async fetch () {
    this.titolo = this.title
    if (this.type === 'cv' || this.type === 'cv-list') {
      await this.getCV()
    }
  },
  computed: {
    ...mapGetters({
      getPreviousLanguage: 'popup/getPreviousLanguage'
    }),
    isLoggedWithConsent () {
      return this.$auth?.loggedIn && this.$auth?.user?.p2p_agree
    }
  },
  watch: {
    visible() {
      this.messageVisible = false
      this.orpName = ''
      this.orpEmail = ''
      this.orpMessage = ''
      this.updateError()
      const gender = this.$auth?.user?.gender ? this.$auth.user.gender : 'm'
      const personality = this.$auth?.user?.personality_type
        ? this.$auth.user.personality_type
        : 'default'
      const skinTone = this.$auth?.user?.avatar_skin_tone
        ? this.$auth.user.avatar_skin_tone
        : 1

      if (this.visible && this.type === 'generic' && this.lottie) {
        lottie.destroy()
        let path = ''
        if (this.$auth?.loggedIn) {
          path =
            '/assets/images/animations/smart-cv/' +
            personality +
            '_' +
            gender +
            '_' +
            skinTone +
            '.json'
        } else {
          path = '../assets/images/animations/smart-cv/default_f_1.json'
        }
        lottie.loadAnimation({
          container: document.getElementById('bmpopup'),
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path
        })
      }
      if (this.visible && this.type === 'noInvite' && this.lottie) {
        lottie.destroy()
        let path = ''
        if (this.$auth?.loggedIn) {
          path =
            '/assets/images/animations/no-invite/' +
            personality +
            '_' +
            gender +
            '_' +
            skinTone +
            '.json'
        } else {
          path = '/assets/images/animations/no-invite/default_f_1.json'
        }
        lottie.loadAnimation({
          container: document.getElementById('bmnoinvite'),
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path
        })
      }
      if (this.type === 'deleteAccount') {
        lottie.destroy()

        lottie.loadAnimation({
          container: document.getElementById('bmdelete'),
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path: '/assets/images/animations/delete-account.json'
        })
      }
      if (this.type === 'orpSuccess') {
        lottie.destroy()

        const gender = this.$auth.user.gender ? this.$auth.user.gender : 'm'
        const personality = this.$auth.user.personality_type
          ? this.$auth.user.personality_type
          : 'default'
        const skinTone = this.$auth.user.avatar_skin_tone
          ? this.$auth.user.avatar_skin_tone
          : 1

        lottie.loadAnimation({
          container: document.getElementById('orpSuccess'),
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path:
            '/assets/images/animations/invite-ok/' +
            personality +
            '_' +
            gender +
            '_' +
            skinTone +
            '.json'
        })
      }
      if (this.visible && this.type === 'error' && this.lottie) {
        lottie.destroy()

        const path = '/assets/images/animations/preferences/errorIcon.json'

        lottie.loadAnimation({
          container: document.getElementById('bmerror'),
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path
        })
      }
      if (this.visible && this.type === 'reload' && this.lottie) {
        lottie.destroy()

        const path = '/assets/images/animations/preferences/globo-mappa.json'

        lottie.loadAnimation({
          container: document.getElementById('globo'),
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path
        })
      }
      if (this.visible && this.type === 'orpCheck') {
        this.checkStep = 1
      }
      if (!this.visible) {
        this.checkStep = 0
      }
    },
    checkStep() {
      switch (this.checkStep) {
      case 1:
        this.$emit('track', { name: 'info', stage: 'start', action: '' })
        break
      case 2:
        this.$emit('track', { name: 'info', stage: 'end', action: 'next' })
        this.$emit('track', { name: 'invito', stage: 'start', action: '' })
        break
      case 3:
        this.$emit('track', { name: 'terms', stage: 'start', action: '' })
        break
      default:
        break
      }
    }

  },
  mounted() {
    this.updateError()
  },
  methods: {
    backAndTrack(stage, action) {
      let name = ''
      switch (this.checkStep) {
      case 1:
        name = 'info'
        break
      case 2:
        name = 'invito'
        break
      case 3:
        name = 'terms'
        break
      default:
        break
      }

      this.$emit('track', { name, stage, action })
      this.hide()
    },
    async copyLink() {
      try {
        this.$nuxt.$loading?.start()
        if (!this.sharedLink) {
          const response = await this.$axios.post('/proxysbapi/auth/sharedlink', { cid: this.cid })
          this.sharedLink = response.data.sharedLink
        }
        if (navigator?.clipboard?.writeText !== undefined) {
          navigator.clipboard.writeText(this.sharedLink)
        } else {
          const tmpTextField = document.createElement('textarea')
          tmpTextField.textContent = this.sharedLink
          tmpTextField.setAttribute('style', 'position:absolute; right:200%;')
          document.body.appendChild(tmpTextField)
          tmpTextField.select()
          tmpTextField.setSelectionRange(0, 99999)
          document.execCommand('copy')
          tmpTextField.remove()
        }
        this.copied = true
        this.$emit('track', { name: 'invito', stage: 'end', action: 'copy_link' })
      } catch (error) {
        if (error.response) {
          this.$emit('showError', { response: error.response })
        }
        if (this.$auth.loggedIn) {
          this.$sentry.setTag('email', this.$auth.user.email)
        }
        this.$sentry.captureException(error)
      } finally {
        this.$nuxt.$loading?.finish()
        setTimeout(() => {
          this.copied = false
        }, 1500)
      }
    },
    validateEmail(email) {
      if (!email || email === '') { return true }
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    orpInvite() {
      this.emailNotValid = false
      this.tryToConfirmOrp = false
      if (this.validateEmail(this.orpEmail)) {
        if (this.orpName && this.orpEmail) {
          this.$emit('track', { name: 'invito', stage: 'end', action: 'next' })
          if (this.isLoggedWithConsent) {
            this.sendInvite()
          } else {
            this.checkStep++
          }
        } else {
          this.tryToConfirmOrp = true
        }
      } else {
        this.emailNotValid = true
        this.tryToConfirmOrp = true
      }
    },
    confirmCheckboxesOrp () {
      if (this.rule1 && this.rule2) {
        this.$emit('track', { name: 'terms', stage: 'end', action: 'next' })
        this.sendInvite()
      } else {
        this.tryToConfirmOrp = true
      }
    },
    sendInvite() {
      this.$emit('confirm-popup', {
        name: this.orpName,
        email: this.orpEmail,
        message: this.messageVisible ? this.orpMessage : ''
      })
    },
    deleteAccount () {
      // do stuff
      this.$auth.logout()
      // const updatedUser = { ...this.$auth.user }
      // updatedUser.cancellationRequested = true
      // this.$auth.setUser(updatedUser)
      this.hide()
    },
    updateError() {
      this.orpError = false
      if (
        (this.type === 'error' || this.type === 'noInvite') &&
        this.error?.data !== undefined
      ) {
        this.errorCode = this.error?.data?.messageCode
        this.errorId = this.error?.data?.messageId
        if (this.error.status === 401) {
          this.complexErrorMessage = this.$t('error.unauthorized')
          if (this.errorCode === 'unauthorized-not-logged-in') {
            this.complexErrorMessage += '<p>' + this.$t('error.unauthorized-not-logged') + '</p>'
          }
        } else if (this.error.status === 502) {
          this.complexErrorMessage = this.$t('error.badGateway')
        } else if (this.error.status === 404) {
          this.complexErrorMessage = this.$t('error.noPage')
        } else if (this.error.status === 406 && this.errorCode === 'EXC-000') {
          this.complexErrorMessage = this.$t('error.tryDifferentBrowser')
        } else if (this.errorCode !== '' && this.errorCode !== undefined) {
          switch (this.errorCode) {
          case 'P2P-001':
            this.errorAlreadyApplied = true
            this.complexErrorMessage = this.$t('error.alreadyApplied')
            this.titolo = this.$t('error.alreadyAppliedTitle')
            break
          case 'P2P-002':
            this.errorAlreadyApplied = true
            this.complexErrorMessage = this.$t('error.alreadyApplied')
            this.titolo = this.$t('error.alreadyAppliedTitle')
            break
          case 'P2P-004':
            this.complexErrorMessage = this.$t('error.atLeast50')
            break
          case 'P2P-005':
            this.complexErrorMessage = this.$t('error.noData')
            break
          case 'P2P-034':
            this.errorAlreadyApplied = true
            this.complexErrorMessage = this.$t('error.alreadyApplied')
            this.titolo = this.$t('error.alreadyAppliedTitle')
            this.orpError = true
            break
          case 'P2P-033':
            this.errorAlreadyApplied = true
            this.complexErrorMessage = this.$t('error.cannotInviteYourself').toString()
            this.titolo = this.$t('generic.attention').toString()
            this.orpError = true
            break
          case 'P2P-003':
            this.complexErrorMessage = this.$t('error.notEnoughCredit')
            break
          case 'WRONG_CF':
            this.complexErrorMessage = this.$t('settings.cfErrato')
            break
          case 'TOO_BIG':
            this.complexErrorMessage = this.$t('settings.tooBig')
            break
          case 'RAD-001':
            this.complexErrorMessage = this.$t('generic.badRequest')
            break
          default:
            this.complexErrorMessage = this.$t('error.generic')
          }
          if (
            this.type === 'error' &&
            this.errorId !== '' &&
            this.errorId !== undefined &&
            !this.errorAlreadyApplied
          ) {
            this.complexErrorMessage +=
              '<span class="error__id">(Error ID: ' + this.errorId + ')</span>'
          }
        } else if (this.error?.data.error !== undefined) {
          this.complexErrorMessage = this.error?.data.error
        }
      } else if (this.type === 'errorGoogle') {
        this.complexErrorMessage = this.error.errGoogle
      } else {
        this.complexErrorMessage = this.text ? this.text : this.$t('error.generic')// Vecchia gestione errori come fallback
      }
    },
    async getCV () {
      const params = {
        uid: this.$auth?.user?.uid
      }
      const headers = {
        Authorization: this.$auth?.strategy?.token?.get()
      }
      try {
        const cvs = await this.$axios.$get(
          '/proxystorage/auth/user-files',
          params,
          headers
        )
        if (cvs.objects !== null) {
          this.cvs = cvs.objects.sort((a, b) =>
            a.lastModified > b.lastModified ? -1 : 1
          )
        }
      } catch ({ response }) {
        // console.log('fail', response)
      }
      this.$nuxt.$loading?.finish()
      this.$nuxt.$loading?.finish()
    },
    async saveCV () {
      this.$nuxt.$loading?.start()
      const params = new FormData()
      params.append('uid', this.$auth?.user?.uid)
      params.append('file', this.cvFile)
      const headers = {
        Authorization: this.$auth?.strategy?.token.get()
      }

      await this.$axios
        .$post('/proxystorage/auth/user-files', params, headers)
        .then(() => {
          setTimeout(() => {
            this.getCV()
          }, 1000)
        })
        .catch((error) => {
          if (this.$auth.loggedIn) {
            this.$sentry.setTag('email', this.$auth.user.email)
          }
          this.$sentry.captureException(error)
          this.$nuxt.$loading?.finish()
        })
      // this.$emit('save-cv', this.textString)

      /* const newCV = {
        name: 'users_files/' + this.$auth.user.uid + '/' + this.cvName,
        lastModified: this.cvLastModified
      }
      this.cvs.unshift(newCV)
      */
    },
    deleteCV (filename, index) {
      this.$nuxt.$loading?.start()
      const params = {
        name: filename
      }
      const headers = {
        Authorization: this.$auth?.strategy?.token.get()
      }
      try {
        this.$axios.$delete(
          '/proxystorage/auth/user-files',
          { data: params },
          headers
        )
      } catch ({ response }) {
        // console.log('fail', response)
      }
      this.$nuxt.$loading?.finish()
      // this.$emit('save-cv', this.textString)
      this.cvs.splice(index, 1)
    },
    async saveCampaignCV () {
      this.$nuxt.$loading?.start()
      const params = {
        cid: parseInt(this.$route.params.id),
        name: this.selectedCV
      }
      const headers = {
        Authorization: this.$auth?.strategy?.token.get()
      }
      try {
        if (this.changecv) {
          await this.$axios.$put(
            '/proxysbapi/auth/user/application/uploadcv',
            params,
            headers
          )
        } else {
          await this.$axios.$post(
            '/proxystorage/auth/campaign-user-files',
            params,
            headers
          )
        }
        this.$emit('save-cv', { textString: this.textString, changecv: this.changecv })
      } catch ({ response }) {
        // console.log('fail', response)
      }
      this.$nuxt.$loading?.finish()
    },
    skipStep () {
      this.$emit('save-cv', { textString: 'skipped' })
    },
    hide() {
      this.messageVisible = false
      this.rule1 = false
      this.rule2 = false
      this.tryToConfirmOrp = false
      this.orpEmail = ''
      this.orpName = ''
      this.orpMessage = ''
      this.orpCheck = 1
      this.$emit('hide-popup')
    },
    reloadPopup() {
      this.$emit('reload-popup')
    },
    redirectToTest () {
      this.$router.push(this.localePath('/user/test'))
    },
    redirectToTalentWeek () {
      console.log('redirect')
    },
    uploadCV (event) {
      const file = event.target.files[0]
      this.cvFile = file
      this.cvName = file.name
      this.cvLastModified = file.lastModified
      this.saveCV()
    },
    confirmRedeem () {
      this.$emit('continue')
    }
  }
})
