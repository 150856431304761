import dayjs from 'dayjs'

import 'dayjs/locale/en'
import 'dayjs/locale/it'
import 'dayjs/locale/fr'
import 'dayjs/locale/es'
import 'dayjs/locale/pt'
import 'dayjs/locale/de'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isToday from 'dayjs/plugin/isToday'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(isToday)
dayjs.extend(isSameOrAfter)

dayjs.locale('it')

dayjs.tz.setDefault('Europe/Rome')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
