const contracts = [
  {
    id: 1,
    language: 'it',
    correlation_id: 0,
    description: 'Non specificato',
    active: true
  },
  {
    id: 2,
    language: 'it',
    correlation_id: 1,
    description: 'Tempo indeterminato',
    active: true
  },
  {
    id: 3,
    language: 'it',
    correlation_id: 2,
    description: 'Tempo determinato',
    active: true
  },
  {
    id: 4,
    language: 'it',
    correlation_id: 3,
    description: 'Somministrazione',
    active: true
  },
  {
    id: 5,
    language: 'it',
    correlation_id: 4,
    description: 'A chiamata',
    active: false
  },
  {
    id: 6,
    language: 'it',
    correlation_id: 5,
    description: 'Apprendistato',
    active: true
  },
  {
    id: 7,
    language: 'it',
    correlation_id: 6,
    description: 'Part-time',
    active: false
  },
  {
    id: 8,
    language: 'it',
    correlation_id: 7,
    description: 'Collaborazione',
    active: true
  },
  {
    id: 9,
    language: 'it',
    correlation_id: 8,
    description: 'Tirocinio formativo/Stage',
    active: true
  },
  {
    id: 10,
    language: 'it',
    correlation_id: 9,
    description: 'P. IVA',
    active: true
  },
  {
    id: 11,
    language: 'en',
    correlation_id: 0,
    description: 'Not specified',
    active: true
  },
  {
    id: 12,
    language: 'en',
    correlation_id: 1,
    description: 'Permanent contract',
    active: true
  },
  {
    id: 13,
    language: 'en',
    correlation_id: 2,
    description: 'Fixed-term contract',
    active: true
  },
  {
    id: 14,
    language: 'en',
    correlation_id: 3,
    description: 'Outsourcing',
    active: true
  },
  {
    id: 15,
    language: 'en',
    correlation_id: 4,
    description: 'Collaboration',
    active: false
  },
  {
    id: 16,
    language: 'en',
    correlation_id: 5,
    description: 'Apprenticeship',
    active: true
  },
  {
    id: 17,
    language: 'en',
    correlation_id: 6,
    description: 'Part-time',
    active: false
  },
  {
    id: 18,
    language: 'en',
    correlation_id: 7,
    description: 'Collaboration',
    active: true
  },
  {
    id: 19,
    language: 'en',
    correlation_id: 8,
    description: 'Internship',
    active: true
  },
  {
    id: 20,
    language: 'en',
    correlation_id: 9,
    description: 'Self-employed',
    active: true
  },
  {
    id: 21,
    language: 'es',
    correlation_id: 0,
    description: 'No se especifica',
    active: true
  },
  {
    id: 22,
    language: 'es',
    correlation_id: 1,
    description: 'Contrato indefinido',
    active: true
  },
  {
    id: 23,
    language: 'es',
    correlation_id: 2,
    description: 'Contrato de duración determinada',
    active: true
  },
  {
    id: 24,
    language: 'es',
    correlation_id: 3,
    description: 'Contrato de suministro',
    active: true
  },
  {
    id: 25,
    language: 'es',
    correlation_id: 4,
    description: 'Colaboración',
    active: false
  },
  {
    id: 26,
    language: 'es',
    correlation_id: 5,
    description: 'Aprendizaje',
    active: true
  },
  {
    id: 27,
    language: 'es',
    correlation_id: 6,
    description: 'Part-time',
    active: false
  },
  {
    id: 28,
    language: 'es',
    correlation_id: 7,
    description: 'Colaboración',
    active: true
  },
  {
    id: 29,
    language: 'es',
    correlation_id: 8,
    description: 'Prácticas',
    active: true
  },
  {
    id: 30,
    language: 'es',
    correlation_id: 9,
    description: 'Auto-emprego',
    active: true
  },
  {
    id: 31,
    language: 'de',
    correlation_id: 0,
    description: 'Nicht angegeben',
    active: true
  },
  {
    id: 32,
    language: 'de',
    correlation_id: 1,
    description: 'Unbefristeter Vertrag',
    active: true
  },
  {
    id: 33,
    language: 'de',
    correlation_id: 2,
    description: 'Befristeter Vertrag',
    active: true
  },
  {
    id: 34,
    language: 'de',
    correlation_id: 3,
    description: 'Verwaltungsvertrag',
    active: true
  },
  {
    id: 35,
    language: 'de',
    correlation_id: 4,
    description: 'Zusammenarbeit',
    active: false
  },
  {
    id: 36,
    language: 'de',
    correlation_id: 5,
    description: 'Ausbildung',
    active: true
  },
  {
    id: 37,
    language: 'de',
    correlation_id: 6,
    description: 'Part-time',
    active: false
  },
  {
    id: 38,
    language: 'de',
    correlation_id: 7,
    description: 'Zusammenarbeit',
    active: true
  },
  {
    id: 39,
    language: 'de',
    correlation_id: 8,
    description: 'Praktikum',
    active: true
  },
  {
    id: 40,
    language: 'de',
    correlation_id: 9,
    description: 'Minijob',
    active: true
  },
  {
    id: 41,
    language: 'fr',
    correlation_id: 0,
    description: 'Non spécifié',
    active: true
  },
  {
    id: 42,
    language: 'fr',
    correlation_id: 1,
    description: 'Contrat à durée indéterminée',
    active: true
  },
  {
    id: 43,
    language: 'fr',
    correlation_id: 2,
    description: 'Contrat à durée déterminée',
    active: true
  },
  {
    id: 44,
    language: 'fr',
    correlation_id: 3,
    description: 'Contrat de gestion',
    active: true
  },
  {
    id: 45,
    language: 'fr',
    correlation_id: 4,
    description: 'Collaboration',
    active: false
  },
  {
    id: 46,
    language: 'fr',
    correlation_id: 5,
    description: 'Apprentissage',
    active: true
  },
  {
    id: 47,
    language: 'fr',
    correlation_id: 6,
    description: 'Part-time',
    active: false
  },
  {
    id: 48,
    language: 'fr',
    correlation_id: 7,
    description: 'Collaboration',
    active: true
  },
  {
    id: 49,
    language: 'fr',
    correlation_id: 8,
    description: 'Apprentissage ou stage',
    active: true
  },
  {
    id: 50,
    language: 'fr',
    correlation_id: 9,
    description: 'Selbstständigkeit',
    active: true
  },
  {
    id: 51,
    language: 'pt',
    correlation_id: 0,
    description: 'Não especificado',
    active: true
  },
  {
    id: 52,
    language: 'pt',
    correlation_id: 1,
    description: 'Contrato sem termo',
    active: true
  },
  {
    id: 53,
    language: 'pt',
    correlation_id: 2,
    description: 'Contrato a prazo fixo',
    active: true
  },
  {
    id: 54,
    language: 'pt',
    correlation_id: 3,
    description: 'Contrato de administração',
    active: true
  },
  {
    id: 55,
    language: 'pt',
    correlation_id: 4,
    description: 'Colaboração',
    active: false
  },
  {
    id: 56,
    language: 'pt',
    correlation_id: 5,
    description: 'Estágio',
    active: true
  },
  {
    id: 57,
    language: 'pt',
    correlation_id: 6,
    description: 'Part-time',
    active: false
  },
  {
    id: 58,
    language: 'pt',
    correlation_id: 7,
    description: 'Colaboração',
    active: true
  },
  {
    id: 59,
    language: 'pt',
    correlation_id: 8,
    description: 'Estágio ou estágio',
    active: true
  },
  {
    id: 60,
    language: 'pt',
    correlation_id: 9,
    description: 'auto-emprego',
    active: true
  }
]
export function getContracts (lang) {
  return contracts.filter(e => e.active && e.language === lang)
}
