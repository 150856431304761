const remote = [
  {
    id: 1,
    language: 'it',
    correlation_id: 0,
    description: 'Non specificata',
    active: true
  },
  {
    id: 2,
    language: 'it',
    correlation_id: 1,
    description: 'Full Remote',
    active: true
  },
  {
    id: 3,
    language: 'it',
    correlation_id: 2,
    description: 'Parziale',
    active: true
  },
  {
    id: 4,
    language: 'it',
    correlation_id: 3,
    description: 'No',
    active: true
  },
  {
    id: 5,
    language: 'en',
    correlation_id: 0,
    description: 'Not specified',
    active: true
  },
  {
    id: 6,
    language: 'en',
    correlation_id: 1,
    description: 'Full Remote',
    active: true
  },
  {
    id: 7,
    language: 'en',
    correlation_id: 2,
    description: 'Partial',
    active: true
  },
  {
    id: 8,
    language: 'en',
    correlation_id: 3,
    description: 'No',
    active: true
  },
  {
    id: 9,
    language: 'es',
    correlation_id: 0,
    description: 'No se especifica',
    active: true
  },
  {
    id: 10,
    language: 'es',
    correlation_id: 1,
    description: 'Full Remote',
    active: true
  },
  {
    id: 11,
    language: 'es',
    correlation_id: 2,
    description: 'Parcialmente',
    active: true
  },
  {
    id: 12,
    language: 'es',
    correlation_id: 3,
    description: 'No',
    active: true
  },
  {
    id: 13,
    language: 'fr',
    correlation_id: 0,
    description: 'Non spécifié',
    active: true
  },
  {
    id: 14,
    language: 'fr',
    correlation_id: 1,
    description: 'Full Remote',
    active: true
  },
  {
    id: 15,
    language: 'fr',
    correlation_id: 2,
    description: 'Partiel',
    active: true
  },
  {
    id: 16,
    language: 'fr',
    correlation_id: 3,
    description: 'Non',
    active: true
  },
  {
    id: 17,
    language: 'de',
    correlation_id: 0,
    description: 'Nicht angegeben',
    active: true
  },
  {
    id: 18,
    language: 'de',
    correlation_id: 1,
    description: 'Full Remote',
    active: true
  },
  {
    id: 19,
    language: 'de',
    correlation_id: 2,
    description: 'Teilweise',
    active: true
  },
  {
    id: 20,
    language: 'de',
    correlation_id: 3,
    description: 'Nein',
    active: true
  },
  {
    id: 21,
    language: 'pt',
    correlation_id: 0,
    description: 'Não especificado',
    active: true
  },
  {
    id: 22,
    language: 'pt',
    correlation_id: 1,
    description: 'Full Remote',
    active: true
  },
  {
    id: 23,
    language: 'pt',
    correlation_id: 2,
    description: 'Parcial',
    active: true
  },
  {
    id: 24,
    language: 'pt',
    correlation_id: 3,
    description: 'Não',
    active: true
  }
]
export function getRemote (lang) {
  return remote.filter(e => e.active && e.language === lang)
}
