var render = function render(){
  var _vm$$auth$user;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "header",
    class: {
      'header--off': (_vm$$auth$user = _vm.$auth.user) === null || _vm$$auth$user === void 0 ? void 0 : _vm$$auth$user.is_first_login
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('nuxt-link', {
    staticClass: "header__logo click_logo",
    attrs: {
      "to": _vm.localePath('index')
    }
  }, [_c('span', {
    on: {
      "click": function ($event) {
        return _vm.trackMenu('CVing logo', 'image');
      }
    }
  }, [_c('img', {
    attrs: {
      "loading": "lazy",
      "src": require("assets/images/logo.svg"),
      "width": "80",
      "height": "28",
      "title": "CVing Logo",
      "alt": "CVing Logo"
    }
  })])]), _vm._v(" "), _c('nav', {
    staticClass: "navigation"
  }, [_c('Menu'), _vm._v(" "), _c('client-only', [_vm.$auth.loggedIn && _vm.$config.p2p ? _c('nuxt-link', {
    staticClass: "navigation__profile--start navigation__profile--redeem",
    attrs: {
      "to": _vm.localePath('user-settings-redeem')
    }
  }, [_c('span', {
    on: {
      "click": function ($event) {
        return _vm.trackMenu('Profile redeem', 'image');
      }
    }
  }, [_vm._v("\n            € " + _vm._s(new Intl.NumberFormat(_vm.$i18n.locale, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }).format(_vm.$auth.user.balance)) + "\n          ")])]) : _vm._e(), _vm._v(" "), _vm.$auth.loggedIn ? _c('nuxt-link', {
    staticClass: "navigation__profile click_notifiche",
    class: {
      'navigation__profile--start': !_vm.$config.p2p
    },
    attrs: {
      "to": _vm.localePath('user-notifications-applications'),
      "no-prefetch": ""
    }
  }, [_c('span', {
    on: {
      "click": function ($event) {
        _vm.trackMenu(_vm.$t('menu.notifications'), 'button');
      }
    }
  }, [_c('fa', {
    attrs: {
      "icon": ['fas', 'bell'],
      "title": _vm.$t('menu.notifications')
    }
  }), _vm._v(" "), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.getCount > 0,
      expression: "getCount > 0"
    }],
    staticClass: "navigation__notifications",
    domProps: {
      "textContent": _vm._s(_vm.getCount)
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _vm.$auth.loggedIn ? _c('nuxt-link', {
    attrs: {
      "to": _vm.localePath(_vm.profileLink)
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.track();
      }
    }
  }, [_c('span', {
    on: {
      "click": function ($event) {
        return _vm.trackMenu('Avatar', 'image');
      }
    }
  }, [_c('img', {
    staticClass: "navigation__avatar",
    attrs: {
      "src": _vm.$auth.user.avatar_url,
      "width": "30",
      "height": "30",
      "alt": _vm.$t('settings.editProfile'),
      "loading": "lazy"
    }
  })])]) : _vm._e(), _vm._v(" "), !_vm.$auth.loggedIn ? _c('nuxt-link', {
    staticClass: "button button--shortest navigation__profile--start navigation__signup",
    attrs: {
      "to": _vm.localePath('signup')
    }
  }, [_c('span', {
    on: {
      "click": function ($event) {
        _vm.trackMenu(_vm.$t('menu.signup'), 'button');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('menu.signup')))])]) : _vm._e(), _vm._v(" "), !_vm.$auth.loggedIn ? _c('nuxt-link', {
    attrs: {
      "to": _vm.localePath('login')
    }
  }, [_c('span', {
    on: {
      "click": function ($event) {
        _vm.trackMenu(_vm.$t('menu.login'), 'button');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('menu.login')))])]) : _vm._e()], 1), _vm._v(" "), !_vm.$auth.loggedIn ? _c('span', {
    staticClass: "navigation__external",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": _vm.redirectToPlatform
    }
  }, [_c('span', {
    on: {
      "click": function ($event) {
        _vm.trackMenu(_vm.$t('menu.forCompanies'), 'button');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('menu.forCompanies')) + " ")])]) : _vm._e()], 1)], 1), _vm._v(" "), _c('Popup', {
    attrs: {
      "title": _vm.$t('generic.attention'),
      "error": _vm.error,
      "type": 'error',
      "visible": _vm.popupError
    },
    on: {
      "hide-popup": function ($event) {
        return _vm.hidePopup();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }