const languages = [
  {
    id: 1,
    language: 'it',
    correlation_id: 1,
    description: 'Italiano',
    active: true
  },
  {
    id: 2,
    language: 'it',
    correlation_id: 2,
    description: 'Cinese (semplificato)',
    active: true
  },
  {
    id: 3,
    language: 'it',
    correlation_id: 3,
    description: 'Francese',
    active: true
  },
  {
    id: 4,
    language: 'it',
    correlation_id: 4,
    description: 'Inglese',
    active: true
  },
  {
    id: 5,
    language: 'it',
    correlation_id: 5,
    description: 'Portoghese',
    active: true
  },
  {
    id: 6,
    language: 'it',
    correlation_id: 6,
    description: 'Russo',
    active: true
  },
  {
    id: 7,
    language: 'it',
    correlation_id: 7,
    description: 'Spagnolo',
    active: true
  },
  {
    id: 8,
    language: 'it',
    correlation_id: 8,
    description: 'Tedesco',
    active: true
  },
  {
    id: 9,
    language: 'it',
    correlation_id: 9,
    description: 'Arabo',
    active: true
  },
  {
    id: 10,
    language: 'it',
    correlation_id: 10,
    description: 'Afrikaans',
    active: true
  },
  {
    id: 11,
    language: 'it',
    correlation_id: 11,
    description: 'Albanese',
    active: true
  },
  {
    id: 12,
    language: 'it',
    correlation_id: 12,
    description: 'Amarico',
    active: true
  },
  {
    id: 13,
    language: 'it',
    correlation_id: 13,
    description: 'Armeno',
    active: true
  },
  {
    id: 14,
    language: 'it',
    correlation_id: 14,
    description: 'Azerbaigiano',
    active: true
  },
  {
    id: 15,
    language: 'it',
    correlation_id: 15,
    description: 'Basco',
    active: true
  },
  {
    id: 16,
    language: 'it',
    correlation_id: 16,
    description: 'Bengalese',
    active: true
  },
  {
    id: 17,
    language: 'it',
    correlation_id: 17,
    description: 'Bielorusso',
    active: true
  },
  {
    id: 18,
    language: 'it',
    correlation_id: 18,
    description: 'Birmano',
    active: true
  },
  {
    id: 19,
    language: 'it',
    correlation_id: 19,
    description: 'Bosniaco',
    active: true
  },
  {
    id: 20,
    language: 'it',
    correlation_id: 20,
    description: 'Bulgaro',
    active: true
  },
  {
    id: 21,
    language: 'it',
    correlation_id: 21,
    description: 'Catalano',
    active: true
  },
  {
    id: 22,
    language: 'it',
    correlation_id: 22,
    description: 'Cebuano',
    active: true
  },
  {
    id: 23,
    language: 'it',
    correlation_id: 23,
    description: 'Ceco',
    active: true
  },
  {
    id: 24,
    language: 'it',
    correlation_id: 24,
    description: 'Cinese (tradizionale)',
    active: true
  },
  {
    id: 25,
    language: 'it',
    correlation_id: 25,
    description: 'Coreano',
    active: true
  },
  {
    id: 26,
    language: 'it',
    correlation_id: 26,
    description: 'Corso',
    active: true
  },
  {
    id: 27,
    language: 'it',
    correlation_id: 27,
    description: 'Creolo haitiano',
    active: true
  },
  {
    id: 28,
    language: 'it',
    correlation_id: 28,
    description: 'Croato',
    active: true
  },
  {
    id: 29,
    language: 'it',
    correlation_id: 29,
    description: 'Curdo',
    active: true
  },
  {
    id: 30,
    language: 'it',
    correlation_id: 30,
    description: 'Danese',
    active: true
  },
  {
    id: 31,
    language: 'it',
    correlation_id: 31,
    description: 'Ebraico',
    active: true
  },
  {
    id: 32,
    language: 'it',
    correlation_id: 32,
    description: 'Esperanto',
    active: true
  },
  {
    id: 33,
    language: 'it',
    correlation_id: 33,
    description: 'Estone',
    active: true
  },
  {
    id: 34,
    language: 'it',
    correlation_id: 34,
    description: 'Filippino',
    active: true
  },
  {
    id: 35,
    language: 'it',
    correlation_id: 35,
    description: 'Finlandese',
    active: true
  },
  {
    id: 36,
    language: 'it',
    correlation_id: 36,
    description: 'Frisone occidentale',
    active: true
  },
  {
    id: 37,
    language: 'it',
    correlation_id: 37,
    description: 'Gaelico scozzese',
    active: true
  },
  {
    id: 38,
    language: 'it',
    correlation_id: 38,
    description: 'Galiziano',
    active: true
  },
  {
    id: 39,
    language: 'it',
    correlation_id: 39,
    description: 'Gallese',
    active: true
  },
  {
    id: 40,
    language: 'it',
    correlation_id: 40,
    description: 'Georgiano',
    active: true
  },
  {
    id: 41,
    language: 'it',
    correlation_id: 41,
    description: 'Giapponese',
    active: true
  },
  {
    id: 42,
    language: 'it',
    correlation_id: 42,
    description: 'Giavanese',
    active: true
  },
  {
    id: 43,
    language: 'it',
    correlation_id: 43,
    description: 'Greco',
    active: true
  },
  {
    id: 44,
    language: 'it',
    correlation_id: 44,
    description: 'Gujarati',
    active: true
  },
  {
    id: 45,
    language: 'it',
    correlation_id: 45,
    description: 'Hausa',
    active: true
  },
  {
    id: 46,
    language: 'it',
    correlation_id: 46,
    description: 'Hawaiano',
    active: true
  },
  {
    id: 47,
    language: 'it',
    correlation_id: 47,
    description: 'Hindi',
    active: true
  },
  {
    id: 48,
    language: 'it',
    correlation_id: 48,
    description: 'Hmong',
    active: true
  },
  {
    id: 49,
    language: 'it',
    correlation_id: 49,
    description: 'Igbo',
    active: true
  },
  {
    id: 50,
    language: 'it',
    correlation_id: 50,
    description: 'Indonesiano',
    active: true
  },
  {
    id: 51,
    language: 'it',
    correlation_id: 51,
    description: 'Irlandese',
    active: true
  },
  {
    id: 52,
    language: 'it',
    correlation_id: 52,
    description: 'Islandese',
    active: true
  },
  {
    id: 53,
    language: 'it',
    correlation_id: 53,
    description: 'Kannada',
    active: true
  },
  {
    id: 54,
    language: 'it',
    correlation_id: 54,
    description: 'Kazako',
    active: true
  },
  {
    id: 55,
    language: 'it',
    correlation_id: 55,
    description: 'Khmer',
    active: true
  },
  {
    id: 56,
    language: 'it',
    correlation_id: 56,
    description: 'Kinyarwanda',
    active: true
  },
  {
    id: 57,
    language: 'it',
    correlation_id: 57,
    description: 'Kirghiso',
    active: true
  },
  {
    id: 58,
    language: 'it',
    correlation_id: 58,
    description: 'Lao',
    active: true
  },
  {
    id: 59,
    language: 'it',
    correlation_id: 59,
    description: 'Latino',
    active: true
  },
  {
    id: 60,
    language: 'it',
    correlation_id: 60,
    description: 'Lettone',
    active: true
  },
  {
    id: 61,
    language: 'it',
    correlation_id: 61,
    description: 'Lituano',
    active: true
  },
  {
    id: 62,
    language: 'it',
    correlation_id: 62,
    description: 'Lussemburghese',
    active: true
  },
  {
    id: 63,
    language: 'it',
    correlation_id: 63,
    description: 'Macedone',
    active: true
  },
  {
    id: 64,
    language: 'it',
    correlation_id: 64,
    description: 'Malayalam',
    active: true
  },
  {
    id: 65,
    language: 'it',
    correlation_id: 65,
    description: 'Malese',
    active: true
  },
  {
    id: 66,
    language: 'it',
    correlation_id: 66,
    description: 'Malgascio',
    active: true
  },
  {
    id: 67,
    language: 'it',
    correlation_id: 67,
    description: 'Maltese',
    active: true
  },
  {
    id: 68,
    language: 'it',
    correlation_id: 68,
    description: 'Maori',
    active: true
  },
  {
    id: 69,
    language: 'it',
    correlation_id: 69,
    description: 'Marathi',
    active: true
  },
  {
    id: 70,
    language: 'it',
    correlation_id: 70,
    description: 'Mongolo',
    active: true
  },
  {
    id: 71,
    language: 'it',
    correlation_id: 71,
    description: 'Nepalese',
    active: true
  },
  {
    id: 72,
    language: 'it',
    correlation_id: 72,
    description: 'Norvegese',
    active: true
  },
  {
    id: 73,
    language: 'it',
    correlation_id: 73,
    description: 'Nyanja',
    active: true
  },
  {
    id: 74,
    language: 'it',
    correlation_id: 74,
    description: 'Odia',
    active: true
  },
  {
    id: 75,
    language: 'it',
    correlation_id: 75,
    description: 'Olandese',
    active: true
  },
  {
    id: 76,
    language: 'it',
    correlation_id: 76,
    description: 'Pashto',
    active: true
  },
  {
    id: 77,
    language: 'it',
    correlation_id: 77,
    description: 'Persiano',
    active: true
  },
  {
    id: 78,
    language: 'it',
    correlation_id: 78,
    description: 'Polacco',
    active: true
  },
  {
    id: 79,
    language: 'it',
    correlation_id: 79,
    description: 'Punjabi',
    active: true
  },
  {
    id: 80,
    language: 'it',
    correlation_id: 80,
    description: 'Rumeno',
    active: true
  },
  {
    id: 81,
    language: 'it',
    correlation_id: 81,
    description: 'Samoano',
    active: true
  },
  {
    id: 82,
    language: 'it',
    correlation_id: 82,
    description: 'Serbo',
    active: true
  },
  {
    id: 83,
    language: 'it',
    correlation_id: 83,
    description: 'Shona',
    active: true
  },
  {
    id: 84,
    language: 'it',
    correlation_id: 84,
    description: 'Sindhi',
    active: true
  },
  {
    id: 85,
    language: 'it',
    correlation_id: 85,
    description: 'Singalese',
    active: true
  },
  {
    id: 86,
    language: 'it',
    correlation_id: 86,
    description: 'Slovacco',
    active: true
  },
  {
    id: 87,
    language: 'it',
    correlation_id: 87,
    description: 'Sloveno',
    active: true
  },
  {
    id: 88,
    language: 'it',
    correlation_id: 88,
    description: 'Somalo',
    active: true
  },
  {
    id: 89,
    language: 'it',
    correlation_id: 89,
    description: 'Sotho del sud',
    active: true
  },
  {
    id: 90,
    language: 'it',
    correlation_id: 90,
    description: 'Sundanese',
    active: true
  },
  {
    id: 91,
    language: 'it',
    correlation_id: 91,
    description: 'Svedese',
    active: true
  },
  {
    id: 92,
    language: 'it',
    correlation_id: 92,
    description: 'Swahili',
    active: true
  },
  {
    id: 93,
    language: 'it',
    correlation_id: 93,
    description: 'Tagico',
    active: true
  },
  {
    id: 94,
    language: 'it',
    correlation_id: 94,
    description: 'Tamil',
    active: true
  },
  {
    id: 95,
    language: 'it',
    correlation_id: 95,
    description: 'Tataro',
    active: true
  },
  {
    id: 96,
    language: 'it',
    correlation_id: 96,
    description: 'Telugu',
    active: true
  },
  {
    id: 97,
    language: 'it',
    correlation_id: 97,
    description: 'Thai',
    active: true
  },
  {
    id: 98,
    language: 'it',
    correlation_id: 98,
    description: 'Turco',
    active: true
  },
  {
    id: 99,
    language: 'it',
    correlation_id: 99,
    description: 'Turcomanno',
    active: true
  },
  {
    id: 100,
    language: 'it',
    correlation_id: 100,
    description: 'Ucraino',
    active: true
  },
  {
    id: 101,
    language: 'it',
    correlation_id: 101,
    description: 'Uiguro',
    active: true
  },
  {
    id: 102,
    language: 'it',
    correlation_id: 102,
    description: 'Ungherese',
    active: true
  },
  {
    id: 103,
    language: 'it',
    correlation_id: 103,
    description: 'Urdu',
    active: true
  },
  {
    id: 104,
    language: 'it',
    correlation_id: 104,
    description: 'Uzbeco',
    active: true
  },
  {
    id: 105,
    language: 'it',
    correlation_id: 105,
    description: 'Vietnamita',
    active: true
  },
  {
    id: 106,
    language: 'it',
    correlation_id: 106,
    description: 'Xhosa',
    active: true
  },
  {
    id: 107,
    language: 'it',
    correlation_id: 107,
    description: 'Yiddish',
    active: true
  },
  {
    id: 108,
    language: 'it',
    correlation_id: 108,
    description: 'Yoruba',
    active: true
  },
  {
    id: 109,
    language: 'it',
    correlation_id: 109,
    description: 'Zulu',
    active: true
  },
  {
    id: 110,
    language: 'en',
    correlation_id: 1,
    description: 'Italian',
    active: true
  },
  {
    id: 111,
    language: 'en',
    correlation_id: 2,
    description: 'Chinese (simplified)',
    active: true
  },
  {
    id: 112,
    language: 'en',
    correlation_id: 3,
    description: 'French',
    active: true
  },
  {
    id: 113,
    language: 'en',
    correlation_id: 4,
    description: 'English',
    active: true
  },
  {
    id: 114,
    language: 'en',
    correlation_id: 5,
    description: 'Portuguese',
    active: true
  },
  {
    id: 115,
    language: 'en',
    correlation_id: 6,
    description: 'Russian',
    active: true
  },
  {
    id: 116,
    language: 'en',
    correlation_id: 7,
    description: 'Spanish',
    active: true
  },
  {
    id: 117,
    language: 'en',
    correlation_id: 8,
    description: 'German',
    active: true
  },
  {
    id: 118,
    language: 'en',
    correlation_id: 9,
    description: 'Arabic',
    active: true
  },
  {
    id: 119,
    language: 'en',
    correlation_id: 10,
    description: 'Afrikaans',
    active: true
  },
  {
    id: 120,
    language: 'en',
    correlation_id: 11,
    description: 'Albanian',
    active: true
  },
  {
    id: 121,
    language: 'en',
    correlation_id: 12,
    description: 'Amharic',
    active: true
  },
  {
    id: 122,
    language: 'en',
    correlation_id: 13,
    description: 'Armenian',
    active: true
  },
  {
    id: 123,
    language: 'en',
    correlation_id: 14,
    description: 'Azerbaijani',
    active: true
  },
  {
    id: 124,
    language: 'en',
    correlation_id: 15,
    description: 'Basque',
    active: true
  },
  {
    id: 125,
    language: 'en',
    correlation_id: 16,
    description: 'Bengali',
    active: true
  },
  {
    id: 126,
    language: 'en',
    correlation_id: 17,
    description: 'Belarusian',
    active: true
  },
  {
    id: 127,
    language: 'en',
    correlation_id: 18,
    description: 'Burmese',
    active: true
  },
  {
    id: 128,
    language: 'en',
    correlation_id: 19,
    description: 'Bosnian',
    active: true
  },
  {
    id: 129,
    language: 'en',
    correlation_id: 20,
    description: 'Bulgarian',
    active: true
  },
  {
    id: 130,
    language: 'en',
    correlation_id: 21,
    description: 'Catalan',
    active: true
  },
  {
    id: 131,
    language: 'en',
    correlation_id: 22,
    description: 'Cebuano',
    active: true
  },
  {
    id: 132,
    language: 'en',
    correlation_id: 23,
    description: 'Czech',
    active: true
  },
  {
    id: 133,
    language: 'en',
    correlation_id: 24,
    description: 'Chinese (Traditional)',
    active: true
  },
  {
    id: 134,
    language: 'en',
    correlation_id: 25,
    description: 'Korean',
    active: true
  },
  {
    id: 135,
    language: 'en',
    correlation_id: 26,
    description: 'Course',
    active: true
  },
  {
    id: 136,
    language: 'en',
    correlation_id: 27,
    description: 'Haitian Creole',
    active: true
  },
  {
    id: 137,
    language: 'en',
    correlation_id: 28,
    description: 'Croatian',
    active: true
  },
  {
    id: 138,
    language: 'en',
    correlation_id: 29,
    description: 'Kurdish',
    active: true
  },
  {
    id: 139,
    language: 'en',
    correlation_id: 30,
    description: 'Danish',
    active: true
  },
  {
    id: 140,
    language: 'en',
    correlation_id: 31,
    description: 'Hebrew',
    active: true
  },
  {
    id: 141,
    language: 'en',
    correlation_id: 32,
    description: 'Esperanto',
    active: true
  },
  {
    id: 142,
    language: 'en',
    correlation_id: 33,
    description: 'Estonian',
    active: true
  },
  {
    id: 143,
    language: 'en',
    correlation_id: 34,
    description: 'Filipino',
    active: true
  },
  {
    id: 144,
    language: 'en',
    correlation_id: 35,
    description: 'Finnish',
    active: true
  },
  {
    id: 145,
    language: 'en',
    correlation_id: 36,
    description: 'West Frisian',
    active: true
  },
  {
    id: 146,
    language: 'en',
    correlation_id: 37,
    description: 'Scottish Gaelic',
    active: true
  },
  {
    id: 147,
    language: 'en',
    correlation_id: 38,
    description: 'Galician',
    active: true
  },
  {
    id: 148,
    language: 'en',
    correlation_id: 39,
    description: 'Welsh',
    active: true
  },
  {
    id: 149,
    language: 'en',
    correlation_id: 40,
    description: 'Georgian',
    active: true
  },
  {
    id: 150,
    language: 'en',
    correlation_id: 41,
    description: 'Japanese',
    active: true
  },
  {
    id: 151,
    language: 'en',
    correlation_id: 42,
    description: 'Javanese',
    active: true
  },
  {
    id: 152,
    language: 'en',
    correlation_id: 43,
    description: 'Greek',
    active: true
  },
  {
    id: 153,
    language: 'en',
    correlation_id: 44,
    description: 'Gujarati',
    active: true
  },
  {
    id: 154,
    language: 'en',
    correlation_id: 45,
    description: 'Hausa',
    active: true
  },
  {
    id: 155,
    language: 'en',
    correlation_id: 46,
    description: 'Hawaiian',
    active: true
  },
  {
    id: 156,
    language: 'en',
    correlation_id: 47,
    description: 'Hindi',
    active: true
  },
  {
    id: 157,
    language: 'en',
    correlation_id: 48,
    description: 'Hmong',
    active: true
  },
  {
    id: 158,
    language: 'en',
    correlation_id: 49,
    description: 'Igbo',
    active: true
  },
  {
    id: 159,
    language: 'en',
    correlation_id: 50,
    description: 'Indonesian',
    active: true
  },
  {
    id: 160,
    language: 'en',
    correlation_id: 51,
    description: 'Irish',
    active: true
  },
  {
    id: 161,
    language: 'en',
    correlation_id: 52,
    description: 'Icelandic',
    active: true
  },
  {
    id: 162,
    language: 'en',
    correlation_id: 53,
    description: 'Kannada',
    active: true
  },
  {
    id: 163,
    language: 'en',
    correlation_id: 54,
    description: 'Kazakh',
    active: true
  },
  {
    id: 164,
    language: 'en',
    correlation_id: 55,
    description: 'Khmer',
    active: true
  },
  {
    id: 165,
    language: 'en',
    correlation_id: 56,
    description: 'Kinyarwanda',
    active: true
  },
  {
    id: 166,
    language: 'en',
    correlation_id: 57,
    description: 'Kyrgyz',
    active: true
  },
  {
    id: 167,
    language: 'en',
    correlation_id: 58,
    description: 'Lao',
    active: true
  },
  {
    id: 168,
    language: 'en',
    correlation_id: 59,
    description: 'Latin',
    active: true
  },
  {
    id: 169,
    language: 'en',
    correlation_id: 60,
    description: 'Latvian',
    active: true
  },
  {
    id: 170,
    language: 'en',
    correlation_id: 61,
    description: 'Lithuanian',
    active: true
  },
  {
    id: 171,
    language: 'en',
    correlation_id: 62,
    description: 'Luxembourgish',
    active: true
  },
  {
    id: 172,
    language: 'en',
    correlation_id: 63,
    description: 'Macedonian',
    active: true
  },
  {
    id: 173,
    language: 'en',
    correlation_id: 64,
    description: 'Malayalam',
    active: true
  },
  {
    id: 174,
    language: 'en',
    correlation_id: 65,
    description: 'Malay',
    active: true
  },
  {
    id: 175,
    language: 'en',
    correlation_id: 66,
    description: 'Malagasy',
    active: true
  },
  {
    id: 176,
    language: 'en',
    correlation_id: 67,
    description: 'Maltese',
    active: true
  },
  {
    id: 177,
    language: 'en',
    correlation_id: 68,
    description: 'Maori',
    active: true
  },
  {
    id: 178,
    language: 'en',
    correlation_id: 69,
    description: 'Marathi',
    active: true
  },
  {
    id: 179,
    language: 'en',
    correlation_id: 70,
    description: 'Mongolian',
    active: true
  },
  {
    id: 180,
    language: 'en',
    correlation_id: 71,
    description: 'Nepalese',
    active: true
  },
  {
    id: 181,
    language: 'en',
    correlation_id: 72,
    description: 'Norwegian',
    active: true
  },
  {
    id: 182,
    language: 'en',
    correlation_id: 73,
    description: 'Nyanja',
    active: true
  },
  {
    id: 183,
    language: 'en',
    correlation_id: 74,
    description: 'Odia',
    active: true
  },
  {
    id: 184,
    language: 'en',
    correlation_id: 75,
    description: 'Dutch',
    active: true
  },
  {
    id: 185,
    language: 'en',
    correlation_id: 76,
    description: 'Pashto',
    active: true
  },
  {
    id: 186,
    language: 'en',
    correlation_id: 77,
    description: 'Persian',
    active: true
  },
  {
    id: 187,
    language: 'en',
    correlation_id: 78,
    description: 'Polish',
    active: true
  },
  {
    id: 188,
    language: 'en',
    correlation_id: 79,
    description: 'Punjabi',
    active: true
  },
  {
    id: 189,
    language: 'en',
    correlation_id: 80,
    description: 'Romanian',
    active: true
  },
  {
    id: 190,
    language: 'en',
    correlation_id: 81,
    description: 'Samoan',
    active: true
  },
  {
    id: 191,
    language: 'en',
    correlation_id: 82,
    description: 'Serbian',
    active: true
  },
  {
    id: 192,
    language: 'en',
    correlation_id: 83,
    description: 'Shona',
    active: true
  },
  {
    id: 193,
    language: 'en',
    correlation_id: 84,
    description: 'Sindhi',
    active: true
  },
  {
    id: 194,
    language: 'en',
    correlation_id: 85,
    description: 'Singalese',
    active: true
  },
  {
    id: 195,
    language: 'en',
    correlation_id: 86,
    description: 'Slovak',
    active: true
  },
  {
    id: 196,
    language: 'en',
    correlation_id: 87,
    description: 'Slovenian',
    active: true
  },
  {
    id: 197,
    language: 'en',
    correlation_id: 88,
    description: 'Somali',
    active: true
  },
  {
    id: 198,
    language: 'en',
    correlation_id: 89,
    description: 'Southern Sotho',
    active: true
  },
  {
    id: 199,
    language: 'en',
    correlation_id: 90,
    description: 'Sundanese',
    active: true
  },
  {
    id: 200,
    language: 'en',
    correlation_id: 91,
    description: 'Swedish',
    active: true
  },
  {
    id: 201,
    language: 'en',
    correlation_id: 92,
    description: 'Swahili',
    active: true
  },
  {
    id: 202,
    language: 'en',
    correlation_id: 93,
    description: 'Tajik',
    active: true
  },
  {
    id: 203,
    language: 'en',
    correlation_id: 94,
    description: 'Tamil',
    active: true
  },
  {
    id: 204,
    language: 'en',
    correlation_id: 95,
    description: 'Tatar',
    active: true
  },
  {
    id: 205,
    language: 'en',
    correlation_id: 96,
    description: 'Telugu',
    active: true
  },
  {
    id: 206,
    language: 'en',
    correlation_id: 97,
    description: 'Thai',
    active: true
  },
  {
    id: 207,
    language: 'en',
    correlation_id: 98,
    description: 'Turkish',
    active: true
  },
  {
    id: 208,
    language: 'en',
    correlation_id: 99,
    description: 'Turkish',
    active: true
  },
  {
    id: 209,
    language: 'en',
    correlation_id: 100,
    description: 'Ukrainian',
    active: true
  },
  {
    id: 210,
    language: 'en',
    correlation_id: 101,
    description: 'Uyghur',
    active: true
  },
  {
    id: 211,
    language: 'en',
    correlation_id: 102,
    description: 'Hungarian',
    active: true
  },
  {
    id: 212,
    language: 'en',
    correlation_id: 103,
    description: 'Urdu',
    active: true
  },
  {
    id: 213,
    language: 'en',
    correlation_id: 104,
    description: 'Uzbek',
    active: true
  },
  {
    id: 214,
    language: 'en',
    correlation_id: 105,
    description: 'Vietnamese',
    active: true
  },
  {
    id: 215,
    language: 'en',
    correlation_id: 106,
    description: 'Xhosa',
    active: true
  },
  {
    id: 216,
    language: 'en',
    correlation_id: 107,
    description: 'Yiddish',
    active: true
  },
  {
    id: 217,
    language: 'en',
    correlation_id: 108,
    description: 'Yoruba',
    active: true
  },
  {
    id: 218,
    language: 'en',
    correlation_id: 109,
    description: 'Zulu',
    active: true
  },
  {
    id: 219,
    language: 'es',
    correlation_id: 1,
    description: 'Italiano',
    active: true
  },
  {
    id: 220,
    language: 'es',
    correlation_id: 2,
    description: 'Chino (simplificado)',
    active: true
  },
  {
    id: 221,
    language: 'es',
    correlation_id: 3,
    description: 'Francés',
    active: true
  },
  {
    id: 222,
    language: 'es',
    correlation_id: 4,
    description: 'Inglés',
    active: true
  },
  {
    id: 223,
    language: 'es',
    correlation_id: 5,
    description: 'Portugués',
    active: true
  },
  {
    id: 224,
    language: 'es',
    correlation_id: 6,
    description: 'Ruso',
    active: true
  },
  {
    id: 225,
    language: 'es',
    correlation_id: 7,
    description: 'Español',
    active: true
  },
  {
    id: 226,
    language: 'es',
    correlation_id: 8,
    description: 'Alemán',
    active: true
  },
  {
    id: 227,
    language: 'es',
    correlation_id: 9,
    description: 'Árabe',
    active: true
  },
  {
    id: 228,
    language: 'es',
    correlation_id: 10,
    description: 'Afrikáans',
    active: true
  },
  {
    id: 229,
    language: 'es',
    correlation_id: 11,
    description: 'Albanés',
    active: true
  },
  {
    id: 230,
    language: 'es',
    correlation_id: 12,
    description: 'Amárico',
    active: true
  },
  {
    id: 231,
    language: 'es',
    correlation_id: 13,
    description: 'Armenio',
    active: true
  },
  {
    id: 232,
    language: 'es',
    correlation_id: 14,
    description: 'Azerbaiyán',
    active: true
  },
  {
    id: 233,
    language: 'es',
    correlation_id: 15,
    description: 'Vasco',
    active: true
  },
  {
    id: 234,
    language: 'es',
    correlation_id: 16,
    description: 'Bengalí',
    active: true
  },
  {
    id: 235,
    language: 'es',
    correlation_id: 17,
    description: 'Bielorruso',
    active: true
  },
  {
    id: 236,
    language: 'es',
    correlation_id: 18,
    description: 'Birmano',
    active: true
  },
  {
    id: 237,
    language: 'es',
    correlation_id: 19,
    description: 'Bosnia',
    active: true
  },
  {
    id: 238,
    language: 'es',
    correlation_id: 20,
    description: 'Búlgaro',
    active: true
  },
  {
    id: 239,
    language: 'es',
    correlation_id: 21,
    description: 'Catalán',
    active: true
  },
  {
    id: 240,
    language: 'es',
    correlation_id: 22,
    description: 'Cebuano',
    active: true
  },
  {
    id: 241,
    language: 'es',
    correlation_id: 23,
    description: 'Checa',
    active: true
  },
  {
    id: 242,
    language: 'es',
    correlation_id: 24,
    description: 'Chino (tradicional)',
    active: true
  },
  {
    id: 243,
    language: 'es',
    correlation_id: 25,
    description: 'Coreano',
    active: true
  },
  {
    id: 244,
    language: 'es',
    correlation_id: 26,
    description: 'Curso',
    active: true
  },
  {
    id: 245,
    language: 'es',
    correlation_id: 27,
    description: 'Criollo haitiano',
    active: true
  },
  {
    id: 246,
    language: 'es',
    correlation_id: 28,
    description: 'Croata',
    active: true
  },
  {
    id: 247,
    language: 'es',
    correlation_id: 29,
    description: 'Kurdo',
    active: true
  },
  {
    id: 248,
    language: 'es',
    correlation_id: 30,
    description: 'Danés',
    active: true
  },
  {
    id: 249,
    language: 'es',
    correlation_id: 31,
    description: 'Hebreo',
    active: true
  },
  {
    id: 250,
    language: 'es',
    correlation_id: 32,
    description: 'Esperanto',
    active: true
  },
  {
    id: 251,
    language: 'es',
    correlation_id: 33,
    description: 'Estonio',
    active: true
  },
  {
    id: 252,
    language: 'es',
    correlation_id: 34,
    description: 'Filipino',
    active: true
  },
  {
    id: 253,
    language: 'es',
    correlation_id: 35,
    description: 'Finlandés',
    active: true
  },
  {
    id: 254,
    language: 'es',
    correlation_id: 36,
    description: 'Frisón occidental',
    active: true
  },
  {
    id: 255,
    language: 'es',
    correlation_id: 37,
    description: 'Gaélico escocés',
    active: true
  },
  {
    id: 256,
    language: 'es',
    correlation_id: 38,
    description: 'Gallego',
    active: true
  },
  {
    id: 257,
    language: 'es',
    correlation_id: 39,
    description: 'Galesa',
    active: true
  },
  {
    id: 258,
    language: 'es',
    correlation_id: 40,
    description: 'Georgiano',
    active: true
  },
  {
    id: 259,
    language: 'es',
    correlation_id: 41,
    description: 'Japonés',
    active: true
  },
  {
    id: 260,
    language: 'es',
    correlation_id: 42,
    description: 'Javanés',
    active: true
  },
  {
    id: 261,
    language: 'es',
    correlation_id: 43,
    description: 'Griego',
    active: true
  },
  {
    id: 262,
    language: 'es',
    correlation_id: 44,
    description: 'Gujarati',
    active: true
  },
  {
    id: 263,
    language: 'es',
    correlation_id: 45,
    description: 'Hausa',
    active: true
  },
  {
    id: 264,
    language: 'es',
    correlation_id: 46,
    description: 'hawaiano',
    active: true
  },
  {
    id: 265,
    language: 'es',
    correlation_id: 47,
    description: 'Hindi',
    active: true
  },
  {
    id: 266,
    language: 'es',
    correlation_id: 48,
    description: 'Hmong',
    active: true
  },
  {
    id: 267,
    language: 'es',
    correlation_id: 49,
    description: 'Igbo',
    active: true
  },
  {
    id: 268,
    language: 'es',
    correlation_id: 50,
    description: 'Indonesia',
    active: true
  },
  {
    id: 269,
    language: 'es',
    correlation_id: 51,
    description: 'Irlandés',
    active: true
  },
  {
    id: 270,
    language: 'es',
    correlation_id: 52,
    description: 'Islandia',
    active: true
  },
  {
    id: 271,
    language: 'es',
    correlation_id: 53,
    description: 'Kannada',
    active: true
  },
  {
    id: 272,
    language: 'es',
    correlation_id: 54,
    description: 'Kazajistán',
    active: true
  },
  {
    id: 273,
    language: 'es',
    correlation_id: 55,
    description: 'Khmer',
    active: true
  },
  {
    id: 274,
    language: 'es',
    correlation_id: 56,
    description: 'Kinyarwanda',
    active: true
  },
  {
    id: 275,
    language: 'es',
    correlation_id: 57,
    description: 'Kyrgyz',
    active: true
  },
  {
    id: 276,
    language: 'es',
    correlation_id: 58,
    description: 'Lao',
    active: true
  },
  {
    id: 277,
    language: 'es',
    correlation_id: 59,
    description: 'Latín',
    active: true
  },
  {
    id: 278,
    language: 'es',
    correlation_id: 60,
    description: 'Letón',
    active: true
  },
  {
    id: 279,
    language: 'es',
    correlation_id: 61,
    description: 'Lituania',
    active: true
  },
  {
    id: 280,
    language: 'es',
    correlation_id: 62,
    description: 'Luxemburgo',
    active: true
  },
  {
    id: 281,
    language: 'es',
    correlation_id: 63,
    description: 'Macedonio',
    active: true
  },
  {
    id: 282,
    language: 'es',
    correlation_id: 64,
    description: 'Malayalam',
    active: true
  },
  {
    id: 283,
    language: 'es',
    correlation_id: 65,
    description: 'Malayo',
    active: true
  },
  {
    id: 284,
    language: 'es',
    correlation_id: 66,
    description: 'Malgache',
    active: true
  },
  {
    id: 285,
    language: 'es',
    correlation_id: 67,
    description: 'Maltés',
    active: true
  },
  {
    id: 286,
    language: 'es',
    correlation_id: 68,
    description: 'Maorí',
    active: true
  },
  {
    id: 287,
    language: 'es',
    correlation_id: 69,
    description: 'Marathi',
    active: true
  },
  {
    id: 288,
    language: 'es',
    correlation_id: 70,
    description: 'Mongolia',
    active: true
  },
  {
    id: 289,
    language: 'es',
    correlation_id: 71,
    description: 'Nepalesa',
    active: true
  },
  {
    id: 290,
    language: 'es',
    correlation_id: 72,
    description: 'Noruego',
    active: true
  },
  {
    id: 291,
    language: 'es',
    correlation_id: 73,
    description: 'Nyanja',
    active: true
  },
  {
    id: 292,
    language: 'es',
    correlation_id: 74,
    description: 'Odia',
    active: true
  },
  {
    id: 293,
    language: 'es',
    correlation_id: 75,
    description: 'Holandés',
    active: true
  },
  {
    id: 294,
    language: 'es',
    correlation_id: 76,
    description: 'Pashto',
    active: true
  },
  {
    id: 295,
    language: 'es',
    correlation_id: 77,
    description: 'Persa',
    active: true
  },
  {
    id: 296,
    language: 'es',
    correlation_id: 78,
    description: 'Polaco',
    active: true
  },
  {
    id: 297,
    language: 'es',
    correlation_id: 79,
    description: 'Punjabi',
    active: true
  },
  {
    id: 298,
    language: 'es',
    correlation_id: 80,
    description: 'Rumano',
    active: true
  },
  {
    id: 299,
    language: 'es',
    correlation_id: 81,
    description: 'Samoa',
    active: true
  },
  {
    id: 300,
    language: 'es',
    correlation_id: 82,
    description: 'Serbio',
    active: true
  },
  {
    id: 301,
    language: 'es',
    correlation_id: 83,
    description: 'Shona',
    active: true
  },
  {
    id: 302,
    language: 'es',
    correlation_id: 84,
    description: 'Sindhi',
    active: true
  },
  {
    id: 303,
    language: 'es',
    correlation_id: 85,
    description: 'Singalés',
    active: true
  },
  {
    id: 304,
    language: 'es',
    correlation_id: 86,
    description: 'Eslovaquia',
    active: true
  },
  {
    id: 305,
    language: 'es',
    correlation_id: 87,
    description: 'Eslovenia',
    active: true
  },
  {
    id: 306,
    language: 'es',
    correlation_id: 88,
    description: 'Somalia',
    active: true
  },
  {
    id: 307,
    language: 'es',
    correlation_id: 89,
    description: 'Sotho del Sur',
    active: true
  },
  {
    id: 308,
    language: 'es',
    correlation_id: 90,
    description: 'Sundanese',
    active: true
  },
  {
    id: 309,
    language: 'es',
    correlation_id: 91,
    description: 'Sueco',
    active: true
  },
  {
    id: 310,
    language: 'es',
    correlation_id: 92,
    description: 'Swahili',
    active: true
  },
  {
    id: 311,
    language: 'es',
    correlation_id: 93,
    description: 'Tajik',
    active: true
  },
  {
    id: 312,
    language: 'es',
    correlation_id: 94,
    description: 'Tamil',
    active: true
  },
  {
    id: 313,
    language: 'es',
    correlation_id: 95,
    description: 'Tártaro',
    active: true
  },
  {
    id: 314,
    language: 'es',
    correlation_id: 96,
    description: 'Telugu',
    active: true
  },
  {
    id: 315,
    language: 'es',
    correlation_id: 97,
    description: 'Tailandés',
    active: true
  },
  {
    id: 316,
    language: 'es',
    correlation_id: 98,
    description: 'Turco',
    active: true
  },
  {
    id: 317,
    language: 'es',
    correlation_id: 99,
    description: 'Turco',
    active: true
  },
  {
    id: 318,
    language: 'es',
    correlation_id: 100,
    description: 'ucraniano',
    active: true
  },
  {
    id: 319,
    language: 'es',
    correlation_id: 101,
    description: 'Uyghur',
    active: true
  },
  {
    id: 320,
    language: 'es',
    correlation_id: 102,
    description: 'Húngaro',
    active: true
  },
  {
    id: 321,
    language: 'es',
    correlation_id: 103,
    description: 'Urdu',
    active: true
  },
  {
    id: 322,
    language: 'es',
    correlation_id: 104,
    description: 'Uzbekistán',
    active: true
  },
  {
    id: 323,
    language: 'es',
    correlation_id: 105,
    description: 'Vietnamita',
    active: true
  },
  {
    id: 324,
    language: 'es',
    correlation_id: 106,
    description: 'Xhosa',
    active: true
  },
  {
    id: 325,
    language: 'es',
    correlation_id: 107,
    description: 'Yiddish',
    active: true
  },
  {
    id: 326,
    language: 'es',
    correlation_id: 108,
    description: 'Yoruba',
    active: true
  },
  {
    id: 327,
    language: 'es',
    correlation_id: 109,
    description: 'Zulú',
    active: true
  },
  {
    id: 328,
    language: 'de',
    correlation_id: 1,
    description: 'Italienisch',
    active: true
  },
  {
    id: 329,
    language: 'de',
    correlation_id: 2,
    description: 'Chinesisch (vereinfacht)',
    active: true
  },
  {
    id: 330,
    language: 'de',
    correlation_id: 3,
    description: 'Französisch',
    active: true
  },
  {
    id: 331,
    language: 'de',
    correlation_id: 4,
    description: 'Englisch',
    active: true
  },
  {
    id: 332,
    language: 'de',
    correlation_id: 5,
    description: 'Portugiesisch',
    active: true
  },
  {
    id: 333,
    language: 'de',
    correlation_id: 6,
    description: 'Russisch',
    active: true
  },
  {
    id: 334,
    language: 'de',
    correlation_id: 7,
    description: 'Spanisch',
    active: true
  },
  {
    id: 335,
    language: 'de',
    correlation_id: 8,
    description: 'Deutsch',
    active: true
  },
  {
    id: 336,
    language: 'de',
    correlation_id: 9,
    description: 'Arabisch',
    active: true
  },
  {
    id: 337,
    language: 'de',
    correlation_id: 10,
    description: 'Afrikaans',
    active: true
  },
  {
    id: 338,
    language: 'de',
    correlation_id: 11,
    description: 'Albanisch',
    active: true
  },
  {
    id: 339,
    language: 'de',
    correlation_id: 12,
    description: 'Amharisch',
    active: true
  },
  {
    id: 340,
    language: 'de',
    correlation_id: 13,
    description: 'Armenisch',
    active: true
  },
  {
    id: 341,
    language: 'de',
    correlation_id: 14,
    description: 'Aserbaidschanisch',
    active: true
  },
  {
    id: 342,
    language: 'de',
    correlation_id: 15,
    description: 'Baskisch',
    active: true
  },
  {
    id: 343,
    language: 'de',
    correlation_id: 16,
    description: 'Bengalisch',
    active: true
  },
  {
    id: 344,
    language: 'de',
    correlation_id: 17,
    description: 'Weißrussisch',
    active: true
  },
  {
    id: 345,
    language: 'de',
    correlation_id: 18,
    description: 'Birmanisch',
    active: true
  },
  {
    id: 346,
    language: 'de',
    correlation_id: 19,
    description: 'Bosnisch',
    active: true
  },
  {
    id: 347,
    language: 'de',
    correlation_id: 20,
    description: 'Bulgarisch',
    active: true
  },
  {
    id: 348,
    language: 'de',
    correlation_id: 21,
    description: 'Katalanisch',
    active: true
  },
  {
    id: 349,
    language: 'de',
    correlation_id: 22,
    description: 'Cebuano',
    active: true
  },
  {
    id: 350,
    language: 'de',
    correlation_id: 23,
    description: 'Tschechisch',
    active: true
  },
  {
    id: 351,
    language: 'de',
    correlation_id: 24,
    description: 'Chinesisch (traditionell)',
    active: true
  },
  {
    id: 352,
    language: 'de',
    correlation_id: 25,
    description: 'Koreanisch',
    active: true
  },
  {
    id: 353,
    language: 'de',
    correlation_id: 26,
    description: 'Kurs',
    active: true
  },
  {
    id: 354,
    language: 'de',
    correlation_id: 27,
    description: 'Haitianisch-Kreolisch',
    active: true
  },
  {
    id: 355,
    language: 'de',
    correlation_id: 28,
    description: 'Kroatisch',
    active: true
  },
  {
    id: 356,
    language: 'de',
    correlation_id: 29,
    description: 'Kurdisch',
    active: true
  },
  {
    id: 357,
    language: 'de',
    correlation_id: 30,
    description: 'Dänisch',
    active: true
  },
  {
    id: 358,
    language: 'de',
    correlation_id: 31,
    description: 'Hebräisch',
    active: true
  },
  {
    id: 359,
    language: 'de',
    correlation_id: 32,
    description: 'Esperanto',
    active: true
  },
  {
    id: 360,
    language: 'de',
    correlation_id: 33,
    description: 'Estnisch',
    active: true
  },
  {
    id: 361,
    language: 'de',
    correlation_id: 34,
    description: 'Filipino',
    active: true
  },
  {
    id: 362,
    language: 'de',
    correlation_id: 35,
    description: 'Finnisch',
    active: true
  },
  {
    id: 363,
    language: 'de',
    correlation_id: 36,
    description: 'Westfriesisch',
    active: true
  },
  {
    id: 364,
    language: 'de',
    correlation_id: 37,
    description: 'Schottisches Gälisch',
    active: true
  },
  {
    id: 365,
    language: 'de',
    correlation_id: 38,
    description: 'Galizisch',
    active: true
  },
  {
    id: 366,
    language: 'de',
    correlation_id: 39,
    description: 'Walisisch',
    active: true
  },
  {
    id: 367,
    language: 'de',
    correlation_id: 40,
    description: 'Georgisch',
    active: true
  },
  {
    id: 368,
    language: 'de',
    correlation_id: 41,
    description: 'Japanisch',
    active: true
  },
  {
    id: 369,
    language: 'de',
    correlation_id: 42,
    description: 'Javanisch',
    active: true
  },
  {
    id: 370,
    language: 'de',
    correlation_id: 43,
    description: 'Griechisch',
    active: true
  },
  {
    id: 371,
    language: 'de',
    correlation_id: 44,
    description: 'Gujarati',
    active: true
  },
  {
    id: 372,
    language: 'de',
    correlation_id: 45,
    description: 'Hausa',
    active: true
  },
  {
    id: 373,
    language: 'de',
    correlation_id: 46,
    description: 'Hawaii',
    active: true
  },
  {
    id: 374,
    language: 'de',
    correlation_id: 47,
    description: 'Hindi',
    active: true
  },
  {
    id: 375,
    language: 'de',
    correlation_id: 48,
    description: 'Hmong',
    active: true
  },
  {
    id: 376,
    language: 'de',
    correlation_id: 49,
    description: 'Igbo',
    active: true
  },
  {
    id: 377,
    language: 'de',
    correlation_id: 50,
    description: 'Indonesisch',
    active: true
  },
  {
    id: 378,
    language: 'de',
    correlation_id: 51,
    description: 'Irisch',
    active: true
  },
  {
    id: 379,
    language: 'de',
    correlation_id: 52,
    description: 'Isländisch',
    active: true
  },
  {
    id: 380,
    language: 'de',
    correlation_id: 53,
    description: 'Kannada',
    active: true
  },
  {
    id: 381,
    language: 'de',
    correlation_id: 54,
    description: 'Kasachstan',
    active: true
  },
  {
    id: 382,
    language: 'de',
    correlation_id: 55,
    description: 'Khmer',
    active: true
  },
  {
    id: 383,
    language: 'de',
    correlation_id: 56,
    description: 'Kinyarwanda',
    active: true
  },
  {
    id: 384,
    language: 'de',
    correlation_id: 57,
    description: 'Kirgisistan',
    active: true
  },
  {
    id: 385,
    language: 'de',
    correlation_id: 58,
    description: 'Laos',
    active: true
  },
  {
    id: 386,
    language: 'de',
    correlation_id: 59,
    description: 'Lateinisch',
    active: true
  },
  {
    id: 387,
    language: 'de',
    correlation_id: 60,
    description: 'Lettisch',
    active: true
  },
  {
    id: 388,
    language: 'de',
    correlation_id: 61,
    description: 'Litauisch',
    active: true
  },
  {
    id: 389,
    language: 'de',
    correlation_id: 62,
    description: 'Luxemburgisch',
    active: true
  },
  {
    id: 390,
    language: 'de',
    correlation_id: 63,
    description: 'Mazedonisch',
    active: true
  },
  {
    id: 391,
    language: 'de',
    correlation_id: 64,
    description: 'Malayalam',
    active: true
  },
  {
    id: 392,
    language: 'de',
    correlation_id: 65,
    description: 'Malaiisch',
    active: true
  },
  {
    id: 393,
    language: 'de',
    correlation_id: 66,
    description: 'Madagassisch',
    active: true
  },
  {
    id: 394,
    language: 'de',
    correlation_id: 67,
    description: 'Malteser',
    active: true
  },
  {
    id: 395,
    language: 'de',
    correlation_id: 68,
    description: 'Maori',
    active: true
  },
  {
    id: 396,
    language: 'de',
    correlation_id: 69,
    description: 'Marathi',
    active: true
  },
  {
    id: 397,
    language: 'de',
    correlation_id: 70,
    description: 'Mongolei',
    active: true
  },
  {
    id: 398,
    language: 'de',
    correlation_id: 71,
    description: 'Nepalesisch',
    active: true
  },
  {
    id: 399,
    language: 'de',
    correlation_id: 72,
    description: 'Norwegisch',
    active: true
  },
  {
    id: 400,
    language: 'de',
    correlation_id: 73,
    description: 'Nyanja',
    active: true
  },
  {
    id: 401,
    language: 'de',
    correlation_id: 74,
    description: 'Odia',
    active: true
  },
  {
    id: 402,
    language: 'de',
    correlation_id: 75,
    description: 'Niederländisch',
    active: true
  },
  {
    id: 403,
    language: 'de',
    correlation_id: 76,
    description: 'Paschtu',
    active: true
  },
  {
    id: 404,
    language: 'de',
    correlation_id: 77,
    description: 'Persisch',
    active: true
  },
  {
    id: 405,
    language: 'de',
    correlation_id: 78,
    description: 'Polnisch',
    active: true
  },
  {
    id: 406,
    language: 'de',
    correlation_id: 79,
    description: 'Punjabi',
    active: true
  },
  {
    id: 407,
    language: 'de',
    correlation_id: 80,
    description: 'Rumänisch',
    active: true
  },
  {
    id: 408,
    language: 'de',
    correlation_id: 81,
    description: 'Samoanisch',
    active: true
  },
  {
    id: 409,
    language: 'de',
    correlation_id: 82,
    description: 'Serbisch',
    active: true
  },
  {
    id: 410,
    language: 'de',
    correlation_id: 83,
    description: 'Shona',
    active: true
  },
  {
    id: 411,
    language: 'de',
    correlation_id: 84,
    description: 'Sindhi',
    active: true
  },
  {
    id: 412,
    language: 'de',
    correlation_id: 85,
    description: 'Singalese',
    active: true
  },
  {
    id: 413,
    language: 'de',
    correlation_id: 86,
    description: 'Slowakisch',
    active: true
  },
  {
    id: 414,
    language: 'de',
    correlation_id: 87,
    description: 'Slowenisch',
    active: true
  },
  {
    id: 415,
    language: 'de',
    correlation_id: 88,
    description: 'Somali',
    active: true
  },
  {
    id: 416,
    language: 'de',
    correlation_id: 89,
    description: 'Süd-Sotho',
    active: true
  },
  {
    id: 417,
    language: 'de',
    correlation_id: 90,
    description: 'Sundanisch',
    active: true
  },
  {
    id: 418,
    language: 'de',
    correlation_id: 91,
    description: 'Schwedisch',
    active: true
  },
  {
    id: 419,
    language: 'de',
    correlation_id: 92,
    description: 'Suaheli',
    active: true
  },
  {
    id: 420,
    language: 'de',
    correlation_id: 93,
    description: 'Tadschikisch',
    active: true
  },
  {
    id: 421,
    language: 'de',
    correlation_id: 94,
    description: 'Tamilisch',
    active: true
  },
  {
    id: 422,
    language: 'de',
    correlation_id: 95,
    description: 'Tatar',
    active: true
  },
  {
    id: 423,
    language: 'de',
    correlation_id: 96,
    description: 'Telugu',
    active: true
  },
  {
    id: 424,
    language: 'de',
    correlation_id: 97,
    description: 'Thailändisch',
    active: true
  },
  {
    id: 425,
    language: 'de',
    correlation_id: 98,
    description: 'Türkisch',
    active: true
  },
  {
    id: 426,
    language: 'de',
    correlation_id: 99,
    description: 'Türkisch',
    active: true
  },
  {
    id: 427,
    language: 'de',
    correlation_id: 100,
    description: 'Ukrainisch',
    active: true
  },
  {
    id: 428,
    language: 'de',
    correlation_id: 101,
    description: 'Uigurisch',
    active: true
  },
  {
    id: 429,
    language: 'de',
    correlation_id: 102,
    description: 'Ungarisch',
    active: true
  },
  {
    id: 430,
    language: 'de',
    correlation_id: 103,
    description: 'Urdu',
    active: true
  },
  {
    id: 431,
    language: 'de',
    correlation_id: 104,
    description: 'Usbekisch',
    active: true
  },
  {
    id: 432,
    language: 'de',
    correlation_id: 105,
    description: 'Vietnamesisch',
    active: true
  },
  {
    id: 433,
    language: 'de',
    correlation_id: 106,
    description: 'Xhosa',
    active: true
  },
  {
    id: 434,
    language: 'de',
    correlation_id: 107,
    description: 'Jiddisch',
    active: true
  },
  {
    id: 435,
    language: 'de',
    correlation_id: 108,
    description: 'Yoruba',
    active: true
  },
  {
    id: 436,
    language: 'de',
    correlation_id: 109,
    description: 'Zulu',
    active: true
  },
  {
    id: 437,
    language: 'fr',
    correlation_id: 1,
    description: 'Italien',
    active: true
  },
  {
    id: 438,
    language: 'fr',
    correlation_id: 2,
    description: 'Chinois (simplifié)',
    active: true
  },
  {
    id: 439,
    language: 'fr',
    correlation_id: 3,
    description: 'Français',
    active: true
  },
  {
    id: 440,
    language: 'fr',
    correlation_id: 4,
    description: 'Anglais',
    active: true
  },
  {
    id: 441,
    language: 'fr',
    correlation_id: 5,
    description: 'Portugais',
    active: true
  },
  {
    id: 442,
    language: 'fr',
    correlation_id: 6,
    description: 'Russe',
    active: true
  },
  {
    id: 443,
    language: 'fr',
    correlation_id: 7,
    description: 'Espagnol',
    active: true
  },
  {
    id: 444,
    language: 'fr',
    correlation_id: 8,
    description: 'Allemand',
    active: true
  },
  {
    id: 445,
    language: 'fr',
    correlation_id: 9,
    description: 'Arabe',
    active: true
  },
  {
    id: 446,
    language: 'fr',
    correlation_id: 10,
    description: 'Afrikaans',
    active: true
  },
  {
    id: 447,
    language: 'fr',
    correlation_id: 11,
    description: 'Albanais',
    active: true
  },
  {
    id: 448,
    language: 'fr',
    correlation_id: 12,
    description: 'Amharique',
    active: true
  },
  {
    id: 449,
    language: 'fr',
    correlation_id: 13,
    description: 'Arménien',
    active: true
  },
  {
    id: 450,
    language: 'fr',
    correlation_id: 14,
    description: 'Azerbaïdjanais',
    active: true
  },
  {
    id: 451,
    language: 'fr',
    correlation_id: 15,
    description: 'Basque',
    active: true
  },
  {
    id: 452,
    language: 'fr',
    correlation_id: 16,
    description: 'Bengali',
    active: true
  },
  {
    id: 453,
    language: 'fr',
    correlation_id: 17,
    description: 'Biélorusse',
    active: true
  },
  {
    id: 454,
    language: 'fr',
    correlation_id: 18,
    description: 'Birmane',
    active: true
  },
  {
    id: 455,
    language: 'fr',
    correlation_id: 19,
    description: 'Bosniaque',
    active: true
  },
  {
    id: 456,
    language: 'fr',
    correlation_id: 20,
    description: 'Bulgare',
    active: true
  },
  {
    id: 457,
    language: 'fr',
    correlation_id: 21,
    description: 'Catalan',
    active: true
  },
  {
    id: 458,
    language: 'fr',
    correlation_id: 22,
    description: 'Cebuano',
    active: true
  },
  {
    id: 459,
    language: 'fr',
    correlation_id: 23,
    description: 'Tchèque',
    active: true
  },
  {
    id: 460,
    language: 'fr',
    correlation_id: 24,
    description: 'Chinois (traditionnel)',
    active: true
  },
  {
    id: 461,
    language: 'fr',
    correlation_id: 25,
    description: 'Coréen',
    active: true
  },
  {
    id: 462,
    language: 'fr',
    correlation_id: 26,
    description: 'Cours',
    active: true
  },
  {
    id: 463,
    language: 'fr',
    correlation_id: 27,
    description: 'Créole haïtien',
    active: true
  },
  {
    id: 464,
    language: 'fr',
    correlation_id: 28,
    description: 'Croate',
    active: true
  },
  {
    id: 465,
    language: 'fr',
    correlation_id: 29,
    description: 'Kurde',
    active: true
  },
  {
    id: 466,
    language: 'fr',
    correlation_id: 30,
    description: 'Danois',
    active: true
  },
  {
    id: 467,
    language: 'fr',
    correlation_id: 31,
    description: 'Hébreu',
    active: true
  },
  {
    id: 468,
    language: 'fr',
    correlation_id: 32,
    description: 'Espéranto',
    active: true
  },
  {
    id: 469,
    language: 'fr',
    correlation_id: 33,
    description: 'Estonien',
    active: true
  },
  {
    id: 470,
    language: 'fr',
    correlation_id: 34,
    description: 'Philippine',
    active: true
  },
  {
    id: 471,
    language: 'fr',
    correlation_id: 35,
    description: 'Finlandais',
    active: true
  },
  {
    id: 472,
    language: 'fr',
    correlation_id: 36,
    description: 'Frison occidental',
    active: true
  },
  {
    id: 473,
    language: 'fr',
    correlation_id: 37,
    description: 'Gaélique écossais',
    active: true
  },
  {
    id: 474,
    language: 'fr',
    correlation_id: 38,
    description: 'Galicien',
    active: true
  },
  {
    id: 475,
    language: 'fr',
    correlation_id: 39,
    description: 'Gallois',
    active: true
  },
  {
    id: 476,
    language: 'fr',
    correlation_id: 40,
    description: 'Géorgien',
    active: true
  },
  {
    id: 477,
    language: 'fr',
    correlation_id: 41,
    description: 'Japonais',
    active: true
  },
  {
    id: 478,
    language: 'fr',
    correlation_id: 42,
    description: 'Javanais',
    active: true
  },
  {
    id: 479,
    language: 'fr',
    correlation_id: 43,
    description: 'Grec',
    active: true
  },
  {
    id: 480,
    language: 'fr',
    correlation_id: 44,
    description: 'Gujarati',
    active: true
  },
  {
    id: 481,
    language: 'fr',
    correlation_id: 45,
    description: 'Hausa',
    active: true
  },
  {
    id: 482,
    language: 'fr',
    correlation_id: 46,
    description: 'Hawaïen',
    active: true
  },
  {
    id: 483,
    language: 'fr',
    correlation_id: 47,
    description: 'Hindi',
    active: true
  },
  {
    id: 484,
    language: 'fr',
    correlation_id: 48,
    description: 'Hmong',
    active: true
  },
  {
    id: 485,
    language: 'fr',
    correlation_id: 49,
    description: 'Igbo',
    active: true
  },
  {
    id: 486,
    language: 'fr',
    correlation_id: 50,
    description: 'Indonésien',
    active: true
  },
  {
    id: 487,
    language: 'fr',
    correlation_id: 51,
    description: 'Irlandais',
    active: true
  },
  {
    id: 488,
    language: 'fr',
    correlation_id: 52,
    description: 'islandais',
    active: true
  },
  {
    id: 489,
    language: 'fr',
    correlation_id: 53,
    description: 'Kannada',
    active: true
  },
  {
    id: 490,
    language: 'fr',
    correlation_id: 54,
    description: 'Kazakh',
    active: true
  },
  {
    id: 491,
    language: 'fr',
    correlation_id: 55,
    description: 'Khmer',
    active: true
  },
  {
    id: 492,
    language: 'fr',
    correlation_id: 56,
    description: 'Kinyarwanda',
    active: true
  },
  {
    id: 493,
    language: 'fr',
    correlation_id: 57,
    description: 'Kirghizistan',
    active: true
  },
  {
    id: 494,
    language: 'fr',
    correlation_id: 58,
    description: 'Lao',
    active: true
  },
  {
    id: 495,
    language: 'fr',
    correlation_id: 59,
    description: 'Latin',
    active: true
  },
  {
    id: 496,
    language: 'fr',
    correlation_id: 60,
    description: 'Letton',
    active: true
  },
  {
    id: 497,
    language: 'fr',
    correlation_id: 61,
    description: 'Lituanien',
    active: true
  },
  {
    id: 498,
    language: 'fr',
    correlation_id: 62,
    description: 'Luxembourgeois',
    active: true
  },
  {
    id: 499,
    language: 'fr',
    correlation_id: 63,
    description: 'Macédoine',
    active: true
  },
  {
    id: 500,
    language: 'fr',
    correlation_id: 64,
    description: 'Malayalam',
    active: true
  },
  {
    id: 501,
    language: 'fr',
    correlation_id: 65,
    description: 'Malais',
    active: true
  },
  {
    id: 502,
    language: 'fr',
    correlation_id: 66,
    description: 'Malgache',
    active: true
  },
  {
    id: 503,
    language: 'fr',
    correlation_id: 67,
    description: 'Maltais',
    active: true
  },
  {
    id: 504,
    language: 'fr',
    correlation_id: 68,
    description: 'Maori',
    active: true
  },
  {
    id: 505,
    language: 'fr',
    correlation_id: 69,
    description: 'Marathi',
    active: true
  },
  {
    id: 506,
    language: 'fr',
    correlation_id: 70,
    description: 'Mongolie',
    active: true
  },
  {
    id: 507,
    language: 'fr',
    correlation_id: 71,
    description: 'Népalais',
    active: true
  },
  {
    id: 508,
    language: 'fr',
    correlation_id: 72,
    description: 'Norvégien',
    active: true
  },
  {
    id: 509,
    language: 'fr',
    correlation_id: 73,
    description: 'Nyanja',
    active: true
  },
  {
    id: 510,
    language: 'fr',
    correlation_id: 74,
    description: 'Odia',
    active: true
  },
  {
    id: 511,
    language: 'fr',
    correlation_id: 75,
    description: 'Néerlandais',
    active: true
  },
  {
    id: 512,
    language: 'fr',
    correlation_id: 76,
    description: 'Pashto',
    active: true
  },
  {
    id: 513,
    language: 'fr',
    correlation_id: 77,
    description: 'Persan',
    active: true
  },
  {
    id: 514,
    language: 'fr',
    correlation_id: 78,
    description: 'Polonais',
    active: true
  },
  {
    id: 515,
    language: 'fr',
    correlation_id: 79,
    description: 'Punjabi',
    active: true
  },
  {
    id: 516,
    language: 'fr',
    correlation_id: 80,
    description: 'Roumain',
    active: true
  },
  {
    id: 517,
    language: 'fr',
    correlation_id: 81,
    description: 'Samoan',
    active: true
  },
  {
    id: 518,
    language: 'fr',
    correlation_id: 82,
    description: 'Serbe',
    active: true
  },
  {
    id: 519,
    language: 'fr',
    correlation_id: 83,
    description: 'Shona',
    active: true
  },
  {
    id: 520,
    language: 'fr',
    correlation_id: 84,
    description: 'Sindhi',
    active: true
  },
  {
    id: 521,
    language: 'fr',
    correlation_id: 85,
    description: 'Singalais',
    active: true
  },
  {
    id: 522,
    language: 'fr',
    correlation_id: 86,
    description: 'Slovaquie',
    active: true
  },
  {
    id: 523,
    language: 'fr',
    correlation_id: 87,
    description: 'Slovène',
    active: true
  },
  {
    id: 524,
    language: 'fr',
    correlation_id: 88,
    description: 'Somali',
    active: true
  },
  {
    id: 525,
    language: 'fr',
    correlation_id: 89,
    description: 'Sotho du Sud',
    active: true
  },
  {
    id: 526,
    language: 'fr',
    correlation_id: 90,
    description: 'Sundanais',
    active: true
  },
  {
    id: 527,
    language: 'fr',
    correlation_id: 91,
    description: 'Suédois',
    active: true
  },
  {
    id: 528,
    language: 'fr',
    correlation_id: 92,
    description: 'Swahili',
    active: true
  },
  {
    id: 529,
    language: 'fr',
    correlation_id: 93,
    description: 'Tadjik',
    active: true
  },
  {
    id: 530,
    language: 'fr',
    correlation_id: 94,
    description: 'Tamil',
    active: true
  },
  {
    id: 531,
    language: 'fr',
    correlation_id: 95,
    description: 'Tatar',
    active: true
  },
  {
    id: 532,
    language: 'fr',
    correlation_id: 96,
    description: 'Telugu',
    active: true
  },
  {
    id: 533,
    language: 'fr',
    correlation_id: 97,
    description: 'Thaïlandais',
    active: true
  },
  {
    id: 534,
    language: 'fr',
    correlation_id: 98,
    description: 'Turc',
    active: true
  },
  {
    id: 535,
    language: 'fr',
    correlation_id: 99,
    description: 'Turc',
    active: true
  },
  {
    id: 536,
    language: 'fr',
    correlation_id: 100,
    description: 'Ukrainien',
    active: true
  },
  {
    id: 537,
    language: 'fr',
    correlation_id: 101,
    description: 'Uyghur',
    active: true
  },
  {
    id: 538,
    language: 'fr',
    correlation_id: 102,
    description: 'Hongrois',
    active: true
  },
  {
    id: 539,
    language: 'fr',
    correlation_id: 103,
    description: 'Urdu',
    active: true
  },
  {
    id: 540,
    language: 'fr',
    correlation_id: 104,
    description: 'Ouzbek',
    active: true
  },
  {
    id: 541,
    language: 'fr',
    correlation_id: 105,
    description: 'Vietnamien',
    active: true
  },
  {
    id: 542,
    language: 'fr',
    correlation_id: 106,
    description: 'Xhosa',
    active: true
  },
  {
    id: 543,
    language: 'fr',
    correlation_id: 107,
    description: 'Yiddish',
    active: true
  },
  {
    id: 544,
    language: 'fr',
    correlation_id: 108,
    description: 'Yoruba',
    active: true
  },
  {
    id: 545,
    language: 'fr',
    correlation_id: 109,
    description: 'Zulu',
    active: true
  },
  {
    id: 546,
    language: 'pt',
    correlation_id: 1,
    description: 'Italiano',
    active: true
  },
  {
    id: 547,
    language: 'pt',
    correlation_id: 2,
    description: 'Chinês (simplificado)',
    active: true
  },
  {
    id: 548,
    language: 'pt',
    correlation_id: 3,
    description: 'Francês',
    active: true
  },
  {
    id: 549,
    language: 'pt',
    correlation_id: 4,
    description: 'Inglês',
    active: true
  },
  {
    id: 550,
    language: 'pt',
    correlation_id: 5,
    description: 'Português',
    active: true
  },
  {
    id: 551,
    language: 'pt',
    correlation_id: 6,
    description: 'Russo',
    active: true
  },
  {
    id: 552,
    language: 'pt',
    correlation_id: 7,
    description: 'Espanhol',
    active: true
  },
  {
    id: 553,
    language: 'pt',
    correlation_id: 8,
    description: 'Alemão',
    active: true
  },
  {
    id: 554,
    language: 'pt',
    correlation_id: 9,
    description: 'árabe',
    active: true
  },
  {
    id: 555,
    language: 'pt',
    correlation_id: 10,
    description: 'Afrikaans',
    active: true
  },
  {
    id: 556,
    language: 'pt',
    correlation_id: 11,
    description: 'Albanês',
    active: true
  },
  {
    id: 557,
    language: 'pt',
    correlation_id: 12,
    description: 'Amárico',
    active: true
  },
  {
    id: 558,
    language: 'pt',
    correlation_id: 13,
    description: 'Arménio',
    active: true
  },
  {
    id: 559,
    language: 'pt',
    correlation_id: 14,
    description: 'Azerbaijano',
    active: true
  },
  {
    id: 560,
    language: 'pt',
    correlation_id: 15,
    description: 'Basco',
    active: true
  },
  {
    id: 561,
    language: 'pt',
    correlation_id: 16,
    description: 'Bengali',
    active: true
  },
  {
    id: 562,
    language: 'pt',
    correlation_id: 17,
    description: 'Bielorrusso',
    active: true
  },
  {
    id: 563,
    language: 'pt',
    correlation_id: 18,
    description: 'Birmanês',
    active: true
  },
  {
    id: 564,
    language: 'pt',
    correlation_id: 19,
    description: 'Bósnio',
    active: true
  },
  {
    id: 565,
    language: 'pt',
    correlation_id: 20,
    description: 'Búlgaro',
    active: true
  },
  {
    id: 566,
    language: 'pt',
    correlation_id: 21,
    description: 'Catalão',
    active: true
  },
  {
    id: 567,
    language: 'pt',
    correlation_id: 22,
    description: 'Cebuano',
    active: true
  },
  {
    id: 568,
    language: 'pt',
    correlation_id: 23,
    description: 'Checo',
    active: true
  },
  {
    id: 569,
    language: 'pt',
    correlation_id: 24,
    description: 'Chinês (tradicional)',
    active: true
  },
  {
    id: 570,
    language: 'pt',
    correlation_id: 25,
    description: 'Coreano',
    active: true
  },
  {
    id: 571,
    language: 'pt',
    correlation_id: 26,
    description: 'Curso',
    active: true
  },
  {
    id: 572,
    language: 'pt',
    correlation_id: 27,
    description: 'Crioulo haitiano',
    active: true
  },
  {
    id: 573,
    language: 'pt',
    correlation_id: 28,
    description: 'Croata',
    active: true
  },
  {
    id: 574,
    language: 'pt',
    correlation_id: 29,
    description: 'Curdo',
    active: true
  },
  {
    id: 575,
    language: 'pt',
    correlation_id: 30,
    description: 'Dinamarquês',
    active: true
  },
  {
    id: 576,
    language: 'pt',
    correlation_id: 31,
    description: 'Hebraico',
    active: true
  },
  {
    id: 577,
    language: 'pt',
    correlation_id: 32,
    description: 'Esperanto',
    active: true
  },
  {
    id: 578,
    language: 'pt',
    correlation_id: 33,
    description: 'Estónio',
    active: true
  },
  {
    id: 579,
    language: 'pt',
    correlation_id: 34,
    description: 'Filipino',
    active: true
  },
  {
    id: 580,
    language: 'pt',
    correlation_id: 35,
    description: 'Finlandês',
    active: true
  },
  {
    id: 581,
    language: 'pt',
    correlation_id: 36,
    description: 'Frisiano Ocidental',
    active: true
  },
  {
    id: 582,
    language: 'pt',
    correlation_id: 37,
    description: 'Gaélico Escocês',
    active: true
  },
  {
    id: 583,
    language: 'pt',
    correlation_id: 38,
    description: 'Galego',
    active: true
  },
  {
    id: 584,
    language: 'pt',
    correlation_id: 39,
    description: 'Galês',
    active: true
  },
  {
    id: 585,
    language: 'pt',
    correlation_id: 40,
    description: 'Georgiano',
    active: true
  },
  {
    id: 586,
    language: 'pt',
    correlation_id: 41,
    description: 'Japonês',
    active: true
  },
  {
    id: 587,
    language: 'pt',
    correlation_id: 42,
    description: 'Javanês',
    active: true
  },
  {
    id: 588,
    language: 'pt',
    correlation_id: 43,
    description: 'Grego',
    active: true
  },
  {
    id: 589,
    language: 'pt',
    correlation_id: 44,
    description: 'Gujarati',
    active: true
  },
  {
    id: 590,
    language: 'pt',
    correlation_id: 45,
    description: 'Hausa',
    active: true
  },
  {
    id: 591,
    language: 'pt',
    correlation_id: 46,
    description: 'havaiano',
    active: true
  },
  {
    id: 592,
    language: 'pt',
    correlation_id: 47,
    description: 'Hindi',
    active: true
  },
  {
    id: 593,
    language: 'pt',
    correlation_id: 48,
    description: 'Hmong',
    active: true
  },
  {
    id: 594,
    language: 'pt',
    correlation_id: 49,
    description: 'Igbo',
    active: true
  },
  {
    id: 595,
    language: 'pt',
    correlation_id: 50,
    description: 'Indonésio',
    active: true
  },
  {
    id: 596,
    language: 'pt',
    correlation_id: 51,
    description: 'Irlandês',
    active: true
  },
  {
    id: 597,
    language: 'pt',
    correlation_id: 52,
    description: 'Islandês',
    active: true
  },
  {
    id: 598,
    language: 'pt',
    correlation_id: 53,
    description: 'Kannada',
    active: true
  },
  {
    id: 599,
    language: 'pt',
    correlation_id: 54,
    description: 'Cazaque',
    active: true
  },
  {
    id: 600,
    language: 'pt',
    correlation_id: 55,
    description: 'Khmer',
    active: true
  },
  {
    id: 601,
    language: 'pt',
    correlation_id: 56,
    description: 'Kinyarwanda',
    active: true
  },
  {
    id: 602,
    language: 'pt',
    correlation_id: 57,
    description: 'Quirguizes',
    active: true
  },
  {
    id: 603,
    language: 'pt',
    correlation_id: 58,
    description: 'Lao',
    active: true
  },
  {
    id: 604,
    language: 'pt',
    correlation_id: 59,
    description: 'Latim',
    active: true
  },
  {
    id: 605,
    language: 'pt',
    correlation_id: 60,
    description: 'Letão',
    active: true
  },
  {
    id: 606,
    language: 'pt',
    correlation_id: 61,
    description: 'Lituano',
    active: true
  },
  {
    id: 607,
    language: 'pt',
    correlation_id: 62,
    description: 'Luxemburguês',
    active: true
  },
  {
    id: 608,
    language: 'pt',
    correlation_id: 63,
    description: 'Macedónio',
    active: true
  },
  {
    id: 609,
    language: 'pt',
    correlation_id: 64,
    description: 'Malayalam',
    active: true
  },
  {
    id: 610,
    language: 'pt',
    correlation_id: 65,
    description: 'Malaio',
    active: true
  },
  {
    id: 611,
    language: 'pt',
    correlation_id: 66,
    description: 'Malgaxe',
    active: true
  },
  {
    id: 612,
    language: 'pt',
    correlation_id: 67,
    description: 'Maltês',
    active: true
  },
  {
    id: 613,
    language: 'pt',
    correlation_id: 68,
    description: 'Maori',
    active: true
  },
  {
    id: 614,
    language: 'pt',
    correlation_id: 69,
    description: 'Marathi',
    active: true
  },
  {
    id: 615,
    language: 'pt',
    correlation_id: 70,
    description: 'Mongol',
    active: true
  },
  {
    id: 616,
    language: 'pt',
    correlation_id: 71,
    description: 'Nepalês',
    active: true
  },
  {
    id: 617,
    language: 'pt',
    correlation_id: 72,
    description: 'Norueguês',
    active: true
  },
  {
    id: 618,
    language: 'pt',
    correlation_id: 73,
    description: 'Nyanja',
    active: true
  },
  {
    id: 619,
    language: 'pt',
    correlation_id: 74,
    description: 'Odia',
    active: true
  },
  {
    id: 620,
    language: 'pt',
    correlation_id: 75,
    description: 'Holandês',
    active: true
  },
  {
    id: 621,
    language: 'pt',
    correlation_id: 76,
    description: 'Pashto',
    active: true
  },
  {
    id: 622,
    language: 'pt',
    correlation_id: 77,
    description: 'Persa',
    active: true
  },
  {
    id: 623,
    language: 'pt',
    correlation_id: 78,
    description: 'Polaco',
    active: true
  },
  {
    id: 624,
    language: 'pt',
    correlation_id: 79,
    description: 'Punjabi',
    active: true
  },
  {
    id: 625,
    language: 'pt',
    correlation_id: 80,
    description: 'Romeno',
    active: true
  },
  {
    id: 626,
    language: 'pt',
    correlation_id: 81,
    description: 'Samoan',
    active: true
  },
  {
    id: 627,
    language: 'pt',
    correlation_id: 82,
    description: 'Sérvio',
    active: true
  },
  {
    id: 628,
    language: 'pt',
    correlation_id: 83,
    description: 'Shona',
    active: true
  },
  {
    id: 629,
    language: 'pt',
    correlation_id: 84,
    description: 'Sindhi',
    active: true
  },
  {
    id: 630,
    language: 'pt',
    correlation_id: 85,
    description: 'Singalese',
    active: true
  },
  {
    id: 631,
    language: 'pt',
    correlation_id: 86,
    description: 'Eslovaco',
    active: true
  },
  {
    id: 632,
    language: 'pt',
    correlation_id: 87,
    description: 'Esloveno',
    active: true
  },
  {
    id: 633,
    language: 'pt',
    correlation_id: 88,
    description: 'Somali',
    active: true
  },
  {
    id: 634,
    language: 'pt',
    correlation_id: 89,
    description: 'Sotho do Sul',
    active: true
  },
  {
    id: 635,
    language: 'pt',
    correlation_id: 90,
    description: 'Sundanese',
    active: true
  },
  {
    id: 636,
    language: 'pt',
    correlation_id: 91,
    description: 'Sueco',
    active: true
  },
  {
    id: 637,
    language: 'pt',
    correlation_id: 92,
    description: 'Swahili',
    active: true
  },
  {
    id: 638,
    language: 'pt',
    correlation_id: 93,
    description: 'Tajik',
    active: true
  },
  {
    id: 639,
    language: 'pt',
    correlation_id: 94,
    description: 'Tâmil',
    active: true
  },
  {
    id: 640,
    language: 'pt',
    correlation_id: 95,
    description: 'Tártaro',
    active: true
  },
  {
    id: 641,
    language: 'pt',
    correlation_id: 96,
    description: 'Telugu',
    active: true
  },
  {
    id: 642,
    language: 'pt',
    correlation_id: 97,
    description: 'Thai',
    active: true
  },
  {
    id: 643,
    language: 'pt',
    correlation_id: 98,
    description: 'Turco',
    active: true
  },
  {
    id: 644,
    language: 'pt',
    correlation_id: 99,
    description: 'Turco',
    active: true
  },
  {
    id: 645,
    language: 'pt',
    correlation_id: 100,
    description: 'Ucraniano',
    active: true
  },
  {
    id: 646,
    language: 'pt',
    correlation_id: 101,
    description: 'Uyghur',
    active: true
  },
  {
    id: 647,
    language: 'pt',
    correlation_id: 102,
    description: 'Húngaro',
    active: true
  },
  {
    id: 648,
    language: 'pt',
    correlation_id: 103,
    description: 'Urdu',
    active: true
  },
  {
    id: 649,
    language: 'pt',
    correlation_id: 104,
    description: 'Uzbeque',
    active: true
  },
  {
    id: 650,
    language: 'pt',
    correlation_id: 105,
    description: 'Vietnamita',
    active: true
  },
  {
    id: 651,
    language: 'pt',
    correlation_id: 106,
    description: 'Xhosa',
    active: true
  },
  {
    id: 652,
    language: 'pt',
    correlation_id: 107,
    description: 'Yiddish',
    active: true
  },
  {
    id: 653,
    language: 'pt',
    correlation_id: 108,
    description: 'Iorubá',
    active: true
  },
  {
    id: 654,
    language: 'pt',
    correlation_id: 109,
    description: 'Zulu',
    active: true
  }
]
export function getLanguages (lang) {
  return languages.filter(e => e.active && e.language === lang)
}
