export default {
  SET_POSTS: (state, payload) => {
    state.posts = payload
  },
  SET_REFRESH: (state, payload) => {
    state.refresh = payload
  },

  SET_CURRENT_PAGE: (state, payload) => {
    state.currentPage = payload
  },

  SET_SEARCH_TITLE: (state, payload) => {
    state.search_title = payload.replace(/-/g, ' ')
  },

  SET_CITY: (state, payload) => {
    state.city = payload
  },
  SET_RADIUS: (state, payload) => {
    state.radius = payload
  },
  SET_VERTICALI_RADAR: (state, payload) => {
    state.verticali = payload
  },
  SET_LAT: (state, payload) => {
    state.lat = payload
  },
  SET_LON: (state, payload) => {
    state.lon = payload
  },
  SET_ORP_SUGGESTION: (state, payload) => {
    state.orpSuggestion = payload
  },
  SET_ORP_OUTCOME: (state, payload) => {
    state.orpOutcome = payload
  }
}
