const education = [
  {
    id: 1,
    language: 'it',
    correlation_id: 0,
    description: 'Non specificato',
    active: true
  },
  {
    id: 2,
    language: 'it',
    correlation_id: 1,
    description: 'Scuola primaria',
    active: true
  },
  {
    id: 3,
    language: 'it',
    correlation_id: 2,
    description: 'Scuola secondaria di I livello (medie)',
    active: true
  },
  {
    id: 4,
    language: 'it',
    correlation_id: 3,
    description: 'Scuola secondaria di II livello (superiori)',
    active: true
  },
  {
    id: 5,
    language: 'it',
    correlation_id: 4,
    description: 'Laureando',
    active: true
  },
  {
    id: 6,
    language: 'it',
    correlation_id: 5,
    description: 'Laurea triennale',
    active: true
  },
  {
    id: 7,
    language: 'it',
    correlation_id: 6,
    description: 'Laurea magistrale (o specialistica)',
    active: true
  },
  {
    id: 8,
    language: 'it',
    correlation_id: 7,
    description: 'Master',
    active: true
  },
  {
    id: 9,
    language: 'it',
    correlation_id: 8,
    description: 'Dottorato',
    active: true
  },
  {
    id: 10,
    language: 'en',
    correlation_id: 0,
    description: 'Not Specified',
    active: true
  },
  {
    id: 11,
    language: 'en',
    correlation_id: 1,
    description: 'Elementary school',
    active: true
  },
  {
    id: 12,
    language: 'en',
    correlation_id: 2,
    description: 'Level I secondary school (middle school)',
    active: true
  },
  {
    id: 13,
    language: 'en',
    correlation_id: 3,
    description: 'Secondary school level II (high school)',
    active: true
  },
  {
    id: 14,
    language: 'en',
    correlation_id: 4,
    description: 'Graduating student',
    active: true
  },
  {
    id: 15,
    language: 'en',
    correlation_id: 5,
    description: 'Bachelor\'s degree',
    active: true
  },
  {
    id: 16,
    language: 'en',
    correlation_id: 6,
    description: 'MS',
    active: true
  },
  {
    id: 17,
    language: 'en',
    correlation_id: 7,
    description: 'M.A.',
    active: true
  },
  {
    id: 18,
    language: 'en',
    correlation_id: 8,
    description: 'PhD',
    active: true
  },
  {
    id: 19,
    language: 'es',
    correlation_id: 0,
    description: 'No especificado',
    active: true
  },
  {
    id: 20,
    language: 'es',
    correlation_id: 1,
    description: 'Escuela primaria',
    active: true
  },
  {
    id: 21,
    language: 'es',
    correlation_id: 2,
    description: 'Escuela media',
    active: true
  },
  {
    id: 22,
    language: 'es',
    correlation_id: 3,
    description: 'Bachillerato',
    active: true
  },
  {
    id: 23,
    language: 'es',
    correlation_id: 4,
    description: 'Estudiante graduado',
    active: true
  },
  {
    id: 24,
    language: 'es',
    correlation_id: 5,
    description: 'Licenciatura',
    active: true
  },
  {
    id: 25,
    language: 'es',
    correlation_id: 6,
    description: 'Postgrado',
    active: true
  },
  {
    id: 26,
    language: 'es',
    correlation_id: 7,
    description: 'Máster',
    active: true
  },
  {
    id: 27,
    language: 'es',
    correlation_id: 8,
    description: 'Doctorado',
    active: true
  },
  {
    id: 28,
    language: 'de',
    correlation_id: 0,
    description: 'Keine Angaben',
    active: true
  },
  {
    id: 29,
    language: 'de',
    correlation_id: 1,
    description: 'Grundschule',
    active: true
  },
  {
    id: 30,
    language: 'de',
    correlation_id: 2,
    description: 'Hauptschule',
    active: true
  },
  {
    id: 31,
    language: 'de',
    correlation_id: 3,
    description: 'Realschule',
    active: true
  },
  {
    id: 32,
    language: 'de',
    correlation_id: 4,
    description: 'Gymnasium',
    active: true
  },
  {
    id: 33,
    language: 'de',
    correlation_id: 5,
    description: 'Gesamtschule',
    active: true
  },
  {
    id: 34,
    language: 'de',
    correlation_id: 6,
    description: 'Bachelor',
    active: true
  },
  {
    id: 35,
    language: 'de',
    correlation_id: 7,
    description: 'Master',
    active: true
  },
  {
    id: 36,
    language: 'de',
    correlation_id: 8,
    description: 'Promotion',
    active: true
  },
  {
    id: 37,
    language: 'fr',
    correlation_id: 0,
    description: 'Non spécifié',
    active: true
  },
  {
    id: 38,
    language: 'fr',
    correlation_id: 1,
    description: 'École primaire',
    active: true
  },
  {
    id: 39,
    language: 'fr',
    correlation_id: 2,
    description: 'Collège',
    active: true
  },
  {
    id: 40,
    language: 'fr',
    correlation_id: 3,
    description: 'Collège Technique/Lycèe',
    active: true
  },
  {
    id: 41,
    language: 'fr',
    correlation_id: 4,
    description: 'Université',
    active: true
  },
  {
    id: 42,
    language: 'fr',
    correlation_id: 5,
    description: 'Licence',
    active: true
  },
  {
    id: 43,
    language: 'fr',
    correlation_id: 6,
    description: 'MSc',
    active: true
  },
  {
    id: 44,
    language: 'fr',
    correlation_id: 7,
    description: 'M.A.',
    active: true
  },
  {
    id: 45,
    language: 'fr',
    correlation_id: 8,
    description: 'Doctorat',
    active: true
  },
  {
    id: 46,
    language: 'pt',
    correlation_id: 0,
    description: 'Não especificado',
    active: true
  },
  {
    id: 47,
    language: 'pt',
    correlation_id: 1,
    description: 'Ensino Fundamental',
    active: true
  },
  {
    id: 48,
    language: 'pt',
    correlation_id: 2,
    description: 'Ensino Médio',
    active: true
  },
  {
    id: 49,
    language: 'pt',
    correlation_id: 3,
    description: 'Técnico de Nivel Médio',
    active: true
  },
  {
    id: 50,
    language: 'pt',
    correlation_id: 4,
    description: 'Graduação',
    active: true
  },
  {
    id: 51,
    language: 'pt',
    correlation_id: 5,
    description: 'Licenciatura',
    active: true
  },
  {
    id: 52,
    language: 'pt',
    correlation_id: 6,
    description: 'Mestre',
    active: true
  },
  {
    id: 53,
    language: 'pt',
    correlation_id: 7,
    description: 'Mestre Professional',
    active: true
  },
  {
    id: 54,
    language: 'pt',
    correlation_id: 8,
    description: 'Doutor',
    active: true
  }
]
export function getEducation (lang) {
  return education.filter(e => e.active && e.language === lang)
}
