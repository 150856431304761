// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default ({ app }, inject) => {
  inject('sanitizeTitle', (str) => {
    str = str.replace(/^\s+|\s+$/g, '') // trim
    str = str.toLowerCase()

    // remove accents, swap ñ for n, etc
    const from = 'àáäâèéëêìíïîòóöôùúüûñç\'·/_,:;'
    const to = 'aaaaeeeeiiiioooouuuunc       '
    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }
    str = str.trim()
    str = str.replace(/[^a-z0-9 -|]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-') // collapse dashes

    return str
  })

  inject('getCVFileName', (name) => {
    if (name !== undefined) {
      const pieces = name.split('/')
      return pieces[pieces.length - 1]
    } else {
      return ''
    }
  })

  inject('isEmptyObject', (obj) => {
    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype
  })

  inject('getLetterColor', (name) => {
    if (name !== undefined) {
      switch (name.charAt(0).toLowerCase()) {
      case 'a':
      case 'h':
      case 'o':
      case 'v':
        return '#0085d4'
      case 'b':
      case 'i':
      case 'p':
      case 'w':
        return '#008f7d'
      case 'c':
      case 'j':
      case 'q':
      case 'x':
        return '#bb00bd'
      case 'd':
      case 'k':
      case 'r':
      case 'y':
        return '#daaf00'
      case 'e':
      case 'l':
      case 's':
      case 'z':
        return '#fe5b00'
      case 'f':
      case 'm':
      case 't':
        return '#926c61'
      case 'g':
      case 'n':
      case 'u':
        return '#415b65'
      default:
        return '#150060'
      }
    } else {
      return '#fff'
    }
  })

  inject('testResults', (json) => {
    if (json === '') {
      return 'default'
    } else {
      const valori = JSON.parse(json)
      let acronym = ''

      for (const key in valori) {
        const temp = parseFloat(valori[key]) - 24
        valori[key] = Math.round((50 * temp) / 24)
        // delete temp;
      }

      if (valori.ie < 0) { acronym = 'i' } else { acronym = 'e' }
      if (valori.sn < 0) { acronym += 's' } else { acronym += 'n' }
      if (valori.ft < 0) { acronym += 'f' } else { acronym += 't' }
      if (valori.jp < 0) { acronym += 'j' } else { acronym += 'p' }

      return acronym
    }
  })
}
