var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('div', {
    staticClass: "loading"
  })]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }