import Vue from 'vue'

import './fonts.css'

import { head } from 'nuxt-speedkit/plugins/vFont/head'
import vFont from 'nuxt-speedkit/plugins/vFont'
import FontList from 'nuxt-speedkit/classes/FontList'
import { isSupportedBrowser } from 'nuxt-speedkit/utils/browser';
import LoadingSpinner from 'nuxt-speedkit/components/SpeedkitImage/classes/LoadingSpinner';

Vue.use(vFont)

const speedkit = Object.freeze({
  head: () => console.error('$speedkit.head() is not available in context'),
  crossorigin: "anonymous",
  isBrowserSupported: () => isSupportedBrowser({"regex":""}),
  loader: () => undefined,
  targetFormats: ["avif","webp","jpg|jpeg|png|gif"]
});

const fontList = new FontList([{"family":"Poppins","locals":["Poppins"],"fallback":["sans-serif"],"variances":[{"style":"normal","weight":200,"sources":[{"src":"assets/fonts/Poppins-Thin.woff2","type":"woff2"}],"src":require("assets/fonts/Poppins-Thin.woff2"),"type":"woff2"},{"style":"normal","weight":400,"sources":[{"src":"assets/fonts/Poppins-Regular.woff2","type":"woff2"}],"src":require("assets/fonts/Poppins-Regular.woff2"),"type":"woff2"},{"style":"normal","weight":700,"sources":[{"src":"assets/fonts/Poppins-Bold.woff2","type":"woff2"}],"src":require("assets/fonts/Poppins-Bold.woff2"),"type":"woff2"}]},{"family":"Lato","locals":["Lato"],"fallback":["sans-serif"],"variances":[{"style":"normal","weight":300,"sources":[{"src":"assets/fonts/Lato-300-latin2.woff2","type":"woff2"}],"src":require("assets/fonts/Lato-300-latin2.woff2"),"type":"woff2"},{"style":"normal","weight":400,"sources":[{"src":"assets/fonts/Lato-400-latin4.woff2","type":"woff2"}],"src":require("assets/fonts/Lato-400-latin4.woff2"),"type":"woff2"},{"style":"normal","weight":700,"sources":[{"src":"assets/fonts/Lato-700-latin6.woff2","type":"woff2"}],"src":require("assets/fonts/Lato-700-latin6.woff2"),"type":"woff2"}]}]);

export default (context, inject) => {
  inject('getFont', fontList.getFont.bind(fontList));
  inject('speedkit', speedkit);

  // For each render an own critical font style map is generated, which is used initially during page loading.
  const criticalFontStyles = {};
  inject('addCriticalFontStyle', (style) => {
    criticalFontStyles[style.hid] = style;
  });
  if (process.static && process.server) {
    context.beforeNuxtRender(({ nuxtState }) => {
      const ssrData = nuxtState.data[0] || {}
      ssrData._criticalFontStyles = criticalFontStyles
    })
  }
}

!('$speedkit' in Vue.prototype) && Object.defineProperty(Vue.prototype, '$speedkit', {
  get () {
    return Object.freeze(Object.assign({}, speedkit, {head: head.bind(this)}));
  }
});
