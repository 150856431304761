var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "layout"
  }, [_c('TheHeader'), _vm._v(" "), _c('div', {
    staticClass: "main-container-scroll"
  }, [_c('Nuxt'), _vm._v(" "), _c('TheFooter', {
    attrs: {
      "long": true
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }