export default () => ({
  posts: {},
  currentPage: 1,
  radius: 15000,
  lat: 0,
  lon: 0,
  verticali: [],
  country_iso_code: '',
  search_title: '',
  city: '',
  googleKey: process.env.GOOGLE_API_KEY,
  orpSuggestion: {},
  orpOutcome: {},
  refresh: 0
})
