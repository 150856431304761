
import { mapActions } from 'vuex'
import Vue from 'vue'

export default Vue.extend({
  name: 'Footer',
  props: {
    long: {
      type: Boolean,
      default: () => { return false }
    }
  },
  data: () => ({
    platformLinks: [],
    corporateLinks: [],
    agencyLinks: [],
    candidateLinks: [],
    otherLinks: [],
    textLinks: [],
    queryParams: null
  }),
  computed: {
    getLangs():{code:string}[] {
      const langs = JSON.parse(JSON.stringify(this.$i18n.locales))
      return langs.sort((l, m) => l.code.localeCompare(m.code))
    }
  },
  watch: {
    '$i18n.locale'() {
      this.createText()
    }
  },
  created () {
    this.createText()
    this.queryParams = this.$route.fullPath.substring(this.$route.fullPath.indexOf('?'))
  },
  methods: {
    ...mapActions({
      setPreviousLanguage: 'popup/setPreviousLanguage'
    }),
    changeLanguage(lang: string) {
      this.setPreviousLanguage(this.$i18n.locale)
      this.$i18n.locale = lang
      const name = this.$route.name.replace(/___\w+$/, '') + '___' + lang
      const newRoute = this.$router.resolve({
        name,
        params: this.$route.params,
        query: this.$route.query,
        hash: this.$route.hash
      })
      this.$router.push(newRoute.location)
      if (document.getElementsByClassName('main-container-scroll')[0]) {
        document.getElementsByClassName('main-container-scroll')[0].scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    openLink(link) {
      // Apri il link in una nuova scheda del browser
      const newWindow = window.open(link, '_blank')
      if (newWindow) {
        newWindow.opener = null // Evita che la finestra aperta possa accedere all'opener
        newWindow.focus() // Porta la nuova scheda in primo piano
      } else {
        // Gestisci il blocco dei popup
        alert('I popup sono bloccati dal tuo browser. Abilita i popup per aprire il link.')
      }
    },
    createText() {
      this.platformLinks = [
        {
          text: this.$t('footer.areYouACompany').toString(),
          link: 'https://business.cving.com/platform'
        },
        {
          text: this.$t('footer.publish').toString(),
          link: 'https://easypost.cving.com/'
        }
      ]
      this.corporateLinks = [
        {
          text: 'Legal info',
          link: 'https://media.cving.com/legal-info/'
        }
      ]
      this.agencyLinks = [
        {
          text: this.$t('footer.discoverServices').toString(),
          link: 'https://business.cving.com/per-le-aziende'
        },
        {
          text: this.$t('footer.platform').toString(),
          link: 'https://business.cving.com/per-le-aziende#piattaforma'
        },
        {
          text: this.$t('footer.solution').toString(),
          link: 'https://business.cving.com/per-le-aziende#solutions'
        },
        {
          text: this.$t('footer.comunication').toString(),
          link: 'https://business.cving.com/per-le-aziende#communication'
        },
        {
          text: this.$t('footer.events').toString(),
          link: 'https://business.cving.com/per-le-aziende#events'
        },
        {
          text: this.$t('footer.indeed').toString(),
          link: 'https://business.cving.com/per-le-aziende#indeed'
        },
        {
          text: this.$t('footer.publish').toString(),
          link: this.$i18n.locale === 'it' ? 'https://easypost.cving.com' : 'https://easypost.cving.com/?lang=' + this.$i18n.locale
        },
        {
          text: this.$t('footer.contactUs').toString(),
          link: 'https://business.cving.com/contatti'
        }
      ]
      this.candidateLinks = [
        {
          text: 'CVing.com',
          link: 'https://cving.com/'
        },
        {
          text: this.$t('footer.searchJob').toString(),
          link: 'https://cving.com/offerte-lavoro?p=1'
        },
        {
          text: 'Open Referral Program',
          link: 'https://business.cving.com/openreferralprogram'
        },
        {
          link: 'https://cving.com/signup',
          text: this.$t('footer.signUp').toString()
        },
        {
          text: this.$t('footer.signIn').toString(),
          link: 'https://cving.com/login'
        },
        {
          link: 'https://media.cving.com/',
          text: 'News'
        },
        {
          link: 'https://cving.com/user/test',
          text: this.$t('footer.personalityTest').toString()

        },
        {
          link: 'https://business.cving.com/training-school/',
          text: 'Training'
        },
        {
          link: 'https://support.cving.com/hc/it',
          text: 'Customer support'
        }
      ]
      this.otherLinks = [
        {
          icon: true,
          name: 'facebook-f',
          link: 'https://www.facebook.com/CVingapp/'
        },
        {
          icon: true,
          name: 'linkedin',
          link: 'https://www.linkedin.com/company/cving'
        },
        {
          icon: true,
          name: 'telegram',
          link: 'https://t.me/digitaltalentweek'
        },
        {
          icon: true,
          name: 'youtube',
          link: 'https://www.youtube.com/c/CVing/featured'
        },
        {
          icon: true,
          name: 'instagram',
          link: 'https://www.instagram.com/cving_app/'
        },
        {
          icon: true,
          name: 'tiktok',
          link: 'https://www.tiktok.com/@cving_app'
        }]
      this.textLinks = [{
        icon: false,
        name: 'Privacy',
        link: 'https://media.cving.com/privacy/'
      },
      {
        icon: false,
        name: this.$t('footer.termsAndConditiones').toString(),
        link: 'https://media.cving.com/terms-conditions/'
      },
      {
        icon: false,
        name: this.$t('footer.transparency').toString(),
        link: 'https://media.cving.com/trasparenza/'
      },
      {
        icon: false,
        name: this.$t('footer.cookiePolicy').toString(),
        link: 'https://media.cving.com/cookie-declaration/'
      }

      ]
    },
    toggleCookies () {
      const body = document.querySelector('body')
      const isActive = body.classList.contains('show-cookies')
      if (isActive) {
        document.querySelector('body').classList.remove('show-cookies')
      } else {
        document.querySelector('body').classList.add('show-cookies')
      }
    }
  }
})
