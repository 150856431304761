export default ({ app, $auth }) => { // Questo plugin perché la redirect automatica dopo il login dovuto a un URL sotto auth non va con il multilingua
  $auth.onRedirect((to) => {
    return app.localePath(to)
  })
  /*
  $auth.onError((error, name, endpoint) => {
    console.log('ERRORE NUXT AUTH')
    console.error(name, error)
  })
   */
}
