const macrocategories = [
  {
    id: 1,
    id_verticals: 1,
    it: 'Contabilità',
    en: 'Accounting',
    es: 'Contabilidad',
    de: 'Buchhaltung',
    fr: 'Comptabilité',
    pt: 'Contabilidade',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 2,
    id_verticals: 1,
    it: 'Finanza',
    en: 'Finance',
    es: 'Finanzas',
    de: 'Finanzen',
    fr: 'La finance',
    pt: 'Finança',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 3,
    id_verticals: 1,
    it: 'Amministrazione',
    en: 'Administrative',
    es: 'Administrativo',
    de: 'Verwaltung',
    fr: 'Administratif',
    pt: 'Administrativo',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 4,
    id_verticals: 2,
    it: 'IT e sviluppo',
    en: 'IT and Development',
    es: 'Informática y desarrollo',
    de: 'IT und Entwicklung',
    fr: 'Informatique et Développement',
    pt: 'TI e Desenvolvimento',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 5,
    id_verticals: 2,
    it: 'Design',
    en: 'Design',
    es: 'Diseño',
    de: 'Design',
    fr: 'Concevoir',
    pt: 'Projeto',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 6,
    id_verticals: 4,
    it: 'Servizio clienti',
    en: 'Customer service',
    es: 'Servicio al cliente',
    de: 'Kundendienst',
    fr: 'Service Clients',
    pt: 'Serviço ao cliente',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 7,
    id_verticals: 5,
    it: 'Istruzione',
    en: 'Education',
    es: 'Educación',
    de: 'Bildung',
    fr: 'Éducation',
    pt: 'Educação',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 8,
    id_verticals: 5,
    it: 'Formazione aziendale',
    en: 'Corporate training',
    es: 'Formación para empresas',
    de: 'Unternehmenstraining',
    fr: 'Formation en entreprise',
    pt: 'Treinamento Corporativo',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 9,
    id_verticals: 6,
    it: 'Ingegneria',
    en: 'Engineering',
    es: 'Ingeniería',
    de: 'Maschinenbau',
    fr: 'Ingénierie',
    pt: 'Engenharia',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 10,
    id_verticals: 9,
    it: 'Edilizia',
    en: 'Construction',
    es: 'Construcción',
    de: 'Konstruktion',
    fr: 'Construction',
    pt: 'Construção',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 11,
    id_verticals: 9,
    it: 'Produzione',
    en: 'Production',
    es: 'Producción',
    de: 'Produktion',
    fr: 'Production',
    pt: 'Produção',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 12,
    id_verticals: 7,
    it: 'Sanità',
    en: 'Healthcare',
    es: 'Sanidad',
    de: 'Gesundheitswesen',
    fr: 'Soins de santé',
    pt: 'Assistência médica',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 13,
    id_verticals: 7,
    it: 'Farmaceutica',
    en: 'Pharmaceuticals',
    es: 'Farmacéutico',
    de: 'Arzneimittel',
    fr: 'Médicaments',
    pt: 'Produtos farmacêuticos',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 14,
    id_verticals: 8,
    it: 'Ospitalità',
    en: 'Hospitality',
    es: 'Hospitalidad',
    de: 'Gastfreundschaft',
    fr: 'Hospitalité',
    pt: 'Hospitalidade',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 15,
    id_verticals: 8,
    it: 'Viaggi e turismo',
    en: 'Travel & Tourism',
    es: 'Viajes y Turismo',
    de: 'Reise Tourismus',
    fr: 'Voyages & Tourisme',
    pt: 'Viagem de Turismo',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 16,
    id_verticals: 5,
    it: 'Risorse umane',
    en: 'Human Resources',
    es: 'Recursos Humanos',
    de: 'Humanressourcen',
    fr: 'Ressources humaines',
    pt: 'Recursos Humanos',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 18,
    id_verticals: 1,
    it: 'Legale',
    en: 'Legal',
    es: 'Legal',
    de: 'Gesetzlich',
    fr: 'Légal',
    pt: 'Jurídico',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 19,
    id_verticals: 9,
    it: 'Logistica',
    en: 'Logistics',
    es: 'Logística',
    de: 'Logistik',
    fr: 'Logistique',
    pt: 'Logística',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 20,
    id_verticals: 9,
    it: 'Strutture',
    en: 'Facilities',
    es: 'Instalaciones',
    de: 'Anlagen',
    fr: 'Installations',
    pt: 'Instalações',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 21,
    id_verticals: 10,
    it: 'Marketing',
    en: 'Marketing',
    es: 'Marketing',
    de: 'Marketing',
    fr: 'Commercialisation',
    pt: 'Marketing',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 22,
    id_verticals: 10,
    it: 'Relazioni pubbliche (PR)',
    en: 'Public Relations (PR)',
    es: 'Relaciones públicas (PR)',
    de: 'Öffentlichkeitsarbeit (PR)',
    fr: 'Relations publiques (RP)',
    pt: 'Relações Públicas (RP)',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 23,
    id_verticals: 10,
    it: 'Media',
    en: 'Media',
    es: 'Media',
    de: 'Medien',
    fr: 'Médias',
    pt: 'meios de comunicação',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 24,
    id_verticals: 10,
    it: 'Beni immobili',
    en: 'Real estate',
    es: 'Inmobiliaria',
    de: 'Immobilie',
    fr: 'Immobilier',
    pt: 'Imobiliária',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 25,
    id_verticals: 10,
    it: 'Vendite',
    en: 'Sales',
    es: 'Ventas',
    de: 'Verkauf',
    fr: 'Ventes',
    pt: 'Vendas',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 26,
    id_verticals: 4,
    it: 'Vendita al dettaglio',
    en: 'Retail',
    es: 'Venta al por menor',
    de: 'Einzelhandel',
    fr: 'Vendre au détail',
    pt: 'Retalho',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  },
  {
    id: 17,
    id_verticals: 1,
    it: 'Forze dell\'ordine / Sicurezza',
    en: 'Law enforcement / Security',
    es: 'Aplicación de la ley / Seguridad',
    de: 'Strafverfolgung / Sicherheit',
    fr: 'Application de la loi / Sécurité',
    pt: 'Aplicação da lei / Segurança',
    active: true,
    creation_date: '2022-10-14 13:32:10.017',
    update_date: ''
  }
]

export function getMacrocategories (lang) {
  const filtred = []
  macrocategories.forEach((macro) => {
    if (macro.active) {
      filtred.push({
        [lang]: macro[lang],
        id: macro.id,
        id_verticals: macro.id_verticals
      })
    }
  })
  return filtred
}
